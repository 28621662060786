import { Box, Typography } from "@mui/material";


const MessageCard = ({ timestamp, content, isSent }) => {
    const messageStyles = {
        px: 1, 
        py: 0.5, 
        borderRadius: 2,
        width: '100%', 
        bgcolor: isSent ? '#6C5DD3' : '#D6D6D6',
        color: isSent ? '#FFFFFF' : '#000000',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: 'medium',
        fontSize: '14px',
    };

    const timestampStyles = {
        fontFamily: 'Poppins, sans-serif',
        fontWeight: 'regular',
        fontSize: '14px',
        color: '#717171',
        mt: 0.5,
        textAlign: isSent ? 'right' : 'left', // align right if message is sent, left otherwise
    };

    const boxStyles = {
        maxWidth: '80%',
        width: 'auto',
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        alignSelf: isSent ? 'flex-end' : 'flex-start',
    };

    const dateObject = timestamp ? new Date(timestamp.seconds * 1000) : null;
    
    // Formatted timestamp as per your requirements
    const optionsTime = { hour: "numeric", minute: "numeric" };
    const optionsDate = { month: "long", day: "numeric" };
    
    const formattedTime = dateObject ? dateObject.toLocaleTimeString([], optionsTime) : "";
    const formattedDate = dateObject ? dateObject.toLocaleDateString([], optionsDate) : "";
    
    const readableDate = `${formattedTime} ${formattedDate}`;

    return (
        <Box sx={boxStyles}>
            <Box sx={messageStyles}>
                <Typography>{content}</Typography>
            </Box>
            <Typography sx={timestampStyles}>
                {readableDate}
            </Typography>
        </Box>
    );
};

export default MessageCard;
