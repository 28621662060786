import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControlLabel, RadioGroup, Radio, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const FilterDialog = ({ open, handleClose, handleFilterApply, teachingModes, instruments, genres }) => {
    const [selectedTeachingMode, setSelectedTeachingMode] = useState("");
    const [selectedInstruments, setSelectedInstruments] = useState([]);
    const [selectedGenres, setSelectedGenres] = useState([]);

    const handleTeachingModeChange = (event) => {
        setSelectedTeachingMode(event.target.value);
    };

    const handleInstrumentsChange = (instrument) => {
        setSelectedInstruments(prevInstruments => {
            if (prevInstruments.includes(instrument)) {
                return prevInstruments.filter(i => i !== instrument);
            } else {
                return [...prevInstruments, instrument];
            }
        });
    };

    const handleGenresChange = (genre) => {
        setSelectedGenres(prevGenres => {
            if (prevGenres.includes(genre)) {
                return prevGenres.filter(g => g !== genre);
            } else {
                return [...prevGenres, genre];
            }
        });
    };

    const handleApplyFilters = () => {
        handleFilterApply(selectedTeachingMode, selectedInstruments, selectedGenres);
        handleClose();
    };

    const handleResetFilters = () => {
        setSelectedTeachingMode("");
        setSelectedInstruments([]);
        setSelectedGenres([]);
    };

    const textStyle = {
        fontFamily: 'Poppins, sans-serif',
        fontWeight: 600,
        fontSize: '20px',
        margin: '20px 0',
    };

    const buttonContainerStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle>
                <Box sx={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid grey', py: 1 }}>
                    <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', fontSize: 24 }}>
                        Filters
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        sx={{ marginLeft: 'auto' }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>  
            <DialogContent>
                <Typography sx={textStyle}>Teaching Mode</Typography>
                <RadioGroup
                    id="teaching-mode-select"
                    value={selectedTeachingMode}
                    onChange={handleTeachingModeChange}
                >
                    {teachingModes.map((mode, index) => (
                        <FormControlLabel key={index} value={mode} control={<Radio />} label={mode} />
                    ))}
                </RadioGroup>

                <Typography sx={textStyle}>Instruments</Typography>
                <div style={buttonContainerStyle}>
                    {instruments.map((instrument, index) => (
                        <Button
                            key={index}
                            variant="contained"
                            sx={{ 
                                backgroundColor: selectedInstruments.includes(instrument) ? "#6C5DD3" : "grey", 
                                whiteSpace: 'nowrap',
                                overflow: 'visible',
                                display: 'inline-flex',
                                px: 2
                            }}
                            onClick={() => handleInstrumentsChange(instrument)}
                        >
                            {instrument}
                        </Button>
                    ))}
                </div>

                <Typography sx={textStyle}>Genres</Typography>
                <div style={buttonContainerStyle}>
                    {genres.map((genre, index) => (
                        <Button
                            key={index}
                            variant="contained"
                            sx={{ 
                                backgroundColor: selectedGenres.includes(genre) ? "#6C5DD3" : "grey", 
                                whiteSpace: 'nowrap',
                                overflow: 'visible',
                                display: 'inline-flex',
                                px: 2
                            }}
                            onClick={() => handleGenresChange(genre)}
                        >
                            {genre}
                        </Button>
                    ))}
                </div>
            </DialogContent>
            <DialogActions sx={{ flex: 1, mb: 2, px: 3 }}>
                <Box sx={{ justifyContent: "space-between", width: "100%", display: 'flex' }}>
                    <Button
                        sx={{ px: '20px', py: '10px', borderRadius: '15px', backgroundColor: '#6C5DD3' }} 
                        variant="contained"
                        onClick={handleResetFilters}
                    >
                        <Box sx={{ fontWeight: 'bold' }}>Reset</Box>
                    </Button>
                    <Button
                        sx={{ px: '20px', py: '10px', borderRadius: '15px', backgroundColor: '#36C752' }} 
                        variant="contained"
                        onClick={handleApplyFilters}
                    >
                        <Box sx={{ fontWeight: 'bold' }}>Apply</Box>
                    </Button>    
                </Box>     
            </DialogActions>
        </Dialog>
    );
};

export default FilterDialog;
