import React, { useContext } from "react";
import Booking from "../Scheduling/Booking";
import SchedulingCalendar from "../Scheduling/SchedulingCalendar";
import { Grid, CircularProgress } from "@mui/material";
import SchedulingProvider from "../../contexts/SchedulingContext";

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AuthContext } from '../../contexts/AuthContext'; 


function Scheduling() {
    const { currentUser, userProfile } = useContext(AuthContext);


    if (!currentUser || !userProfile) {
        return <CircularProgress />;
      }

    return (
        <SchedulingProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid container sx={{ px: '80px', py: 6 }} spacing={4}>
                    <Grid item xs={5}>
                        <Booking />
                    </Grid>
                    <Grid item xs={7} >
                        <SchedulingCalendar />
                    </Grid>
                </Grid>
            </LocalizationProvider>
        </SchedulingProvider>
    );
}

export default Scheduling;
