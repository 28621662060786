import React, { useState } from 'react';
import { Button } from '@mui/material';

function AnalyticsTimeFrameSelection() {
  const [selectedTimeFrame, setSelectedTimeFrame] = useState('Week');

  return (
    <div>
      {['Week', 'Month', 'Year', 'Total'].map((timeFrame) => (
        <Button
          key={timeFrame}
          variant="outlined"
          onClick={() => setSelectedTimeFrame(timeFrame)}
          sx={{
            borderRadius: '10px',
            px: '15px',
            py: '5px',
            color: 'black',
            backgroundColor: selectedTimeFrame === timeFrame ? '#C4C4C4' : 'white',
            border: '1px solid #717171',
            fontSize: 20,
            fontWeight: 'medium',
            fontFamily: 'poppins',
            mr: 2
          }}
        >
          {timeFrame}
        </Button>
      ))}
    </div>
  );
}

export default AnalyticsTimeFrameSelection;
