import React, { useState, useContext } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'; 
import { ExploreContext } from '../../../contexts/ExploreContext';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
  },
  palette: {
    primary: {
      main: '#6C5DD3',
    },
  },
});

const genres = [
  "Pop",
  "Rock",
  "Country",
  "Jazz",
  "Blues",
  "Classical",
  "Hip Hop",
  "Reggae",
  "Electronic",
  "Folk",
  "Soul",
  "R&B",
  "Bluegrass",
  "Gospel",
  "Punk",
  "Metal",
  "Indie",
  "Latin",
  "World Music",
  "Alternative",
  "Other"
];

const instruments = [
  "Piano",
  "Guitar",
  "Voice",
  "Violin",
  "Cello",
  "Flute",
  "Drums",
  "Saxophone",
  "Clarinet",
  "Trumpet",
  "Harp",
  "Viola",
  "Trombone",
  "Ukulele",
  "Bass Guitar",
  "Double Bass",
  "Accordion",
  "Banjo",
  "Mandolin",
  "Harmonica",
  "Oboe",
  "Synthesizer",
  "Other"
];

function OnboardingTeacher2() {
  const [onboardingData, setOnboardingData] = useState({
    teachingDescription: "",
    teachingInstruments: [],
    teachingGenres: [],
    teachingYears: "",
    teachingExperience: "",
    handleStrugglingStudent: "",
    engagingLessons: ""
  });

  const navigate = useNavigate();
  const { updateOnboardingData } = useContext(ExploreContext); 

  const handleChange = (event) => {
    setOnboardingData({
      ...onboardingData,
      [event.target.name]: event.target.value,
    });
  };

  const handleInstrumentChange = (instrument) => {
    setOnboardingData(prevData => {
        if (prevData.teachingInstruments.includes(instrument)) {
            return {
                ...prevData,
                teachingInstruments: prevData.teachingInstruments.filter(i => i !== instrument)
            }
        } else {
            return {
                ...prevData,
                teachingInstruments: [...prevData.teachingInstruments, instrument]
            }
        }
    });
  };

  const handleGenreChange = (genre) => {
    setOnboardingData(prevData => {
        if (prevData.teachingGenres.includes(genre)) {
            return {
                ...prevData,
                teachingGenres: prevData.teachingGenres.filter(g => g !== genre)
            }
        } else {
            return {
                ...prevData,
                teachingGenres: [...prevData.teachingGenres, genre]
            }
        }
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    updateOnboardingData(onboardingData);
    navigate('/onboardingTeacher3');
  };

  const handleBack = () => {
    navigate('/onboardingTeacher1');
  };
  
  const isDisabled = onboardingData.teachingInstruments.length === 0 || onboardingData.teachingGenres.length === 0 || !onboardingData.teachingYears || !onboardingData.teachingDescription;
  
  return (
    <ThemeProvider theme={theme}>
      <Box 
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
        className="login-container"
        sx={{ marginBottom: '100px' }} 
      >
        <Box 
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          className="form-container"
          sx={{mt: 4, maxWidth: "60vw"}}
        >
          <Typography variant="h4" className="login-heading" gutterBottom>Teaching Experience</Typography>
  
          <form onSubmit={handleSubmit} className="login-form" style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 10}}>
            
            <Typography sx={{ fontWeight: 'semibold', fontSize: 20, fontFamily: 'Poppins' }}>Describe what you teach in one sentence (Required)</Typography>
            <TextField
              variant="outlined"
              className="login-input"
              margin="normal"
              name="teachingDescription"
              value={onboardingData.teachingDescription}
              onChange={handleChange}
              fullWidth
              rows={3}
              multiline
              required
            />
  
            <Typography sx={{ fontWeight: 'semibold', fontSize: 20, fontFamily: 'Poppins' }}>Select all instrument(s) you teach (Required)</Typography>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
              {instruments.map((instrument) => (
                  <Button
                      key={instrument}
                      variant="contained"
                      sx={{ 
                          backgroundColor: onboardingData.teachingInstruments.includes(instrument) ? "#6C5DD3" : "grey", 
                          whiteSpace: 'nowrap',
                          overflow: 'visible',
                          display: 'inline-flex',
                          px: 2
                      }}
                      onClick={() => handleInstrumentChange(instrument)}
                  >
                      {instrument}
                  </Button>
              ))}
            </div>
  
            <Typography sx={{ fontWeight: 'semibold', fontSize: 20, fontFamily: 'Poppins' }}>Select all genre(s) you teach (Required)</Typography>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
              {genres.map((genre) => (
                  <Button
                      key={genre}
                      variant="contained"
                      sx={{ 
                          backgroundColor: onboardingData.teachingGenres.includes(genre) ? "#6C5DD3" : "grey", 
                          whiteSpace: 'nowrap',
                          overflow: 'visible',
                          display: 'inline-flex',
                          px: 2
                      }}
                      onClick={() => handleGenreChange(genre)}
                  >
                      {genre}
                  </Button>
              ))}
            </div>
  
            <Typography sx={{ fontWeight: 'semibold', fontSize: 20, fontFamily: 'Poppins' }}>How many years have you been teaching? (Required)</Typography>
            <TextField
              variant="outlined"
              className="login-input"
              margin="normal"
              name="teachingYears"
              value={onboardingData.teachingYears}
              onChange={handleChange}
              type="number"
            />
  
            <Typography sx={{ fontWeight: 'semibold', fontSize: 20, fontFamily: 'Poppins' }}>Describe your experience with teaching in your own words (Optional)</Typography>
            <TextField
              variant="outlined"
              className="login-input"
              margin="normal"
              name="teachingExperience"
              value={onboardingData.teachingExperience}
              onChange={handleChange}
              fullWidth
              rows={3}
              multiline
            />
  
            <Typography sx={{ fontWeight: 'semibold', fontSize: 20, fontFamily: 'Poppins' }}>How do you handle a student who is struggling to understand a concept or master a skill? (Optional)</Typography>
            <TextField
              variant="outlined"
              className="login-input"
              margin="normal"
              name="handleStrugglingStudent"
              value={onboardingData.handleStrugglingStudent}
              onChange={handleChange}
              fullWidth
              rows={3}
              multiline
            />
  
            <Typography sx={{ fontWeight: 'semibold', fontSize: 20, fontFamily: 'Poppins' }}>How do you keep your lessons engaging and fun? (Optional)</Typography>
            <TextField
              variant="outlined"
              className="login-input"
              margin="normal"
              name="engagingLessons"
              value={onboardingData.engagingLessons}
              onChange={handleChange}
              fullWidth
              rows={3}
              multiline
            />
  
            <Box display="flex" justifyContent="space-between" marginY={2} sx={{width: '100%'}}>
              <Button
                sx={{ px: '20px', py: '10px', borderRadius: '15px', backgroundColor: '#6C5DD3' }} 
                variant="contained"
                endIcon={<ArrowBackIcon />}
                onClick={handleBack}
              >
                <Box sx={{ fontWeight: 'bold' }}>Back</Box>
              </Button>
              <Button
                sx={{ px: '20px', py: '10px', borderRadius: '15px', backgroundColor: '#6C5DD3' }} 
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                type="submit"
                disabled={isDisabled}
              >
                <Box sx={{ fontWeight: 'bold' }}>Next</Box>
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </ThemeProvider>
  );
  
}

export default OnboardingTeacher2;
