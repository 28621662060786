import React from 'react';
import { Box, Typography, Grid, Slider } from '@mui/material';

const ProfilePreferences = (dataProfile) => {

  let studentPreferences = [];
  let teacherPreferences = [];
  dataProfile = dataProfile.dataProfile

  if (dataProfile?.userType === 'student' && dataProfile?.onboardingData) {
    const learningStyle = dataProfile.onboardingData.learningStyle;
    studentPreferences = [
        { label: dataProfile.onboardingData.learningMode ? 'Would you prefer learning online or in person?' : '', value: dataProfile.onboardingData.learningMode || '' },
        { label: dataProfile.onboardingData.lessonType ? 'Do you prefer group lessons or solo lessons?' : '', value: dataProfile.onboardingData.lessonType || '' },
        { label: learningStyle.structured ? 'Teaching Style: Structured...Flexible' : '', value: learningStyle.structured || '' },
        { label: learningStyle.theory ? 'Teaching Style: Theory...Practical' : '', value: learningStyle.theory || '' },
        { label: learningStyle.performance ? 'Teaching Style: Performance...Fun' : '', value: learningStyle.performance || '' },
        { label: dataProfile.onboardingData.practiceHours ? 'How many hours per week are you willing to practice?' : '', value: dataProfile.onboardingData.practiceHours || '' },
        { label: dataProfile.onboardingData.teachingMethods ? 'What kind of teaching methods do you find most effective?' : '', value: dataProfile.onboardingData.teachingMethods || '' },
        { label: dataProfile.onboardingData.guidancePreference ? 'How do you like to be corrected or guided during lessons?' : '', value: dataProfile.onboardingData.guidancePreference || '' },
        { label: dataProfile.onboardingData.typicalLesson ? 'Could you describe a typical lesson you would enjoy?' : '', value: dataProfile.onboardingData.typicalLesson || '' },
        { label: dataProfile.onboardingData.specialConsiderations ? 'Do you have any special considerations the teacher should be aware of?' : '', value: dataProfile.onboardingData.specialConsiderations || '' },
    ];
  } else if (dataProfile?.userType === 'teacher' && dataProfile?.onboardingData) {
    const teachingStyle = dataProfile.onboardingData.teachingStyle;
    teacherPreferences = [
        { label: dataProfile.onboardingData.teachingMode ? 'Online vs. In Person' : '', value: dataProfile.onboardingData.teachingMode || '' },
        { label: dataProfile.onboardingData.lessonType ? 'Group vs. Solo' : '', value: dataProfile.onboardingData.lessonType || '' },
        { label: teachingStyle.structured ? 'Teaching Style: Structured...Flexible' : '', value: teachingStyle.structured || '' },
        { label: teachingStyle.theory ? 'Teaching Style: Theory...Practical' : '', value: teachingStyle.theory || '' },
        { label: teachingStyle.performance ? 'Teaching Style: Performance...Fun' : '', value: teachingStyle.performance || '' },
        { label: dataProfile.onboardingData.practiceHours ? 'Expected practice hours per week' : '', value: dataProfile.onboardingData.practiceHours || '' },
        { label: dataProfile.onboardingData.practiceHandling ? 'How do you handle a student who is not practicing as required?' : '', value: dataProfile.onboardingData.practiceHandling || '' },
        { label: dataProfile.onboardingData.typicalStudent ? 'What is your typical student like?' : '', value: dataProfile.onboardingData.typicalStudent || '' },
        { label: dataProfile.onboardingData.specialConsiderationsExperience ? 'What is your experience teaching students with special considerations?' : '', value: dataProfile.onboardingData.specialConsiderationsExperience || '' },
    ];
  }

  const preferencesInfo = dataProfile.userType === 'student' ? studentPreferences : teacherPreferences;

  return (
    <Box
      sx={{
        border: '2px solid #C4C4FF',
        borderRadius: '12px',
        padding: '20px',
        margin: '10px 0',
        width: '100%',
      }}
    >
      <Typography 
        sx={{              
          fontFamily: 'Poppins',
          fontWeight: 'bold',
          fontSize: '24px',
          mb: 2
        }}
      >
        Learning Preferences & Conditions
      </Typography>
      {preferencesInfo.map((info, index) => (
        info.label && info.value && (
          <Box key={index} sx={{ marginBottom: '10px' }}>
            {info.label.endsWith('...Flexible') || info.label.endsWith('...Practical') || info.label.endsWith('...Fun') ? (
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                  <Typography 
                    variant="h6"
                    sx={{
                      fontFamily: 'Poppins',
                      fontWeight: 'semibold',
                      fontSize: '22px',
                      mb: 1
                    }}
                  >
                    {dataProfile.userType === 'student' ? 'Adjust the Slider value to capture how you want your lessons to go' : 'Adjust the slider values to capture how your typical lessons go'}
                  </Typography>
                  <Grid container justifyContent="space-between">
                    <Grid item><Typography variant="body1" fontWeight="semiBold" fontSize={20}>{info.label.split('...')[0]}</Typography></Grid>
                    <Grid item><Typography variant="body1" fontWeight="semiBold" fontSize={20}>{info.label.split('...')[1]}</Typography></Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Slider
                    value={info.value}
                    aria-labelledby="continuous-slider"
                    valueLabelDisplay="auto"
                    min={0}
                    max={10}
                    disabled
                  />
                </Grid>
              </Grid>
            ) : (
              <>
                <Typography 
                  variant="h6"
                  sx={{
                    fontFamily: 'Poppins',
                    fontWeight: 'semibold',
                    fontSize: '22px',
                  }}
                >
                  {info.label}
                </Typography>
                <Typography 
                  variant="body1"
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '20px',
                    mb: 2
                  }}
                >
                  {info.value || ''}
                </Typography>
              </>
            )}
          </Box>
        )
      ))}
    </Box>
  );
};

export default ProfilePreferences;