import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <Box sx={{ 
      backgroundColor: '#ffffff', 
      color: '#000000', 
      py: 2, 
      borderTop: '1px solid #BDBDBD',
      position: 'relative',
      bottom: 0,
      width: '100%'
    }}>
      <Container sx={{ width: '100%', padding: 0 }}>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mt: 1, padding: 0}}>
          <Typography variant="body1" align="left" sx={{ marginRight: 3 }}>
            &copy; {new Date().getFullYear()} ACHORD LLC
          </Typography>
          <Link to="/contact" style={{ color: 'inherit', textDecoration: 'none' }}>
            Trouble with Bugs?
          </Link>
          <Typography variant="body2" sx={{ mx: 1 }}>
            |
          </Typography>
          <Link to="/about" style={{ color: 'inherit', textDecoration: 'none' }}>
            About Us
          </Link>
          <Typography variant="body2" sx={{ mx: 1 }}>
            |
          </Typography>
          <Link to="/privacy-policy" style={{ color: 'inherit', textDecoration: 'none' }}>
            Privacy Policy
          </Link>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
