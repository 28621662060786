import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardHeader, Avatar, CardContent, Typography, Box, ListItem, ListItemText, List, CardActionArea } from '@mui/material';
import { red, deepPurple } from '@mui/material/colors';


const LandingTeacherCard = ({ teacher }) => {
    const navigate = useNavigate();


    const renderTeachingMode = (mode) => {
        switch(mode) {
        case 'Online':
            return 'Online';
        case 'In Person':
            return 'In Person';
        case 'Both/Either':
            return 'In Person | Online';
        default:
            return '';
        }
    };

    return (
        <CardActionArea onClick={() => navigate('/explore')}>
            <Card sx={{ width: "400px", borderRadius: 3, border: 1, borderColor: deepPurple[200], boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', height: "350px", py: 1, px: 1}}>
                <CardHeader 
                    sx={{p: 1}}
                    avatar={
                        <Avatar sx={{ bgcolor: red[500], border: 2, borderColor: deepPurple[200], width: 60, height: 60 }} src={teacher.pictureURL} />
                    }
                    title= {
                        <Typography sx={{fontWeight: '600', fontFamily: "poppins", fontSize: 18}}>
                            {teacher.fullName}
                        </Typography>}
                
                subheader = {
                    <List disablePadding>
                        <ListItem disablePadding>
                            <ListItemText>
                                <Typography variant="body2" color={deepPurple[200]} sx={{fontWeight: '500', fontFamily: "poppins", fontSize: 14}}>
                                {renderTeachingMode(teacher.onboardingData.teachingMode)}
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemText>
                                <Typography variant="body2" color="text.secondary" sx={{fontWeight: '500', fontFamily: "poppins", fontSize: 14}}>
                                {teacher.onboardingData.teachingYears} years experience
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                    }
                />
            
            <CardContent 
                sx={{p:1}}
            >
                <Box sx={{fontWeight: '600', fontFamily: "poppins", fontSize: 16}}>Who am I?</Box>
                <Typography variant="body2" sx={{fontWeight: '400', fontFamily: "poppins", fontSize: 16}}>
                    {teacher.onboardingData.teacherDescription}
                </Typography>
                <Box sx={{fontWeight: '600', fontFamily: "poppins", fontSize: 16}}>I teach:</Box>
                <Typography variant="body2" sx={{fontWeight: '400', fontFamily: "poppins", fontSize: 16}}>
                    {teacher.onboardingData.teachingDescription}
                </Typography>
                <Box sx={{fontWeight: '600', fontFamily: "poppins", fontSize: 16}}>Words to Describe Me</Box>
                <Typography variant="body2" sx={{fontWeight: '400', fontFamily: "poppins", fontSize: 16}}>
                    {teacher.onboardingData.teacherTraits.join(", ")}
                </Typography>
            </CardContent>    
            </Card>
        </CardActionArea>
    );
};

export default LandingTeacherCard;
