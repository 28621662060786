import React, { useContext, useEffect, useState, useRef } from "react";
import {
    Avatar, Box, Button, Checkbox, Dialog, DialogActions,
    DialogContent, DialogTitle, Divider, Grid, ListItem, ListItemText,
    List, Link, Stack, Stepper, Step, StepButton, ThemeProvider, Typography, IconButton
} from "@mui/material";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DigitalClock } from '@mui/x-date-pickers/DigitalClock';
import { SchedulingContext } from '../../contexts/SchedulingContext';
import { AuthContext } from '../../contexts/AuthContext';
import BookingCalendar from "./BookingCalendar";
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import theme from '../theme';
import { functions, httpsCallable, Timestamp } from '../../services/firebase';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const steps = ['Teacher', 'Package', 'Time'];
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

function TeacherIcon({ teacher, color }) {
    return (
        <Box sx={{ border: 2, borderColor: { color }, borderRadius: '12px', py: '12px', px: '16px' }}>
            <Stack direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >

                <Avatar alt="" src={teacher.pictureURL} sx={{ width: 56, height: 56 }} />
                <Typography>{teacher.fullName}</Typography>
            </Stack>
        </Box>
    );
}


function PackageCard({ teacherPackage, color }) {
    return (
        <ListItem
            sx={{ my: 1.5, py: 1.5, px: 2, borderRadius: '12px', borderColor: { color }, border: 2, boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
        >
            <ListItemText
                primary={
                    <Box sx={{ fontWeight: 'bold' }}>{teacherPackage.name}</Box>
                }
                secondary={
                    <List disablePadding dense>

                        <ListItem disablePadding>
                            <ListItemText sx={{ m: 0 }}>
                                <Typography variant="body2" color="text.secondary">
                                    Instrument : {teacherPackage.instrument}
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemText sx={{ m: 0 }}>
                                <Typography variant="body2" color="text.secondary">
                                    Number of Lessons : {teacherPackage.numberLessons}
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemText sx={{ m: 0 }}>
                                <Typography variant="body2" color="text.secondary">
                                    Duration of Lesson Time : {teacherPackage.duration}
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        {(!teacherPackage.online) ? (
                            <ListItem disablePadding>
                                <ListItemText sx={{ m: 0 }}>
                                    <Typography variant="body2" color="text.secondary">
                                        Address : {teacherPackage.location}
                                    </Typography>

                                </ListItemText>
                            </ListItem>
                        )
                            :
                            (
                                <ListItem disablePadding>
                                    <ListItemText sx={{ m: 0 }}>
                                        Link:
                                        <Link href={teacherPackage.location} underline="always"> {teacherPackage.location}</Link>


                                    </ListItemText>
                                </ListItem>
                            )

                        }
                    </List>
                }
            />
        </ListItem>
    );
}

function NewBookingModal({ newBookingOpen, handleNewBookingClose }) {

    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const [selectedTeacher, setSeclectedTeacher] = React.useState({});
    const [selectedPackage, setSeclectedPackage] = React.useState({});
    const [dateValue, setDateValue] = React.useState(dayjs());
    const [timeValue, setTimeValue] = React.useState(null);
    const { currentUser, usersSavedTeachers, userProfile } = useContext(AuthContext);

    const [selectedTeacherObject, setSelectedTeacherObject] = React.useState({});
    const [selectedTeacherPackages, setSelectedTeacherPackages] = React.useState([]);
    const [finalPackage, setFinalPackage] = React.useState({});

    const { addAppointment } = useContext(SchedulingContext);

    useEffect(() => {
        if (newBookingOpen === false) {
            handleReset();
        }

    }, [newBookingOpen])

    const [specificSlots, setSpecificSlots] = useState({});
    // const [availableSlots, setAvailableSlots] = useState({});
    // setAvailableSlots(newSlots);
    let newSlots = {};

    useEffect(() => {
        const specificSlotsForTheDay = newSlots[dateValue.format('DD/MM/YYYY')];
        setSpecificSlots(specificSlotsForTheDay);
    }, [dateValue])


    const checkAvailabilityGivenSlots = (slot, start, duration) => {
        const slotStart = start.clone().set({
            hour: parseInt(slot.from.split(':')[0]),
            minute: parseInt(slot.from.split(':')[1]),
            second: 0,
            millisecond: 0
        });

        const slotEnd = start.clone().set({
            hour: parseInt(slot.to.split(':')[0]),
            minute: parseInt(slot.to.split(':')[1]),
            second: 0,
            millisecond: 0
        });

        const isAvailable =
            (start.isSameOrAfter(slotStart) &&
                start.clone().add(duration, 'minutes').isSameOrBefore(slotEnd));

        return isAvailable;
    }

    const checkSlots = (dayStartProp, slots) => {
        let toDisable = true;
        let dayStart = dayStartProp;
        if (!newSlots[dayStartProp.format('DD/MM/YYYY')]) {
            newSlots[dayStartProp.format('DD/MM/YYYY')] = [];
        }
        newSlots[dayStartProp.format('DD/MM/YYYY')] = [];


        for (const slot of slots) {
            let fromMinutes = parseInt(slot.from.split(':')[0]) * 60 + parseInt(slot.from.split(':')[1]);
            const toMinutes = parseInt(slot.to.split(':')[0]) * 60 + parseInt(slot.to.split(':')[1]);

            fromMinutes = Math.ceil(fromMinutes / 15) * 15;

            for (let i = fromMinutes; i <= toMinutes; i += 15) {
                const start = dayStart.clone().add(i, 'minutes');

                if (checkAvailabilityGivenSlots(slot, start, finalPackage.duration)) {

                    if (finalPackage.numberLessons > 1) {
                        let counter = 1;
                        let weeksLater = dayStartProp.clone().add(counter, 'week');
                        dayStart = weeksLater;
                        const timeToCheck = i;

                        while (counter < finalPackage.numberLessons) {
                            //check if specific date and time is available
                            if (checkDateTimeAvailable(dayStart, timeToCheck, finalPackage.duration)) {
                                counter++;
                            } else {
                                break;
                            }
                        }
                        if (counter == finalPackage.numberLessons) {
                            if (!newSlots[dayStartProp.format('DD/MM/YYYY')]) {
                                newSlots[dayStartProp.format('DD/MM/YYYY')] = [];
                            }
                            newSlots[dayStartProp.format('DD/MM/YYYY')].push(start.format('HH:mm'));
                            toDisable = false;
                        }
                    }
                }
            }
        }
        return toDisable;

    }

    const checkDateTimeAvailable = (date, time, duration) => {
        const irregularSchedule = selectedTeacherObject.scheduling.availabilities.irregulars.find(
            (d) => d.date.toDate().toISOString().split('T')[0] === date.toISOString().split('T')[0]
        );
        const startTime = date.clone().add(time, 'minutes');
        if (irregularSchedule) {
            const slots = irregularSchedule.slots;

            for (const slot of slots) {
                if (checkAvailabilityGivenSlots(slot, startTime, duration)) {
                    return true;
                }
            }

        } else {
            const dayOfWeek = date.clone().format('dddd');
            const regularSchedule = selectedTeacherObject.scheduling.availabilities.regulars[dayOfWeek];

            if (regularSchedule.length > 0) {
                const slots = regularSchedule;
                for (const slot of slots) {
                    if (checkAvailabilityGivenSlots(slot, startTime, duration)) {
                        return true;
                    }
                }
            } else {
                return false;
            }
        }
    }

    const checkDateAvailble = (date) => {
        const currentDate = moment();

        const convertedDate = date.format('DD/MM/YYYY');
        const dayStart = moment(convertedDate, 'DD/MM/YYYY');

        if (dayStart.isSameOrBefore(currentDate)) {
            return true;
        }

        const irregularSchedule = selectedTeacherObject.scheduling.availabilities.irregulars.find(
            (d) => d.date.toDate().toISOString().split('T')[0] === date.toISOString().split('T')[0]
        );

        if (irregularSchedule) {
            const slots = irregularSchedule.slots;
            return checkSlots(dayStart, slots);
        } else {
            const dayOfWeek = dayStart.clone().format('dddd');
            const regularSchedule = selectedTeacherObject.scheduling.availabilities.regulars[dayOfWeek];

            if (regularSchedule.length > 0) {
                const slots = regularSchedule;
                return checkSlots(dayStart, slots);
            } else {
                return true;
            }
        }

    };

    const handleSelectedTeacher = (event, index) => {
        if (event.target.checked) {
            const newSelectedTeacher = {};
            newSelectedTeacher[index] = true;
            setSeclectedTeacher(newSelectedTeacher);
        }
        else {
            setSeclectedTeacher({});
        }
    };

    const handleSelectedPackage = (event, index) => {
        if (event.target.checked) {
            const newSelectedPackge = {};
            newSelectedPackge[index] = true;
            setSeclectedPackage(newSelectedPackge);
        }
        else {
            setSeclectedPackage({});
        }
    };

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ?
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleTeacherComplete = () => {

        const keyArray = Object.keys(selectedTeacher)
        if (keyArray.length > 0) {

            let teacherIndex = keyArray[0];
            //console.log('usersSavedTEachersIs this', usersSavedTeachers[teacherIndex]);
            setSelectedTeacherObject(usersSavedTeachers[teacherIndex]);
            setSelectedTeacherPackages(usersSavedTeachers[teacherIndex].scheduling.packages || []);
            //console.log('selectedTeacherPakages', selectedTeacherPackages);
        } else {
            console.error("No teacher selected.");
        }
    }

    const handlePackageComplete = () => {

        const keyArray = Object.keys(selectedPackage)
        if (keyArray.length > 0) {

            let packageIndex = keyArray[0];
            setFinalPackage(selectedTeacherPackages[packageIndex]);

        } else {
            console.error("No package selected.");
        }

    }

    const handleComplete = () => {
        const newCompleted = completed;
        if (activeStep === 0) {
            if (Object.keys(selectedTeacher).length > 0) {
                handleTeacherComplete();
            } else {
                return;
            }
            newCompleted[activeStep] = true;
            setCompleted(newCompleted);
            handleNext();
        } else if (activeStep === 1) {
            //refactor the schedule to account for appointments
            const getTeacherAvailability = httpsCallable(functions, 'injectAppointmentsIntoSchedule');
            getTeacherAvailability({ selectedTeacherObject })
                .then((result) => {
                    // Read result of the Cloud Function.
                    const updatedTeacher = result.data;

                    console.log('Appointments Filtered', updatedTeacher);

                    updatedTeacher.scheduling.availabilities.irregulars = updatedTeacher.scheduling.availabilities.irregulars.map(irregular => {
                        const date = irregular.date;
                        // Assuming date is a JavaScript Date object
                        irregular.date = new Timestamp(date.seconds, date.nanoseconds);
                        return irregular;
                    });


                    setSelectedTeacherObject(updatedTeacher);


                    newCompleted[activeStep] = true;
                    setCompleted(newCompleted);
                    handleNext();
                })
                .catch((error) => {
                    // Getting the Error details.
                    const code = error.code;
                    const message = error.message;
                    const details = error.details;
                    console.log(code, message, details);
                });

            // const updatedTeacher = handleUpdateAvails(selectedTeacherObject);

            // setSelectedTeacherObject(updatedTeacher);


            // newCompleted[activeStep] = true;
            // setCompleted(newCompleted);
            // handleNext();

            if (Object.keys(selectedPackage).length > 0) {
                handlePackageComplete();
            } else {
                return;
            }
        }

    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
        setSeclectedTeacher({});
        setSeclectedPackage({});
        setTimeValue(null);
        setDateValue(dayjs());
        handleNewBookingClose();
    };

    const handleRequestBooking = () => {
        handleReset()
        handleNewBookingClose()
    
        const finalFormatted = dateValue.format('DD/MM/YYYY');
        const initialConvertedDate = moment(finalFormatted, 'DD/MM/YYYY');
    
        // Convert Moment date to Firebase Timestamp at midnight
    
        //handleUpdateAvails();

        // Generate a unique package ID using current timestamp and a random number
        const packageID = Date.now() + '_' + Math.round(Math.random() * 1e9);
    
        for (let i = 0; i < finalPackage.numberLessons; i++) {
            const finalConvertedDate = initialConvertedDate.clone().add(i, 'week');
            const dateValueFirebaseTimestamp = Timestamp.fromDate(finalConvertedDate.clone().startOf('day').toDate());
    
            const appointmentToPush = {
                duration: finalPackage.duration,
                studentID: currentUser.uid,
                teacherID: selectedTeacherObject.id,
                date: dateValueFirebaseTimestamp,
                from: timeValue,
                instrument: finalPackage.instrument,
                location: finalPackage.location,
                online: finalPackage.online,
                accepted: false,
                packageID: packageID,
                packageName: finalPackage.name,
                teacher: {
                    image: selectedTeacherObject.pictureURL ? selectedTeacherObject.pictureURL : 'default_avatar_image_link', // add your default avatar link here
                    name: selectedTeacherObject.fullName
                },
                student: {
                    name: userProfile.fullName,
                    image: userProfile.pictureURL ? userProfile.pictureURL : 'default_avatar_image_link' // add your default avatar link here
                }
            };
    
            addAppointment(appointmentToPush);
        }
    };
    

    // const handleUpdateAvails = () => {
    //     const convertedDate = dateValue.format('DD/MM/YYYY');
    //     const initialDateValueAsMoment = moment(convertedDate, 'DD/MM/YYYY');

    //     for (let lessonIteration = 0; lessonIteration < finalPackage.numberLessons; lessonIteration++) {
    //         let adjustedDateValue = initialDateValueAsMoment.clone().add(lessonIteration, 'week');

    //         //find the entry in irregulars that correspond to the current date
    //         const irregularScheduleForCurrentDate = selectedTeacherObject.scheduling.availabilities.irregulars.find(
    //             (d) => d.date.toDate().toISOString().split('T')[0] === adjustedDateValue.toISOString().split('T')[0]
    //         );

    //         if (irregularScheduleForCurrentDate) {
    //             //there is an irregular day, then find the slot for it so that timeValue is after slot start and before slot end
    //             for (const slot of irregularScheduleForCurrentDate.slots) {

    //                 const slotStart = adjustedDateValue.clone().set({
    //                     hour: parseInt(slot.from.split(':')[0]),
    //                     minute: parseInt(slot.from.split(':')[1]),
    //                     second: 0,
    //                     millisecond: 0
    //                 });

    //                 const slotEnd = adjustedDateValue.clone().set({
    //                     hour: parseInt(slot.to.split(':')[0]),
    //                     minute: parseInt(slot.to.split(':')[1]),
    //                     second: 0,
    //                     millisecond: 0
    //                 });

    //                 const timeToCheck = adjustedDateValue.clone().set({
    //                     hour: parseInt(timeValue.split(':')[0]),
    //                     minute: parseInt(timeValue.split(':')[1]),
    //                     second: 0,
    //                     millisecond: 0
    //                 });


    //                 const foundMatch = (timeToCheck.isSameOrAfter(slotStart) && timeToCheck.clone().add(finalPackage.duration, 'minutes').isSameOrBefore(slotEnd));

    //                 if (foundMatch) {
    //                     console.log('Found that I need to adjust times for an irregualr dar with', slotStart, slotEnd, timeToCheck);
    //                     irregularScheduleForCurrentDate.slots = irregularScheduleForCurrentDate.slots.filter((s) => s !== slot);
    //                     // Create two new slots
    //                     const newSlot1 = { from: slot.from, to: timeToCheck.format('HH:mm') };
    //                     const newSlot2 = { from: timeToCheck.clone().add(finalPackage.duration, 'minutes').format('HH:mm'), to: slot.to };
    //                     // Add the new slots
    //                     irregularScheduleForCurrentDate.slots.push(newSlot1, newSlot2);

    //                     const dateToAdjust = irregularScheduleForCurrentDate.date;
    //                     selectedTeacherObject.scheduling.availabilities.irregulars.dateToAdjust = irregularScheduleForCurrentDate;
    //                 }
    //             }

    //         } else {
    //             // If there is no irregular schedule, we'll create one based on the regular schedule
    //             const dayOfWeek = adjustedDateValue.format('dddd');
    //             const regularScheduleForCurrentDate = selectedTeacherObject.scheduling.availabilities.regulars[dayOfWeek];

    //             for (const slot of regularScheduleForCurrentDate) {

    //                 const slotStart = adjustedDateValue.clone().set({
    //                     hour: parseInt(slot.from.split(':')[0]),
    //                     minute: parseInt(slot.from.split(':')[1]),
    //                     second: 0,
    //                     millisecond: 0
    //                 });

    //                 const slotEnd = adjustedDateValue.clone().set({
    //                     hour: parseInt(slot.to.split(':')[0]),
    //                     minute: parseInt(slot.to.split(':')[1]),
    //                     second: 0,
    //                     millisecond: 0
    //                 });

    //                 const timeToCheck = adjustedDateValue.clone().set({
    //                     hour: parseInt(timeValue.split(':')[0]),
    //                     minute: parseInt(timeValue.split(':')[1]),
    //                     second: 0,
    //                     millisecond: 0
    //                 });


    //                 const foundMatch = (timeToCheck.isSameOrAfter(slotStart) && timeToCheck.clone().add(finalPackage.duration, 'minutes').isSameOrBefore(slotEnd));

    //                 if (foundMatch) {
    //                     console.log('Found that I need to adjust times for an regualr dar with', slotStart, slotEnd, timeToCheck);


    //                     const regularTransformedToIrregular = regularScheduleForCurrentDate.filter((s) => s !== slot);
    //                     // Create two new slots
    //                     const newSlot1 = { from: slot.from, to: timeToCheck.format('HH:mm') };
    //                     const newSlot2 = { from: timeToCheck.clone().add(finalPackage.duration, 'minutes').format('HH:mm'), to: slot.to };

    //                     regularTransformedToIrregular.push(newSlot1, newSlot2);

    //                     const newIrregularDay = {
    //                         date: Timestamp.fromDate(adjustedDateValue.toDate()),
    //                         slots: regularTransformedToIrregular
    //                     };

    //                     selectedTeacherObject.scheduling.availabilities.irregulars.push(newIrregularDay);
    //                 }
    //             }


    //         }
    //     }
    //     addAvailability(selectedTeacherObject.scheduling.availabilities);
    // }

    const handleUpdateAvails = (selectedTeacherObject) => {

        const TeacherObjectToReturn = selectedTeacherObject;

        const appointments = [{
            duration: 20,
            from: "10:45",
            date: { seconds: 1689570000, nanoseconds: 0 },
        }];

        for (const appointment of appointments) {
            //iterate through each appointment of the appointments array

            const irregularScheduleForCurrentDate = TeacherObjectToReturn.scheduling.availabilities.irregulars.find((d) => {
                const dateJs = d.date.toDate();
                const dateTwo = new Timestamp(appointment.date.seconds, appointment.date.nanoseconds);
                const dateJsTwo = dateTwo.toDate();
                return dateJs.toISOString().split("T")[0] === dateJsTwo.toISOString().split("T")[0];
            });

            const toBeConverted = new Timestamp(appointment.date.seconds, appointment.date.nanoseconds);
            const convertedDate = moment(toBeConverted.toDate()).format("DD/MM/YYYY");
            const dateValueAsMoment = moment(convertedDate, "DD/MM/YYYY");

            if (irregularScheduleForCurrentDate) {
                const toBeConvertedTwo = new Timestamp(irregularScheduleForCurrentDate.date.seconds, irregularScheduleForCurrentDate.date.nanoseconds);
                const convertedDateTwo = moment(toBeConvertedTwo.toDate()).format("DD/MM/YYYY");
                const dateValueAsMomentTwo = moment(convertedDateTwo, "DD/MM/YYYY");

                for (const slot of irregularScheduleForCurrentDate.slots) {
                    const slotStart = dateValueAsMomentTwo.clone().set({
                        hour: parseInt(slot.from.split(":")[0]),
                        minute: parseInt(slot.from.split(":")[1]),
                        second: 0,
                        millisecond: 0,
                    });

                    const slotEnd = dateValueAsMomentTwo.clone().set({
                        hour: parseInt(slot.to.split(":")[0]),
                        minute: parseInt(slot.to.split(":")[1]),
                        second: 0,
                        millisecond: 0,
                    });

                    const timeToCheck = dateValueAsMoment.clone().set({
                        hour: parseInt(appointment.from.split(":")[0]),
                        minute: parseInt(appointment.from.split(":")[1]),
                        second: 0,
                        millisecond: 0,
                    });

                    const foundMatch = (timeToCheck.isSameOrAfter(slotStart) && timeToCheck.clone().add(appointment.duration, "minutes").isSameOrBefore(slotEnd));

                    if (foundMatch) {
                        console.log("Found that I need to adjust times for an irregualr dar with", slotStart, slotEnd, timeToCheck);
                        irregularScheduleForCurrentDate.slots = irregularScheduleForCurrentDate.slots.filter((s) => s !== slot);
                        // Create two new slots
                        const newSlot1 = { from: slot.from, to: timeToCheck.format("HH:mm") };
                        const newSlot2 = { from: timeToCheck.clone().add(appointment.duration, "minutes").format("HH:mm"), to: slot.to };
                        // Add the new slots
                        irregularScheduleForCurrentDate.slots.push(newSlot1, newSlot2);
                        TeacherObjectToReturn.scheduling.availabilities.irregulars.dateToAdjust = irregularScheduleForCurrentDate;
                    }
                }
            } else {
                // If there is no irregular schedule, we'll create one based on the regular schedule
                const dayOfWeek = dateValueAsMoment.format("dddd");
                const regularScheduleForCurrentDate = TeacherObjectToReturn.scheduling.availabilities.regulars[dayOfWeek];

                for (const slot of regularScheduleForCurrentDate) {
                    const slotStart = dateValueAsMoment.clone().set({
                        hour: parseInt(slot.from.split(":")[0]),
                        minute: parseInt(slot.from.split(":")[1]),
                        second: 0,
                        millisecond: 0,
                    });

                    const slotEnd = dateValueAsMoment.clone().set({
                        hour: parseInt(slot.to.split(":")[0]),
                        minute: parseInt(slot.to.split(":")[1]),
                        second: 0,
                        millisecond: 0,
                    });

                    const timeToCheck = dateValueAsMoment.clone().set({
                        hour: parseInt(appointment.from.split(":")[0]),
                        minute: parseInt(appointment.from.split(":")[1]),
                        second: 0,
                        millisecond: 0,
                    });


                    const foundMatch = (timeToCheck.isSameOrAfter(slotStart) && timeToCheck.clone().add(appointment.duration, "minutes").isSameOrBefore(slotEnd));

                    if (foundMatch) {
                        console.log("Found that I need to adjust times for an regualr dar with", slotStart, slotEnd, timeToCheck);


                        const regularTransformedToIrregular = regularScheduleForCurrentDate.filter((s) => s !== slot);
                        // Create two new slots
                        const newSlot1 = { from: slot.from, to: timeToCheck.format("HH:mm") };
                        const newSlot2 = { from: timeToCheck.clone().add(appointment.duration, "minutes").format("HH:mm"), to: slot.to };

                        regularTransformedToIrregular.push(newSlot1, newSlot2);

                        const newIrregularDay = {
                            date: Timestamp.fromDate(dateValueAsMoment.toDate()),
                            slots: regularTransformedToIrregular,
                        };

                        TeacherObjectToReturn.scheduling.availabilities.irregulars.push(newIrregularDay);
                    }
                }
            }
        }
        console.log('and this is udpatedTeahcer', TeacherObjectToReturn);

        return TeacherObjectToReturn;

    }

    return (
        <ThemeProvider theme={theme}>

            <Dialog
                open={newBookingOpen}
                onClose={handleNewBookingClose}
                maxWidth='md'
                fullWidth={true}
            >
                <DialogTitle>
                <Box sx={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid grey', py: 1 }}>
                    <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', fontSize: 24 }}>
                        My Availabilities
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleNewBookingClose}
                        sx={{ marginLeft: 'auto' }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                </DialogTitle> 
                {/* <Divider variant="middle" flexItem /> */}
                <DialogContent>
                    <Box sx={{ width: '100%' }}>
                        <Stepper nonLinear activeStep={activeStep} >
                            {steps.map((label, index) => (
                                <Step key={label} completed={completed[index]} >
                                    <StepButton color="#6C5DD3" onClick={handleStep(index)}>
                                        {label}
                                    </StepButton>
                                </Step>
                            ))}
                        </Stepper>

                    </Box>
                </DialogContent>
                <DialogContent>
                    <Box>
                    {activeStep == 0 ? (
                        <React.Fragment>
                            <Stack direction="row" spacing={3} justifyContent="center">
                                {usersSavedTeachers.map((teacher, index) => (
                                    <Box key={index}>
                                        <Checkbox
                                            {...label}
                                            checked={selectedTeacher[index] ?? false}
                                            icon={<TeacherIcon teacher={teacher} />}
                                            checkedIcon={<TeacherIcon color="#6C5DD3" teacher={teacher} />}
                                            onChange={(event) => {
                                                handleSelectedTeacher(event, index)
                                            }}
                                        />
                                    </Box>
                                ))}
                            </Stack>
                        </React.Fragment>
                    ) : null}

                        {activeStep == 1 ? (
                            <React.Fragment>
                                <Box>
                                    <List component={Stack} direction="column">
                                        {selectedTeacherPackages.map((teacherPackage, index) => (
                                            <Checkbox
                                                {...label}
                                                key={index}
                                                checked={selectedPackage[index] ?? false}
                                                icon={<PackageCard teacherPackage={teacherPackage} />}
                                                checkedIcon={<PackageCard color="#6C5DD3" teacherPackage={teacherPackage} />}
                                                onChange={(event) => {
                                                    handleSelectedPackage(event, index)
                                                }}
                                            />
                                        ))}

                                    </List>
                                </Box>
                            </React.Fragment>
                        ) : null}

                        {activeStep == 2 ? (
                            <React.Fragment>
                                <Box>
                                    <Divider />
                                    <Grid
                                        container
                                        direction="row"
                                        sx={{ py: '40px' }}
                                    >
                                        <Grid item xs={7}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateCalendar
                                                    shouldDisableDate={(date) => checkDateAvailble(date)}
                                                    value={dateValue}
                                                    onChange={(newValue) => setDateValue(newValue)}
                                                    sx={{ my: 1.5, py: 1.5, px: 2, borderColor: "#6C5DD3", borderRadius: '12px', border: 2, boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
                                                />

                                            </LocalizationProvider>
                                        </Grid>

                                        <Grid item xs={5}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: 20, fontFamily: 'Poppins', mb: 2 }}>
                                                Choose a Time
                                            </Typography>                                            
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <Box sx={{ height: '50%', overflow: 'auto', alignItems: "center", justifyContent: "center" }}> 
                                                    {specificSlots && specificSlots.map((slot, index) => (
                                                        <Button 
                                                            key={index} 
                                                            onClick={() => setTimeValue(slot)} 
                                                            sx={{
                                                                display: 'block', 
                                                                width: '60%', 
                                                                my: 1, 
                                                                borderRadius: '100px', 
                                                                border: '2px solid #6C5DD3',
                                                                bgcolor: timeValue === slot ? '#6C5DD3' : '#FFFFFF',
                                                                color: timeValue === slot ? '#FFFFFF' : '#6C5DD3',
                                                                '&:hover': {
                                                                    bgcolor: '#6C5DD3',
                                                                    color: '#FFFFFF',
                                                                },
                                                                fontFamily: 'Poppins', 
                                                                fontWeight: 'bold', 
                                                                fontSize: 16,
                                                            }}
                                                        >
                                                            {moment(slot, 'HH:mm').format('HH:mm')}
                                                        </Button>
                                                    ))}
                                                </Box>
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </React.Fragment>
                        ) : null}
                    </Box>
                </DialogContent>
                <DialogActions>
                    {allStepsCompleted() ? (
                        <React.Fragment>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Button onClick={handleRequestBooking} disabled={!timeValue}>
                                    Request Booking
                                </Button>
                            </Box>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Box sx={{ display: 'flex', flexDirection: 'row', py: 2, px:2, justifyContent: "space-between", alignItems: "flex-start", width: "100%", flexGrow: 1 }}>
                                <Button
                                    sx={{ px: '20px', py: '10px', borderRadius: '15px', backgroundColor: '#6C5DD3' }} 
                                    variant="contained"
                                    endIcon={<ArrowBackIcon />}
                                    onClick={handleBack}
                                >
                                    <Box sx={{ fontWeight: 'bold' }}>Back</Box>
                                </Button>

                                {completedSteps() === totalSteps() - 1 ? (
                                    <Button
                                        sx={{ px: '20px', py: '10px', borderRadius: '15px', backgroundColor: '#6C5DD3' }} 
                                        variant="contained"
                                        endIcon={<EventAvailableIcon />}
                                        onClick={handleRequestBooking}
                                    >
                                        <Box sx={{ fontWeight: 'bold' }}>Request Booking</Box>
                                    </Button>
                                )
                                    :
                                    (
                                        <Button
                                            sx={{ px: '20px', py: '10px', borderRadius: '15px', backgroundColor: '#6C5DD3' }} 
                                            variant="contained"
                                            endIcon={<ArrowForwardIcon />}
                                            onClick={handleComplete}
                                        >
                                            <Box sx={{ fontWeight: 'bold' }}>Continue</Box>
                                        </Button>      
                                    )
                                }
                            </Box>
                        </React.Fragment>
                    )}
            </DialogActions>

            </Dialog>
        </ThemeProvider>

    );
};

export default NewBookingModal;