import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Container, Typography, Avatar, IconButton, Grid } from "@mui/material";
import { styled } from "@mui/system";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ProfileBackground from '../profile_components/ProfileBackground';
import ProfilePersonality from '../profile_components/ProfilePersonality';
import ProfilePreferences from '../profile_components/ProfilePreferences';
import BookIcon from '@mui/icons-material/Book';
import MessageIcon from '@mui/icons-material/Message';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { arrayUnion, doc, updateDoc, getFirestore, getDoc } from '../../services/firebase';
import { AuthContext } from '../../contexts/AuthContext';

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(20),
  height: theme.spacing(20),
  margin: theme.spacing(1),
}));

const ViewTeacherProfile = () => { 
  const navigate = useNavigate();
  const { teacherId } = useParams();
  const { currentUser, userProfile, updateLocalSavedTeachers } = useContext(AuthContext);
  const [teacherData, setTeacherData] = useState(null);
  const [loading, setLoading] = useState(true);
  const db = getFirestore();

  useEffect(() => {
    const fetchTeacherData = async () => {
      try {
        const teacherDocRef = doc(db, 'users', teacherId);
        const teacherDoc = await getDoc(teacherDocRef);
        if (teacherDoc.exists()) {
          setTeacherData(teacherDoc.data());
        } else {
          console.log("Teacher does not exist!");
        }
      } catch (error) {
        console.error("Error fetching teacher:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchTeacherData();
  }, [teacherId, db]);

  if (!teacherData || loading) {
    return <div>Loading...</div>;
  }

  const renderLearningOrTeachingMode = () => {
    const mode = teacherData.onboardingData?.teachingMode;
    if(mode === 'Both/Either') {
      return 'Online | In Person';
    }
    return mode || '';
  };

  const renderYearsOfTeaching = () => {
    return teacherData.onboardingData?.teachingYears || '';
  };

  const renderInstruments = () => {
    const instruments = teacherData.onboardingData?.teachingInstruments;
    return instruments ? instruments.map(instrument => (
      <Typography key={instrument} variant="body1" sx={{ fontFamily: 'Poppins', fontWeight: 'regular', fontSize: 20 }}>{instrument}</Typography>
    )) : null;
  };

  const renderGenres = () => {
    const genres = teacherData.onboardingData?.teachingGenres;
    return genres ? genres.map(genre => (
      <Typography key={genre} variant="body1" sx={{ fontFamily: 'Poppins', fontWeight: 'regular', fontSize: 20 }}>{genre}</Typography>
    )) : null;
  };

  const handleMessageTeacher = () => {
    if (!currentUser || !userProfile) {
      navigate('/signup');
      return;
    }
    else{
      if(!isTeacherSaved){
        handleAddFavorite()
      }
      navigate('/messaging')
    }
  };

  const handleBookWithTeacher = () => {
    if (!currentUser || !userProfile) {
        navigate('/signup');
        return;
    }
    else{
        if(!isTeacherSaved){
          handleAddFavorite()
        }
        navigate('/scheduling')
    }
  };

  const handleAddFavorite = async () => {
    if (!currentUser || !userProfile) {
        navigate('/signup');
        return;
    }

    try {
        const newTeacher = { id: teacherData.id };
        const userDocRef = doc(db, 'users', currentUser.uid);
        await updateDoc(userDocRef, {
            savedTeachers: arrayUnion(newTeacher)
        });

        const teacherDocRef = doc(db, 'users', teacherData.id);
        await updateDoc(teacherDocRef, {
            students: arrayUnion({ id: currentUser.uid })
        });

        // Update local saved teachers list
        const newSavedTeachers = [...userProfile.savedTeachers, newTeacher];
        updateLocalSavedTeachers(newSavedTeachers);
    } catch (error) {
        console.error("Error adding favorite", error);
    }
};


const isInSavedTeachersArray = (array, id) => {
    return !!array.find(item => item.id === id);
}

const isTeacherSaved = userProfile && userProfile.savedTeachers ? isInSavedTeachersArray(userProfile.savedTeachers, teacherData.id) : false;


const handleRemoveFavorite = async () => {
    if (!currentUser || !userProfile) {
        navigate('/signup');
        return;
    }

    try {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const updatedSavedTeachers = userProfile.savedTeachers.filter(savedTeacher => savedTeacher.id !== teacherData.id);
        await updateDoc(userDocRef, {
            savedTeachers: updatedSavedTeachers
        });

        // Removing student from teacher's student list
        const teacherDocRef = doc(db, 'users', teacherData.id);
        const updatedStudents = teacherData.students.filter(student => student.id !== currentUser.uid);
        await updateDoc(teacherDocRef, {
            students: updatedStudents
        });

        // Update local saved teachers list
        updateLocalSavedTeachers(updatedSavedTeachers);
    } catch (error) {
        console.error("Error removing favorite", error);
    }
};

  return (
    <Container maxWidth={false}>
      <Box sx={{minHeight: '90vh', py: 5, px: "80px"}}>
        <Grid container spacing={10}>
          <Grid item xs={12} sm={12} md={3}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1,  }}>
              <StyledAvatar src={teacherData.pictureURL || ''}></StyledAvatar>
              <Typography variant="h6" sx={{ fontFamily: 'Poppins', fontWeight: 'semibold', fontSize: 30 }}>{teacherData.fullName || ''}</Typography>
              <Typography variant="body1" sx={{ fontFamily: 'Poppins', fontWeight: 'medium', fontSize: 24, color: '#7F7FD4' }}>{renderLearningOrTeachingMode()}</Typography>
              <Typography variant="body1" sx={{ fontFamily: 'Poppins', fontWeight: 'medium', fontSize: 22, color: 'black', display: 'flex', alignItems: 'center' }}><AccessTimeIcon sx={{ marginRight: 1 }}/>{renderYearsOfTeaching()} years of experience</Typography>
              <Typography variant="h6" sx={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 24, color: 'black', mt: 2 }}>Instrument(s)</Typography>
              {renderInstruments()}
              <Typography variant="h6" sx={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 24, color: 'black', mt: 2 }}>Genre(s)</Typography>
              {renderGenres()}
              <div>
                <Typography variant="h6" sx={{ fontFamily: 'Poppins', fontWeight: 'semibold', fontSize: 20, mt: 2 }}>Teacher Code:</Typography>
                <Typography variant="body1">{teacherData.teacherCode || ''}</Typography>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2}}>       
                <Button
                    sx={{ px: '20px', py: '10px', borderRadius: '15px', backgroundColor: '#C52B65' }} 
                    variant="contained"
                    endIcon={<MessageIcon />}
                    onClick={handleMessageTeacher}  
                >
                    <Box sx={{ fontWeight: 'bold' }}>Message</Box>
                </Button>                
                <Button
                    sx={{ px: '20px', py: '10px', borderRadius: '15px', backgroundColor: '#36C752' }} 
                    variant="contained"
                    endIcon={<BookIcon />}
                    onClick={handleBookWithTeacher}  
                >
                    <Box sx={{ fontWeight: 'bold' }}>Book</Box>
                </Button>                
                {isTeacherSaved ? (
                    <IconButton aria-label="remove from favorites" onClick={handleRemoveFavorite}>
                        <FavoriteIcon sx={{ color: '#6C5DD3', fontSize: "1.5em" }} />
                    </IconButton>
                ) : (
                    <IconButton aria-label="add to favorites" onClick={handleAddFavorite}>
                        <FavoriteBorderIcon sx={{ fontSize: "1.5em" }}/>
                    </IconButton>
                )}
            </Box>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
              <ProfileBackground dataProfile={teacherData} />
              <ProfilePersonality dataProfile={teacherData}/>
              <ProfilePreferences dataProfile={teacherData}/>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ViewTeacherProfile;
