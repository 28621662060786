import React, { useState, useContext } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'; 
import { ExploreContext } from '../../../contexts/ExploreContext';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
  },
  palette: {
    primary: {
      main: '#6C5DD3',
    },
  },
});

const genres = [
  "Pop",
  "Rock",
  "Country",
  "Jazz",
  "Blues",
  "Classical",
  "Hip Hop",
  "Reggae",
  "Electronic",
  "Folk",
  "Soul",
  "R&B",
  "Bluegrass",
  "Gospel",
  "Punk",
  "Metal",
  "Indie",
  "Latin",
  "World Music",
  "Alternative",
  "Other"
];

const instruments = [
  "Piano",
  "Guitar",
  "Voice",
  "Violin",
  "Cello",
  "Flute",
  "Drums",
  "Saxophone",
  "Clarinet",
  "Trumpet",
  "Harp",
  "Viola",
  "Trombone",
  "Ukulele",
  "Bass Guitar",
  "Double Bass",
  "Accordion",
  "Banjo",
  "Mandolin",
  "Harmonica",
  "Oboe",
  "Synthesizer",
  "Other"
];

function OnboardingStudent3() {
  const [onboardingData, setOnboardingData] = useState({
    learnGoal: "",
    instruments: [],
    genres: [],
    experienceYears: "",
    musicExperience: "",
    musicChallenge: "",
    favoriteMusic: "",
  });

  const navigate = useNavigate();
  const { updateOnboardingData } = useContext(ExploreContext); 

  const handleChange = (event) => {
    setOnboardingData({
      ...onboardingData,
      [event.target.name]: event.target.value,
    });
  };

  const handleInstrumentChange = (instrument) => {
    setOnboardingData(prevData => {
        if (prevData.instruments.includes(instrument)) {
            return {
                ...prevData,
                instruments: prevData.instruments.filter(i => i !== instrument)
            }
        } else {
            return {
                ...prevData,
                instruments: [...prevData.instruments, instrument]
            }
        }
    });
  };

  const isDisabled = onboardingData.instruments.length === 0 || onboardingData.genres.length === 0 || !onboardingData.experienceYears;

  const handleGenreChange = (genre) => {
    setOnboardingData(prevData => {
        if (prevData.genres.includes(genre)) {
            return {
                ...prevData,
                genres: prevData.genres.filter(g => g !== genre)
            }
        } else {
            return {
                ...prevData,
                genres: [...prevData.genres, genre]
            }
        }
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    updateOnboardingData(onboardingData);
    navigate('/onboardingStudent4');
  };

  const handleBack = () => {
    navigate('/onboardingStudent2');
  };
  
  return (
    <ThemeProvider theme={theme}>
      <Box 
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
        className="login-container"
        sx={{ marginBottom: '100px' }} 
      >
        <Box 
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          className="form-container"
          sx={{mt: 4, maxWidth: "60vw"}}
        >
          <Typography variant="h4" className="login-heading" gutterBottom>Musical Interests & Background</Typography>

          <form onSubmit={handleSubmit} className="login-form" style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 20}}>
            <Typography sx={{ fontWeight: 'semibold', fontSize: 20, fontFamily: 'Poppins' }}>In one sentence, what would you like to learn? (Optional)</Typography>
            <TextField
              variant="outlined"
              className="login-input"
              margin="normal"
              name="learnGoal"
              value={onboardingData.learnGoal}
              onChange={handleChange}
              fullWidth
              rows={3}
              multiline
            />

          <Typography sx={{ fontWeight: 'semibold', fontSize: 20, fontFamily: 'Poppins' }}>Which instrument(s) are you interested in learning? (Required)</Typography>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
              {instruments.map((instrument) => (
                  <Button
                      key={instrument}
                      variant="contained"
                      sx={{ 
                          backgroundColor: onboardingData.instruments.includes(instrument) ? "#6C5DD3" : "grey", 
                          whiteSpace: 'nowrap',
                          overflow: 'visible',
                          display: 'inline-flex',
                          px: 2
                      }}
                      onClick={() => handleInstrumentChange(instrument)}
                  >
                      {instrument}
                  </Button>
              ))}
            </div>

            <Typography sx={{ fontWeight: 'semibold', fontSize: 20, fontFamily: 'Poppins' }}>What genre(s) are you interested in learning? (Required)</Typography>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
              {genres.map((genre) => (
                  <Button
                      key={genre}
                      variant="contained"
                      sx={{ 
                          backgroundColor: onboardingData.genres.includes(genre) ? "#6C5DD3" : "grey", 
                          whiteSpace: 'nowrap',
                          overflow: 'visible',
                          display: 'inline-flex',
                          px: 2
                      }}
                      onClick={() => handleGenreChange(genre)}
                  >
                      {genre}
                  </Button>
              ))}
            </div>

            <Typography sx={{ fontWeight: 'semibold', fontSize: 20, fontFamily: 'Poppins' }}>How many years have you been learning music or playing an instrument? (Required)</Typography>
            <TextField
              variant="outlined"
              className="login-input"
              margin="normal"
              name="experienceYears"
              value={onboardingData.experienceYears}
              onChange={handleChange}
              type="number"
            />

            <Typography sx={{ fontWeight: 'semibold', fontSize: 20, fontFamily: 'Poppins' }}>In your own words, describe your experience with learning music (Optional)</Typography>
            <TextField
              variant="outlined"
              className="login-input"
              margin="normal"
              name="musicExperience"
              value={onboardingData.musicExperience}
              onChange={handleChange}
              fullWidth
              rows={3}
              multiline
            />

            <Typography sx={{ fontWeight: 'semibold', fontSize: 20, fontFamily: 'Poppins' }}>What is your biggest challenge when it comes to learning music? (Optional)</Typography>
            <TextField
              variant="outlined"
              className="login-input"
              margin="normal"
              name="musicChallenge"
              value={onboardingData.musicChallenge}
              onChange={handleChange}
              fullWidth
              rows={3}
              multiline
            />

            <Typography sx={{ fontWeight: 'semibold', fontSize: 20, fontFamily: 'Poppins' }}>What is your favorite song or type of music? (Optional)</Typography>
            <TextField
              variant="outlined"
              className="login-input"
              margin="normal"
              name="favoriteMusic"
              value={onboardingData.favoriteMusic}
              onChange={handleChange}
              fullWidth
              rows={3}
              multiline
            />

            <Box display="flex" justifyContent="space-between" marginY={2} sx={{width: '100%'}}>
                <Button
                    sx={{ px: '20px', py: '10px', borderRadius: '15px', backgroundColor: '#6C5DD3' }} 
                    variant="contained"
                    endIcon={<ArrowBackIcon />}
                    onClick={handleBack}
                >
                <Box sx={{ fontWeight: 'bold' }}>Back</Box>
                </Button>
                <Button
                    sx={{ px: '20px', py: '10px', borderRadius: '15px', backgroundColor: '#6C5DD3' }} 
                    variant="contained"
                    endIcon={<ArrowForwardIcon />}
                    disabled={isDisabled}
                    onClick={handleSubmit}
                >
                    <Box sx={{ fontWeight: 'bold' }}>Continue</Box>
                </Button>                  
            </Box>
          </form>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default OnboardingStudent3;
