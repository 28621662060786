import { createTheme } from "@mui/material";

const theme = createTheme({
    palette: {
      achord_purple: {
        light: '#897ddb',
        main: '#6C5DD3',
        darker: '#4b4193',
        contrastText: "#fff"
      },
      achord_lighter_purple: {
        light: '#897ddb',
        main: '#C4C4FF',
        darker: '#4b4193',
        contrastText: "#fff"
      }
    },
  });

  export default theme