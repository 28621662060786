import React, { useState, useContext } from 'react';
import { TextField, Button, Box, Typography, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'; 
import { ExploreContext } from '../../../contexts/ExploreContext';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
  },
  palette: {
    primary: {
      main: '#6C5DD3',
    },
  },
});

const traits = [
  "Patient",
  "Enthusiastic",
  "Methodical",
  "Innovative",
  "Adaptable",
  "Encouraging",
  "Professional",
  "Fun-loving",
  "Strict",
  "Nurturing",
  "Passionate",
  "Inspirational",
  "Rigorous",
  "Creative",
  "Empathetic",
  "Disciplined",
  "Motivated",
  "Knowledgeable",
  "Reliable",
  "Communicative"
];

function OnboardingTeacher3() {
  const [onboardingData, setOnboardingData] = useState({
    teacherDescription: "",
    teacherTraits: [],
    personalityDescription: "",
    favoriteMusic: "",
    goalsInMusic: ""
  });

  const navigate = useNavigate();
  const { updateOnboardingData } = useContext(ExploreContext); 

  const handleChange = (event) => {
    setOnboardingData({
      ...onboardingData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    updateOnboardingData(onboardingData);
    navigate('/onboardingTeacher4');
  };

  const handleBack = () => {
    navigate('/onboardingTeacher2');
  };

  const handleTraitChange = (trait) => {
    if (onboardingData.teacherTraits.includes(trait)) {
      setOnboardingData({
        ...onboardingData,
        teacherTraits: onboardingData.teacherTraits.filter((item) => item !== trait),
      });
    } else if (onboardingData.teacherTraits.length < 5) {
      setOnboardingData({
        ...onboardingData,
        teacherTraits: [...onboardingData.teacherTraits, trait],
      });
    } else {
      // Optionally, show an error message to the user
      console.log("You can only select up to 5 traits");
      alert("You can only choose up to 5 traits");
    }
  };
  

  const isDisabled = onboardingData.teacherTraits.length === 0 || !onboardingData.teacherDescription;


  return (
    <ThemeProvider theme={theme}>
      <Box 
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
        className="login-container"
        sx={{ marginBottom: '100px' }} 
      >
        <Box 
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          className="form-container"
          sx={{mt: 4, maxWidth: "60vw"}}
        >
          <Typography variant="h4" className="login-heading" gutterBottom>Describe Yourself</Typography>

          <form onSubmit={handleSubmit} className="login-form" style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 10}}>
            
            <Typography sx={{ fontWeight: 'semibold', fontSize: 20, fontFamily: 'Poppins' }}>Describe yourself in one sentence (Required)</Typography>
            <TextField
              variant="outlined"
              className="login-input"
              margin="normal"
              name="teacherDescription"
              value={onboardingData.teacherDescription}
              onChange={handleChange}
              fullWidth
              rows={3}
              multiline
              required
            />
            
            <Typography sx={{ fontWeight: 'semibold', fontSize: 20, fontFamily: 'Poppins' }}>Choose the traits that best describe you (Required)</Typography>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
              {traits.map((trait) => (
                <Button
                  key={trait}
                  variant="contained"
                  sx={{ 
                    backgroundColor: onboardingData.teacherTraits.includes(trait) ? "#6C5DD3" : "grey", 
                    whiteSpace: 'nowrap',
                    overflow: 'visible',
                    display: 'inline-flex',
                    px: 2
                  }}
                  onClick={() => handleTraitChange(trait)}
                >
                  {trait}
                </Button>
              ))}
            </div>

            <Typography sx={{ fontWeight: 'semibold', fontSize: 20, fontFamily: 'Poppins' }}>Describe your personality in more detail (Optional)</Typography>
            <TextField
              variant="outlined"
              className="login-input"
              margin="normal"
              name="personalityDescription"
              value={onboardingData.personalityDescription}
              onChange={handleChange}
              fullWidth
              rows={3}
              multiline
            />

            <Typography sx={{ fontWeight: 'semibold', fontSize: 20, fontFamily: 'Poppins' }}>What is your personal favorite song or type of music? (Optional)</Typography>
            <TextField
              variant="outlined"
              className="login-input"
              margin="normal"
              name="favoriteMusic"
              value={onboardingData.favoriteMusic}
              onChange={handleChange}
              fullWidth
              rows={3}
              multiline
            />

            <Typography sx={{ fontWeight: 'semibold', fontSize: 20, fontFamily: 'Poppins' }}>What are your short-term and long-term goals in music? (Optional)</Typography>
            <TextField
              variant="outlined"
              className="login-input"
              margin="normal"
              name="goalsInMusic"
              value={onboardingData.goalsInMusic}
              onChange={handleChange}
              fullWidth
              rows={3}
              multiline
            />

            <Box display="flex" justifyContent="space-between" marginY={2} sx={{width: '100%'}}>
                <Button
                    sx={{ px: '20px', py: '10px', borderRadius: '15px', backgroundColor: '#6C5DD3' }} 
                    variant="contained"
                    endIcon={<ArrowBackIcon />}
                    onClick={handleBack}
                >
                <Box sx={{ fontWeight: 'bold' }}>Back</Box>
                </Button>
                <Button
                    sx={{ px: '20px', py: '10px', borderRadius: '15px', backgroundColor: '#6C5DD3' }} 
                    variant="contained"
                    endIcon={<ArrowForwardIcon />}
                    type="submit"
                    disabled={isDisabled}
                >
                    <Box sx={{ fontWeight: 'bold' }}>Continue</Box>
                </Button>                  
            </Box>
          </form>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default OnboardingTeacher3;
