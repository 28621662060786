import React, { useState, useContext } from 'react';
import { TextField, Button, Box, Typography, Select, MenuItem, FormControl } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'; 
import { ExploreContext } from '../../../contexts/ExploreContext';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
  },
  palette: {
    primary: {
      main: '#6C5DD3',
    },
  },
});

const traits = [
  "Patient",
  "Enthusiastic",
  "Methodical",
  "Innovative",
  "Adaptable",
  "Encouraging",
  "Professional",
  "Fun-loving",
  "Strict",
  "Nurturing",
  "Passionate",
  "Inspirational",
  "Rigorous",
  "Creative",
  "Empathetic",
  "Disciplined",
  "Motivated",
  "Knowledgeable",
  "Reliable",
  "Communicative"
];

function OnboardingStudent4() {
  const [personalData, setPersonalData] = useState({
    shortDescription: "",
    traits: [],
    detailedDescription: "",
    goals: "",
  });

  const navigate = useNavigate();
  const { updateOnboardingData } = useContext(ExploreContext); 

  const handleChange = (event) => {
    setPersonalData({
      ...personalData,
      [event.target.name]: event.target.value,
    });
  };

  const handleTraitsChange = (trait) => {
    setPersonalData(prevData => {
        if (prevData.traits.includes(trait)) {
            return {
                ...prevData,
                traits: prevData.traits.filter(t => t !== trait)
            }
        } else {
            if(prevData.traits.length < 5) {
                return {
                    ...prevData,
                    traits: [...prevData.traits, trait]
                }
            } else {
                alert("You can only choose up to 5 traits");
                return prevData;
            }
        }
    });
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    updateOnboardingData(personalData);
    navigate('/onboardingStudent5');
  };

  const handleBack = () => {
    navigate('/onboardingStudent3');
  };

  const isDisabled = personalData.traits.length === 0;


  return (
    <ThemeProvider theme={theme}>
      <Box 
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
        sx={{ marginBottom: '100px'}} 
      >
        <Box 
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          className="form-container"
          sx={{mt: 4, maxWidth: "60vw"}}
        >
          <Typography variant="h4" className="login-heading" gutterBottom>Personal Information</Typography>
  
          <form onSubmit={handleSubmit} className="login-form" style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 10}}>
            
            <Typography sx={{ fontWeight: 'semibold', fontSize: 20, fontFamily: 'Poppins' }}>Describe yourself in one sentence (Optional)</Typography>
            <TextField
              required
              variant="outlined"
              className="login-input"
              margin="normal"
              name="shortDescription"
              value={personalData.shortDescription}
              onChange={handleChange}
              fullWidth
              multiline
              rows={3}
            />
  
            <Typography variant="subtitle1" sx={{ fontWeight: 'semibold', fontSize: 20, fontFamily: 'Poppins' }}>Choose the traits that describe your ideal teacher (Required)</Typography>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                {traits.map((trait) => (
                    <Button
                        key={trait}
                        variant="contained"
                        sx={{ 
                            backgroundColor: personalData.traits.includes(trait) ? "#6C5DD3" : "grey", 
                            whiteSpace: 'nowrap',
                            overflow: 'visible',
                            display: 'inline-flex',
                            px: 2
                        }}
                        onClick={() => handleTraitsChange(trait)}
                    >
                        {trait}
                    </Button>
                ))}
              </div>
  
            <Typography variant="subtitle1" sx={{ fontWeight: 'semibold', fontSize: 20, fontFamily: 'Poppins' }}>Describe your personality in more detail (Optional)</Typography>
            <TextField
              variant="outlined"
              className="login-input"
              margin="normal"
              name="detailedDescription"
              value={personalData.detailedDescription}
              onChange={handleChange}
              fullWidth
              multiline
              rows={3}
            />
  
            <Typography variant="subtitle1" sx={{ fontWeight: 'semibold', fontSize: 20, fontFamily: 'Poppins' }}>What are your short-term and long-term goals in music? (Optional)</Typography>
            <TextField
              variant="outlined"
              className="login-input"
              margin="normal"
              name="goals"
              value={personalData.goals}
              onChange={handleChange}
              fullWidth
              multiline
              rows={3}
            />
  
            <Box display="flex" justifyContent="space-between" marginY={2} sx={{width: '100%'}}>
                <Button
                    sx={{ px: '20px', py: '10px', borderRadius: '15px', backgroundColor: '#6C5DD3' }} 
                    variant="contained"
                    endIcon={<ArrowBackIcon />}
                    onClick={handleBack}
                >
                <Box sx={{ fontWeight: 'bold' }}>Back</Box>
                </Button>
                <Button
                    sx={{ px: '20px', py: '10px', borderRadius: '15px', backgroundColor: '#6C5DD3' }} 
                    variant="contained"
                    endIcon={<ArrowForwardIcon />}
                    onClick={handleSubmit}
                    disabled={isDisabled}
                >
                    <Box sx={{ fontWeight: 'bold' }}>Continue</Box>
                </Button>                  
            </Box>
          </form>
        </Box>
      </Box>
    </ThemeProvider>
  );  
}

export default OnboardingStudent4;
