import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { Box, Button, Container, Typography, Avatar, Divider, IconButton, Grid, Link } from "@mui/material";
import { styled } from "@mui/system";
import EditIcon from '@mui/icons-material/Edit';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import theme from "../theme";
import ProfileBackground from '../profile_components/ProfileBackground';
import ProfilePersonality from '../profile_components/ProfilePersonality';
import ProfilePreferences from '../profile_components/ProfilePreferences';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(20),
  height: theme.spacing(20),
  margin: theme.spacing(1),
}));

const MyProfile = () => {
  const navigate = useNavigate();
  const { userProfile, currentUser } = useContext(AuthContext);

  const handleNavigateEditProfile = () => {
    navigate("/editProfile");
  };

  const handleNavigateEnterTeacherCode = () => {
    navigate("/enterTeacherCode");
  };

  const handleOnboardingNavigation = () => {
    if(userProfile.userType === 'student') {
      navigate("/OnboardingStudent3");
    } else {
      navigate("/OnboardingTeacher2");
    }
  };

  if (!userProfile) {
    return <div>Loading...</div>; 
  }

  const renderLearningOrTeachingMode = () => {
    const mode = userProfile.userType === 'student' ? userProfile.onboardingData?.learningMode : userProfile.onboardingData?.teachingMode;
    if(mode === 'Both/Either') {
      return 'Online | In Person';
    }
    return mode || '';
  };

  const renderYearsOfExperienceOrTeaching = () => {
    return (userProfile.userType === 'student' ? userProfile.onboardingData?.experienceYears : userProfile.onboardingData?.teachingYears) || '';
  };

  const renderInstruments = () => {
    const instruments = userProfile.userType === 'student' ? userProfile.onboardingData?.instruments : userProfile.onboardingData?.teachingInstruments;
    return instruments ? instruments.map(instrument => (
      <Typography key={instrument} variant="body1" sx={{ fontFamily: 'Poppins', fontWeight: 'regular', fontSize: 20 }}>{instrument}</Typography>
    )) : null;
  };

  const renderGenres = () => {
    const genres = userProfile.userType === 'student' ? userProfile.onboardingData?.genres : userProfile.onboardingData?.teachingGenres;
    return genres ? genres.map(genre => (
      <Typography key={genre} variant="body1" sx={{ fontFamily: 'Poppins', fontWeight: 'regular', fontSize: 20 }}>{genre}</Typography>
    )) : null;
  };

  const shareableLink = `${window.location.origin}/teacher-profile/${currentUser.uid}`;

  return (
    <Container maxWidth={false}>
      <Box sx={{minHeight: '90vh', py: 5, px: "80px"}}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid black', py: 2, px: 2 }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold', fontFamily: 'Poppins' }}>My Profile</Typography>
          <IconButton onClick={handleNavigateEditProfile}><EditIcon /></IconButton>
        </Box>
        <Divider />
        <Grid container spacing={10}>
          <Grid item xs={12} sm={12} md={3}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1 }}>
              <StyledAvatar src={userProfile.pictureURL || ''}></StyledAvatar>
              <Typography variant="h6" sx={{ fontFamily: 'Poppins', fontWeight: 'semibold', fontSize: 30 }}>{userProfile.fullName || ''}</Typography>
              <Typography variant="body1" sx={{ fontFamily: 'Poppins', fontWeight: 'medium', fontSize: 24, color: '#7F7FD4' }}>{renderLearningOrTeachingMode()}</Typography>
              <Typography variant="body1" sx={{ fontFamily: 'Poppins', fontWeight: 'medium', fontSize: 22, color: 'black', display: 'flex', alignItems: 'center' }}><AccessTimeIcon sx={{ marginRight: 1 }}/>{renderYearsOfExperienceOrTeaching()} years of experience</Typography>
              <Typography variant="h6" sx={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 24, color: 'black' }}>Instrument(s)</Typography>
              {renderInstruments()}
              <Typography variant="h6" sx={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 24, color: 'black' }}>Genre(s)</Typography>
              {renderGenres()}
              {userProfile.userType === 'student' && (
                <Button onClick={handleNavigateEnterTeacherCode} variant="contained" sx={{ backgroundColor: theme.palette.achord_purple.main, }}>Add a New Teacher by Teacher Code</Button>
              )}
              {userProfile.userType === 'teacher' && (
                <div>
                  <Typography sx={{ fontFamily: 'Poppins', fontWeight: 'semibold', fontSize: 24, mt: 1}}>Teacher Code:</Typography>
                  <Typography variant="body1">{userProfile.teacherCode || ''}</Typography>
                  <Box sx={{mt: 2}}>
                      <Typography sx={{ fontFamily: 'Poppins', fontWeight: 'semibold', fontSize: 24 }}>
                          Share your profile:
                      </Typography>
                      <Typography sx={{ fontFamily: 'Poppins', fontWeight: 'regular', fontSize: 16 }}>
                          <Link href={shareableLink} target="_blank" rel="noopener noreferrer" underline="hover" color="inherit">
                              {shareableLink}
                          </Link>
                      </Typography>
                      <CopyToClipboard text={shareableLink}>
                          <Button 
                              sx={{
                                  backgroundColor: theme.palette.achord_purple.main, 
                                  color: "white", 
                                  mt: 2,
                                  '&:hover': {
                                      backgroundColor: '#C4C4FF'
                                  }
                              }}
                          >
                              Copy Link
                          </Button>
                      </CopyToClipboard>
                  </Box>
                </div>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              {
                userProfile.onboardingData ? (
                  <>
                    <ProfileBackground dataProfile={userProfile} />
                    <ProfilePersonality dataProfile={userProfile}/>
                    <ProfilePreferences dataProfile={userProfile}/>
                  </>
                ) : (
                  <Button onClick={handleOnboardingNavigation} variant="contained" sx={{ backgroundColor: theme.palette.achord_purple.main, mt: 5 }}>Complete your Profile</Button>
                )
              }
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default MyProfile;
