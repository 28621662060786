import { useContext, useEffect, useState, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Box, TextField, IconButton, Typography, Menu, MenuItem } from '@mui/material';
import { getFirestore, doc, onSnapshot, updateDoc, arrayUnion, serverTimestamp, deleteDoc, increment } from 'firebase/firestore';
import { AuthContext } from '../../contexts/AuthContext';
import MessageCard from './MessageCard';
import SendIcon from '@mui/icons-material/Send';
import theme from "../theme";
import MoreVertIcon from '@mui/icons-material/MoreVert';

const CurrentConversationInterface = ({ conversation, onConversationDelete, onConversationChange }) => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const { currentUser, userProfile, setCurrentProfileViewingId } = useContext(AuthContext);
    const messagesEndRef = useRef(null);
    const navigate = useNavigate(); 

    const [anchorEl, setAnchorEl] = useState(null);

    const handleFormSubmit = async (event) => {
        event.preventDefault(); // prevent the page from refreshing
        sendMessage();
    };

    const handleViewProfileClick = () => {
        // Determine the profile ID to set based on the user type
        const profileIdToView = userProfile.userType === 'teacher' ? conversation.studentId : conversation.teacherId;
    
        // Set the currentProfileViewingId using the profileIdToView
        setCurrentProfileViewingId(profileIdToView);
    
        // Navigate to '/viewProfile' route using the navigate function
        navigate('/viewProfile');
      };

    // Handle menu (open/close)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        const db = getFirestore();
        if (conversation) {
            const unsubscribe = onSnapshot(doc(db, "conversations", conversation.id), (doc) => {
                if(doc.exists()) { // Check if doc exists before setting state
                    setMessages(doc.data().messages);
                } else {
                    // Conversation has been deleted, clear messages from state
                    setMessages([]);
                }
            });
    
            return () => unsubscribe();
        }
    }, [conversation]);

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
        }
    }, [messages]);

    const sendMessage = async () => {
        const db = getFirestore();
        if (newMessage.trim().length > 0) {
            const message = {
                senderId: currentUser.uid,
                content: newMessage,
                timestamp: new Date(),
            };
    
            // Define the field to increment based on the user type
            const unreadField = userProfile.userType === 'teacher' ? 'studentUnreadMessages' : 'teacherUnreadMessages';
        
            await updateDoc(doc(db, "conversations", conversation.id), {
                messages: arrayUnion(message),
                latestMessage: newMessage,
                lastMessageTimestamp: serverTimestamp(),
                [unreadField]: increment(1), // Increase the unread message count
            });
        
            setNewMessage("");
            onConversationChange(); // Add this line
        }
    };
    
    
      

    const deleteConversation = async () => {
        const db = getFirestore();
        if (conversation) {
            await deleteDoc(doc(db, "conversations", conversation.id));
            setAnchorEl(null); // Close the menu after deleting the conversation
            onConversationDelete(); // Add this line
        }
    };
    
    const recipientName = userProfile.userType === 'teacher' ? conversation.studentName : conversation.teacherName;

    return (
        <Box sx={{
            height: '80vh',
            width: '100%',
            flexShrink: 0,
            borderRadius: '30px',
            border: '2px solid #C4C4FF',
            display: 'flex',
            flexDirection: 'column',
            boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.15)'
        }}>
            <Box sx={{
                p: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottom: '2px solid #C4C4FF',
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                    <Avatar sx={{ width: 60, height: 60 }} src={conversation.otherPartyProfilePic}/> 
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}> {/* Adjust the gap here */}
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'black', fontFamily: 'Poppins' }}>
                            {recipientName} {/* Replace with actual recipient name */}
                        </Typography>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'semibold', color: theme.palette.achord_purple.main, fontFamily: 'Poppins' }}>
                            <span onClick={handleViewProfileClick} style={{ cursor: 'pointer' }}>View Profile</span>
                        </Typography>
                    </Box>
                </Box>
                <IconButton sx={{ ml: 2 }} onClick={handleClick}>
                    <MoreVertIcon sx={{height: '40px', width: '40px'}} />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={deleteConversation}>Delete Conversation</MenuItem>
                </Menu>
            </Box>
            <Box sx={{
                flexGrow: 1,
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                paddingLeft: 3,
                paddingRight: 4,
                py: 2
            }} ref={messagesEndRef}>
            {conversation && messages.map((msg, index) => ( // Check if conversation exists before trying to map messages
                <MessageCard key={index} {...msg} isSent={currentUser.uid === msg.senderId} />
            ))}
            </Box>
            <Box sx={{ p: 2.5, display: 'flex', gap: 2 }}>
                <form onSubmit={handleFormSubmit} style={{ display: 'flex', width: '100%' }}>
                    <TextField
                        variant="outlined"
                        placeholder="Type a message"
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        fullWidth
                        size="small"
                        InputProps={{
                            style: {
                                height: '48px',
                            }
                        }}
                        sx={{ marginRight: 3 }} 
                    />
                    <IconButton
                        type="submit"
                        sx={{
                            backgroundColor: theme.palette.achord_purple.main,
                            color: '#fff',
                            width: '48px',
                            height: '48px',
                            borderRadius: '12px'
                        }}
                    >
                        <SendIcon />
                    </IconButton>
                </form>
            </Box>
        </Box>
    );
};

export default CurrentConversationInterface;
