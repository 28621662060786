import React, { createContext, useState, useEffect } from 'react';
import { getAuth, collection, getDocs, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, getFirestore, setDoc, doc, getDoc, updateDoc, updateEmail, EmailAuthProvider, reauthenticateWithCredential } from "../services/firebase";


export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  function generateTeacherCode() {
    let code = '';
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    for (let i = 0; i < 6; i++) {
      code += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return code;
  }

  const [currentUser, setCurrentUser] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const auth = getAuth();
  const db = getFirestore();
  const [usersStudents, setUsersStudents] = useState([]);
  const [usersSavedTeachers, setUsersSavedTeachers] = useState([]);
  const [profileLoading, setLoading] = useState(true);
  const [currentProfileViewingId, setCurrentProfileViewingId] = useState(null);


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
      if (user) {
        const docRef = doc(db, 'users', user.uid);
        getDoc(docRef).then(async docSnapshot => {
          if (docSnapshot.exists) {
            setLoading(true);

            const userProfileData = docSnapshot.data();
            setUserProfile(userProfileData);

            // Clear usersStudents and usersSavedTeachers
            setUsersStudents([]);
            setUsersSavedTeachers([]);

            if (userProfileData.userType === 'teacher' && userProfileData.students) {
              const studentPromises = userProfileData.students.map(student => {
                const studentRef = doc(db, 'users', student.id);
                return getDoc(studentRef);
              });

              const studentDocs = await Promise.all(studentPromises);

              const studentProfiles = studentDocs.map(studentDoc => ({ id: studentDoc.id, ...studentDoc.data() }));
              setUsersStudents(studentProfiles);
              setLoading(false);

            } else if (userProfileData.userType === 'student' && userProfileData.savedTeachers) {
              const teacherPromises = userProfileData.savedTeachers.map(teacher => {
                const teacherRef = doc(db, 'users', teacher.id);
                return getDoc(teacherRef);
              });

              const teacherDocs = await Promise.all(teacherPromises);

              const teacherProfiles = teacherDocs.map(teacherDoc => ({ id: teacherDoc.id, ...teacherDoc.data() }));
              setUsersSavedTeachers(teacherProfiles);
              setLoading(false);
            }
          } else {
            console.error("User document doesn't exist.");
          }
        });
      } else {
        setUserProfile(null);  // ensure userProfile is set to null when no user is signed in
      }
    });
    return unsubscribe;
  }, [auth, db]);



  const logoutUser = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error signing out", error);
    }
  };

  const updateEmailFrontEnd = async (updatedEmail, password) => {

    const credential = EmailAuthProvider.credential(
      auth.currentUser.email,
      password
    )
  
    return reauthenticateWithCredential(auth.currentUser, credential).then(() => {
      // User re-authenticated.
      return updateEmail(auth.currentUser, updatedEmail).then(() => {
        console.log('Email Updated!');
        return 1;
      }).catch((error) => {
        console.log(error);
        return 0;
      });
    }).catch((error) => {
      console.log('Error with reauthenticate user', error);
      return 0;
    });
  }

  const updateUserProfile = async (updatedUserProfile) => {
    try {
      // Reference to the user's document
      const userDocRef = doc(db, 'users', currentUser.uid);

      // Update the user's profile in Firestore
      await updateDoc(userDocRef, updatedUserProfile);

      // Update local state as well
      setUserProfile(updatedUserProfile);

      // Reference to the conversations collection
      const convosRef = collection(db, 'conversations');

      // Get all conversations
      const convoSnapshot = await getDocs(convosRef);

      // Go through all the documents in the conversations collection
      convoSnapshot.forEach(async (convoDoc) => {
        const convo = convoDoc.data();

        // Check if the current user is part of the conversation
        if ((userProfile.userType === 'student' && convo.studentId === currentUser.uid) ||
          (userProfile.userType === 'teacher' && convo.teacherId === currentUser.uid)) {

          // Reference to the conversation document
          const convoDocRef = doc(db, 'conversations', convoDoc.id);

          // Update the name in the conversation
          if (userProfile.userType === 'student') {
            await updateDoc(convoDocRef, { studentName: updatedUserProfile.fullName });
          } else {
            await updateDoc(convoDocRef, { teacherName: updatedUserProfile.fullName });
          }
        }
      });
    } catch (error) {
      throw new Error("Error updating user profile", error);
    }
  }


  async function signupUser(email, password, fullName, userType, isParentManaged) {
    try {
      // Create user with Firebase Auth
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Access Firestore instance
      const db = getFirestore();

      // Create user document in Firestore
      const docRef = doc(db, 'users', user.uid);

      // Additional properties for teacher
      let teacherCode = null;
      let students = null;
      let scheduling = null;
      teacherCode = generateTeacherCode();
      students = [];
      scheduling = {
        availabilities: {
          regulars: {
            Monday: [],
            Tuesday: [],
            Wednesday: [],
            Thursday: [],
            Friday: [],
            Saturday: [],
            Sunday: [],
          },
          irregulars: []
        },
        packages: []
      };
      // Set document data
      await setDoc(docRef, {
        fullName: fullName,
        userType: userType,
        isParentManaged: isParentManaged,
        email: email,
        teacherCode: teacherCode,
        students: students,
        scheduling: scheduling,
        unlisted: false
      });
      

      return true; // Return true if signup is successful
    } catch (error) {
      throw new Error("Error signing up user, please reenter your information and try again.");
    }
  }


  const loginUser = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      return true; // Return true on successful login
    } catch (error) {
      throw new Error("Incorrect email or password"); // Throw an error when login fails
    }
  };

  const updateLocalSavedTeachers = (newTeachersList) => {
    // Only proceed if there's a user profile in context
    if (userProfile) {
      setUserProfile(prevProfile => ({
        ...prevProfile,
        savedTeachers: newTeachersList
      }));
    }
  };


  return (
    <AuthContext.Provider
      value={{
        currentUser,
        userProfile,
        usersStudents,
        usersSavedTeachers,
        profileLoading,
        currentProfileViewingId,
        setCurrentProfileViewingId,
        signupUser,
        loginUser,
        logoutUser,
        updateUserProfile,
        updateLocalSavedTeachers,
        updateEmailFrontEnd
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;