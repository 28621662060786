import React, { useContext } from 'react';
import { Button, Box, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'; 
import { AuthContext } from '../../contexts/AuthContext';



const theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
  },
  palette: {
    primary: {
      main: '#6C5DD3',
    },
  },
});

function DisplayTeacherCode() {
  const navigate = useNavigate();
  const { userProfile } = useContext(AuthContext); // move useContext here

  

  return (
    <ThemeProvider theme={theme}>
      <Box 
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
        className="login-container"
        sx={{ marginBottom: '100px' }}
      >
        <Box 
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          className="form-container"
        >
          <Box           
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{width: '70%'}}
          >
            <Typography variant="h4" className="login-heading" gutterBottom>Your 6 Digit Teacher Code: {userProfile.teacherCode} </Typography>
            <Typography gutterBottom>Give this code to your students so they can message and book with you. You can find this code again in your profile page.</Typography>
          </Box>
          <Box position="absolute" bottom={20} right={20}>
            <Button variant="contained" className="skip-button" onClick={() => navigate('/scheduling')}>Continue</Button>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default DisplayTeacherCode;
