import React, { useEffect, useState, useContext } from "react";
import { Box, Typography, Button, Dialog, DialogTitle, DialogContent, List, ListItem, ListItemText, CircularProgress, ThemeProvider } from "@mui/material";
import ConversationCard from "./ConversationCard";
import { getFirestore, collection, getDocs, addDoc, serverTimestamp, query, where, updateDoc, doc, getDoc } from "firebase/firestore";
import { AuthContext } from '../../contexts/AuthContext'; 
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import theme from "../theme";


const ConversationList = ({ setSelectedConversation, trigger }) => {
  const [conversations, setConversations] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { currentUser, userProfile, usersStudents, usersSavedTeachers, profileLoading } = useContext(AuthContext);

  useEffect(() => {
    if(currentUser && userProfile && !profileLoading){
      fetchConversations();
    }
  }, [currentUser, userProfile, profileLoading, trigger]);


  const handleConversationSelect = async (conversation) => {
    const db = getFirestore();
  
    let resetUnread;
    if (currentUser.uid === conversation.studentId) {
      resetUnread = { studentUnreadMessages: 0 };
    } else {
      resetUnread = { teacherUnreadMessages: 0 };
    }

    await updateDoc(doc(db, "conversations", conversation.id), resetUnread);
    setSelectedConversation({
      ...conversation,
      otherPartyProfilePic: conversation.otherPartyProfilePic,
    });
    fetchConversations()
  };
  

  const conversationExists = (user) => {
    const userId = user.id;
    return conversations.some(conversation => 
      conversation.studentId === userId || conversation.teacherId === userId
    );
  };
  

  const fetchConversations = async () => {
    if (!currentUser) {
      return;
    }
    console.log(usersStudents)
    console.log("students")

    const db = getFirestore();
    const studentQuerySnapshot = await getDocs(query(collection(db, "conversations"), where("studentId", "==", currentUser.uid)));
    const teacherQuerySnapshot = await getDocs(query(collection(db, "conversations"), where("teacherId", "==", currentUser.uid)));
  
    // Combine results from both queries
    const querySnapshots = [...studentQuerySnapshot.docs, ...teacherQuerySnapshot.docs];
  
    let conversationsWithProfilePics = [];
  
    for (let convoDoc of querySnapshots) {
      let data = convoDoc.data();
  
      // Determine the other party's userId
      const otherPartyId = currentUser.uid === data.studentId ? data.teacherId : data.studentId;
  
      // Fetch the profile from usersStudents or usersSavedTeachers
      let otherPartyProfile;
      if (userProfile.userType === 'student') {
        otherPartyProfile = usersSavedTeachers.find(teacher => teacher.id === otherPartyId);
      } else {
        otherPartyProfile = usersStudents.find(student => student.id === otherPartyId);
      }
  
      // Check if we found the other party's profile
      if (!otherPartyProfile) {
        console.warn(`Profile not found for userId ${otherPartyId}`);
        continue; // Skip this conversation
      }
      console.log(otherPartyProfile)
      console.log(otherPartyProfile.pictureUrl)
      console.log("profile pic")

      let conversation = {
        id: convoDoc.id,
        ...data,
        lastMessageTimestamp: data.lastMessageTimestamp ? data.lastMessageTimestamp.toDate().toString() : "No date",
        // Store the profile picture of the other party in the conversation object
        otherPartyProfilePic: otherPartyProfile.pictureURL,
      };
      
      conversationsWithProfilePics.push(conversation);
    }
  
    setConversations(conversationsWithProfilePics);
  };
  
  


  

  const createConversation = async (user) => {
      if (userProfile?.userType) {
        const db = getFirestore();
        const studentId = userProfile.userType === 'student' ? currentUser.uid : user.id;
        const teacherId = userProfile.userType === 'teacher' ? currentUser.uid : user.id;
        const studentName = userProfile.userType === 'student' ? userProfile.fullName : user.fullName;
        const teacherName = userProfile.userType === 'teacher' ? userProfile.fullName : user.fullName;
    
        await addDoc(collection(db, "conversations"), {
          studentId: studentId,
          teacherId: teacherId,
          studentName: studentName,
          teacherName: teacherName,
          latestMessage: "New Conversation",  // default value, can be updated when a new message is sent
          lastMessageTimestamp: serverTimestamp(),
          teacherUnreadMessages: 0,
          studentUnreadMessages: 0,  
          messages: [],
        });
    
        fetchConversations();
      }
    };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  if (!currentUser || !userProfile) {
    return <CircularProgress />;
  }

  return (


    <Box>
      <Box sx={{ mb: 1 }}>
        <Box sx={{ pt: 0, pb: 1, borderBottom: 1, borderColor: "achord_purple", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Typography variant="h6" sx={{ fontWeight: 'semibold', fontFamily: "poppins", fontSize: 20 }}>
            Direct Messages
          </Typography>
          <ThemeProvider theme={theme}>
            <Button  
              sx={{px: '20px', py: '10px', borderRadius: '15px'}} 
              variant="contained"  
              color="achord_purple" 
              endIcon={<ControlPointIcon/>}
              onClick={handleOpenDialog}
            > 
              <Box sx={{ fontWeight: 'bold'}}>New Conversation</Box>
            </Button>
          </ThemeProvider>
        </Box>
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>Choose a {userProfile.userType === 'student' ? 'teacher' : 'student'} to start a conversation</DialogTitle>
          <DialogContent>
              <List>
              {(userProfile.userType === 'student' ? (usersSavedTeachers || []) : (usersStudents|| [])).filter(user => !conversationExists(user)).map((user, index) => (
                  <ListItem button key={index} onClick={() => { createConversation(user); handleCloseDialog(); }}>
                      <ListItemText primary={user.fullName} />
                  </ListItem>
              ))}
              </List>
            </DialogContent>
          </Dialog>
      </Box>
      {conversations.map((conversation, index) => (
          <Box sx={{ mb: 2 }}> {/* Here is where you could add margin or padding to the right if the cards are cut off */}
            <ConversationCard 
                key={index} 
                onSelect={handleConversationSelect}  // pass the callback function
                conversation={conversation}  // pass the whole conversation object
                name={userProfile.userType === 'student' ? conversation.teacherName : conversation.studentName} 
                profilePic={conversation.otherPartyProfilePic}
                lastMessage={conversation.latestMessage}
                timestamp={conversation.lastMessageTimestamp}  // converting date to string
                unreadMessages={userProfile.userType === 'student' ? conversation.studentUnreadMessages : conversation.teacherUnreadMessages}
            />
          </Box>
      ))}
    </Box>
  );
  
};

export default ConversationList;