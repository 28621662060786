import React, { useContext, useState, useEffect } from 'react';
import { AppBar, Toolbar, Button, IconButton, Box, Menu, MenuItem, Tabs, Tab, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import logo from '../logos_images/logo.png';
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth, deleteUser, sendPasswordResetEmail, getFirestore, doc, deleteDoc } from "../services/firebase";
import SearchBox from '../components/SearchBox';

function NavBar() {
  const { currentUser, logoutUser, userProfile } = useContext(AuthContext);
  const isUserLoggedIn = Boolean(currentUser);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');
  const [actionType, setActionType] = useState('');
  const db = getFirestore();

  useEffect(() => {
    if (location.pathname.includes("scheduling")) {
      setSelectedTab('scheduling');
    } else if (location.pathname.includes("messaging")) {
      setSelectedTab('messaging');
    } else if (location.pathname.includes("analytics")) {
      setSelectedTab('analytics');
    } else if (location.pathname.includes("explore")) {
      setSelectedTab('explore');
    } else{
      setSelectedTab(null);
    }
  }, [location]);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    if (newValue === 'scheduling') {
      navigate('/scheduling');
    } else if (newValue === 'messaging') {
      navigate('/messaging');
    } else if (newValue === 'analytics'){
      navigate('/analytics');
    } else if (newValue === 'explore'){
      navigate('/explore');
    }
  };

  const handleLogout = async () => {
    try {
      await logoutUser();
      handleProfileMenuClose();
      setSelectedTab(null);
      navigate("/"); // navigate to landing page
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteProfile = () => {
    setDialogTitle('Delete Profile');
    setDialogContent('Are you sure you want to delete your profile? This action cannot be undone.');
    setActionType('deleteProfile');
    setOpenDialog(true);
  }

  const handlePasswordReset = () => {
    setDialogTitle('Password Reset');
    setDialogContent('Are you sure you want to reset your password?');
    setActionType('resetPassword');
    setOpenDialog(true);
  }

  const handleDialogClose = () => {
    setOpenDialog(false);
  }

  const handleDialogConfirm = async () => {
    handleDialogClose();
    if (actionType === 'deleteProfile') {
      try {
        // Delete user document from Firestore
        const docRef = doc(db, 'users', currentUser.uid);
        await deleteDoc(docRef);

        // Delete user from Firebase Auth and log out
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
          await logoutUser();
          await deleteUser(user);
          setSelectedTab(null);
          handleProfileMenuClose();
          navigate("/"); // navigate to landing page
        }
      } catch (error) {
        console.error("Error deleting user", error);
      }
    } else if (actionType === 'resetPassword') {
      try {
        const auth = getAuth();
        await sendPasswordResetEmail(auth, currentUser.email);
      } catch (error) {
        console.error("Error sending password reset email", error);
      }
    }
  }


  const handleNavigateLogin = () => {
    handleProfileMenuClose();
    navigate("/login");
  };

  const handleNavigateProfile = () => {
    handleProfileMenuClose();
    navigate("/myProfile");
  };

  const handleNavigateSignup = () => {
    handleProfileMenuClose();
    navigate("/signup");
  };

  return (
    <AppBar position="relative" sx={{ backgroundColor: '#ffffff', color: '#000000', pr: 2 }}>
      <Toolbar sx={{ position: 'relative', height: '50px' }}>
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
          <Button onClick={() => navigate("/")}>
             <img src={logo} alt="logo" style={{ height: '60px' }} />
          </Button>
        </Box>
        {isUserLoggedIn ? 
            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
              <Tabs 
                value={isUserLoggedIn ? selectedTab : false} 
                onChange={handleTabChange} 
                indicatorColor="transparent" 
                textColor='#6C5DD3'
              >
              {userProfile?.userType === 'teacher' && (
              <Tab 
                label="Analytics" 
                value="analytics" 
                sx={{ 
                  fontSize: 20, 
                  fontWeight: 'bold', 
                  fontFamily: 'Poppins', 
                  color: selectedTab === 'analytics' ? '#6C5DD3' : '#000000', 
                  borderBottom: selectedTab === 'analytics' ? '2px solid #C4C4FF' : 'none', 
                  textTransform: 'none', 
                  paddingBottom: 0.5,
                  marginBottom: 1,
                }} 
              />
              )}
              {userProfile?.userType === 'student' && (
              <Tab 
                label="Explore" 
                value="explore" 
                sx={{ 
                  fontSize: 20, 
                  fontWeight: 'bold', 
                  fontFamily: 'Poppins', 
                  color: selectedTab === 'explore' ? '#6C5DD3' : '#000000', 
                  borderBottom: selectedTab === 'explore' ? '2px solid #C4C4FF' : 'none', 
                  textTransform: 'none', 
                  paddingBottom: 0.5,
                  marginBottom: 1,
                }} 
              />
              )}    
              <Tab 
                label="Scheduling" 
                value="scheduling" 
                sx={{ 
                  fontSize: 20, 
                  fontWeight: 'bold', 
                  fontFamily: 'Poppins', 
                  color: selectedTab === 'scheduling' ? '#6C5DD3' : '#000000', 
                  borderBottom: selectedTab === 'scheduling' ? '2px solid #C4C4FF' : 'none', 
                  textTransform: 'none', 
                  paddingBottom: 0.5,
                  marginBottom: 1,
                  marginLeft: 6
                }} 
              />
              <Tab 
                label="Messaging" 
                value="messaging" 
                sx={{ 
                  fontSize: 20, 
                  fontWeight: 'bold', 
                  fontFamily: 'Poppins', 
                  color: selectedTab === 'messaging' ? '#6C5DD3' : '#000000', 
                  borderBottom: selectedTab === 'messaging' ? '2px solid #C4C4FF' : 'none', 
                  textTransform: 'none', 
                  paddingBottom: 0.5,
                  marginBottom: 1,
                  marginLeft: 6
                }} 
              />
              </Tabs>
          </Box>
          :
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
            <SearchBox /> 
          </Box>
        }
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end', alignItems: "center" }}>
          <IconButton  aria-label="show more" aria-haspopup="true" onClick={handleProfileMenuOpen} color="inherit">
            <AccountCircle fontSize='30'/>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleProfileMenuClose}
          >
            {isUserLoggedIn ? 
              [
                <MenuItem onClick={handleLogout} key="logout">Logout</MenuItem>,
                <MenuItem onClick={handleNavigateProfile} key="profile">Profile</MenuItem>,
                <MenuItem onClick={handleDeleteProfile} key="delete">Delete Profile</MenuItem>,
                <MenuItem onClick={handlePasswordReset} key="reset">Send Password Reset</MenuItem>
              ]
            : 
              [
                <MenuItem onClick={handleNavigateLogin} key="login">Login</MenuItem>,
                <MenuItem onClick={handleNavigateSignup} key="signup">Sign Up</MenuItem>
              ]
            }
          </Menu>
        </Box>
        <Dialog
          open={openDialog}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogContent}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDialogConfirm} color="primary" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
