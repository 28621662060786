import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import LandingImage from '../../logos_images/Banner.png';
import { useNavigate } from 'react-router-dom'; 
import { ExploreContext } from '../../contexts/ExploreContext';
import LandingTeacherCard from '../../components/landing_components/LandingTeacherCards'; 
import CreateIcon from '@mui/icons-material/Create';
import LandingPageImage from '../../logos_images/LandingPageImage.jpg';

function Landing() {
    const navigate = useNavigate();
    const { teachers, fetchTeachers } = useContext(ExploreContext);
    const [displayTeachers, setDisplayTeachers] = useState([]);

    useEffect(() => {
        if (teachers.length === 0) {
            fetchTeachers().then(() => {
                let shuffledTeachers = teachers.sort(() => 0.5 - Math.random());
                let selectedTeachers = shuffledTeachers.slice(0, 6);
                setDisplayTeachers(selectedTeachers);
            });
        } else {
            let shuffledTeachers = teachers.sort(() => 0.5 - Math.random());
            let selectedTeachers = shuffledTeachers.slice(0, 6);
            setDisplayTeachers(selectedTeachers);
        }
    }, [teachers]);

    const handleCreateProfile = () => {
        navigate('/signup') 
    }

    return (
        <Grid container>
          <Grid item xs={12} style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            backgroundImage: `url(${LandingImage})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '50vh'
          }}>
            <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <Typography sx={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 24, color: "white", textAlign: "center"}}> Find the Perfect Music Teacher</Typography>
                <Button
                sx={{ px: '20px', py: '10px', borderRadius: '15px', backgroundColor: '#6C5DD3' }} 
                variant="contained"
                onClick={handleCreateProfile}
                >
                    <Box sx={{ fontWeight: 'bold' }}>Take the Quiz</Box>
                </Button>        
            </Box>
          </Grid>
          <Grid container sx={{ px: '80px', py: 6 }}>
              <Grid item xs={6}>
                  <Typography sx={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 30 }}>Create a Genuine Connection</Typography>
                  <Typography sx={{ fontFamily: 'Poppins', fontSize: 24 }}>You can use ACHORD to find your perfect music teacher. Click the search bar above, or a teacher profile below to get started now!</Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'right' }}>
                  <Typography sx={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 30 }}>Already Have a Teacher?</Typography>
                  <Button 
                    sx={{ px: '20px', py: '10px', borderRadius: '15px', mt: 2, backgroundColor: "#6C5DD3"}} 
                    variant="contained"  
                    onClick={() => navigate("/signup")}
                    endIcon={<CreateIcon/>}
                    >
                    <Box sx={{ fontWeight: 'bold'}}>Create Profile</Box>
                    </Button>             
                </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ px: '80px', py: 6 }}>
              {displayTeachers.map((teacher, index) => (
                <Grid key={index} item xs={4}>
                  <LandingTeacherCard teacher={teacher} />
                </Grid>
              ))}
          </Grid>
            <Box sx={{px: '80px', py: 5, mb: 5}}>
            <Grid container spacing={2}>
            <Grid item xs={4} container direction="column" justifyContent="center" alignItems="flex-start">
                <Typography variant="h4" sx={{ fontWeight: 'bold', fontFamily: "poppins", fontSize: 30, color: "black" }}>
                Teach With ACHORD
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'semibold', fontFamily: "poppins", fontSize: 20, color: "black", mt: 1.5 }}>
                ACHORD is more than just an initial connection. Teachers can use ACHORD to manage their small business and focus on what truly matters, sustaining the connection they have with their students.
                </Typography>
                <Button 
                sx={{ px: '20px', py: '10px', borderRadius: '15px', mt: 4, backgroundColor: "#6C5DD3"}} 
                variant="contained"  
                onClick={handleCreateProfile}
                endIcon={<CreateIcon/>}
                >
                <Box sx={{ fontWeight: 'bold'}}>Create Profile</Box>
                </Button>
            </Grid>
            <Grid item xs={8} container justify="center">
                <img src={LandingPageImage} alt="Sign in" style={{maxWidth: "100%"}} />
            </Grid>
            </Grid>
        </Box>
        </Grid>
    );
}

export default Landing;
