import React, { useState, useContext } from 'react';
import { TextField, Button, Box, Typography, Grid, Link } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AuthContext } from '../../../contexts/AuthContext';
import SignInImage from '../../../logos_images/SignInImage.jpg'
import { useNavigate } from 'react-router-dom'; 

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
  },
  palette: {
    primary: {
      main: '#6C5DD3',
    },
  },
});

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const { loginUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const success = await loginUser(email, password);
      if (success) {
        navigate('/scheduling');
      }
    } catch (error) {
      setError('Incorrect email or password. Please try again.');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container>
        <Grid item xs={12} md={6} sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          backgroundImage: `url(${SignInImage})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}>
          {/* Empty Grid item to hold image */}
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', p: 3, height: '100vh' }}>
            <Typography variant="h4" sx={{ textAlign: 'center', width: '100%', mb: 3 }}>Log In</Typography>
            {error && (
              <Box sx={{ marginBottom: '1rem' }}>
                <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{error}</Typography>
              </Box>
            )}
            <form onSubmit={handleSubmit} sx={{ width: '100%' }}>
              <TextField
                label="Email"
                variant="outlined"
                onChange={e => setEmail(e.target.value)}
                margin="normal"
                fullWidth
              />
              <TextField
                label="Password"
                type="password"
                variant="outlined"
                onChange={e => setPassword(e.target.value)}
                margin="normal"
                fullWidth
              />
              <Button variant="contained" type="submit" sx={{ mt: 1, width: '100%' }}>Log In</Button>
            </form>
            <Link
              component="button"
              variant="body2"
              underline="always"
              onClick={() => navigate("/signup")}
              sx={{ mt: 2 }}
            >
              Don't have a profile? Create one now!
            </Link>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default Login;
