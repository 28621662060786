import React from 'react';
import useStripePricingTable from '../../hooks/useStripePricingTable';

//Button for cancel
//See current subscription (intergraded stripe)
//upgrade students
//As soon as a student starts a conversation, or requests a booking with them (the teacher), they need to start paying. 9(redirected to subcrition)
//Free level for DiAnne.

function Subscription() {
  const stripePublishableKey = 'pk_live_51NcJR2ENi29LW6Ow0178iokWBXtzCgiCcwY4HTdw4EwdhZ86rst0JWyh4SseAr8heUzJB3DzNTSxJT6SeYvzybv300xxUKifaK'; 
  const pricingTableId = 'prctbl_1NdFrzENi29LW6OwpfqLKzU2'; 

  const ref = useStripePricingTable(stripePublishableKey, pricingTableId);

  return (
    <div>
      <h1>Subscription Page</h1>
      <div ref={ref}></div>
    </div>
  );
}

export default Subscription;
