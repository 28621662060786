import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardHeader, Avatar, CardContent, Typography, Box, CardActions, Button, ListItem, ListItemText, List, IconButton } from '@mui/material';
import { red, deepPurple } from '@mui/material/colors';
import { ExploreContext } from '../../contexts/ExploreContext';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { AuthContext } from '../../contexts/AuthContext';
import { arrayUnion, doc, updateDoc, getFirestore } from '../../services/firebase';


const ExploreTeacherCard = ({ teacher }) => {
    const navigate = useNavigate();
    const { setCurrentTeacherViewing } = useContext(ExploreContext);
    const { currentUser, userProfile, updateLocalSavedTeachers } = useContext(AuthContext);
    const db = getFirestore();

    const handleViewProfile = () => {
        const url = `${window.location.origin}/teacher-profile/${teacher.id}`;
        window.open(url, "_blank");
    };    

    const handleAddFavorite = async () => {
        if (!currentUser || !userProfile) {
            navigate('/signup');
            return;
        }

        try {
            const newTeacher = { id: teacher.id };
            const userDocRef = doc(db, 'users', currentUser.uid);
            await updateDoc(userDocRef, {
                savedTeachers: arrayUnion(newTeacher)
            });
    
            const teacherDocRef = doc(db, 'users', teacher.id);
            await updateDoc(teacherDocRef, {
                students: arrayUnion({ id: currentUser.uid })
            });
    
            // Update local saved teachers list
            const newSavedTeachers = [...userProfile.savedTeachers, newTeacher];
            updateLocalSavedTeachers(newSavedTeachers);
        } catch (error) {
            console.error("Error adding favorite", error);
        }
    };
    
    const isInSavedTeachersArray = (array, id) => {
        return !!array.find(item => item.id === id);
    }

    const isTeacherSaved = userProfile && userProfile.savedTeachers ? isInSavedTeachersArray(userProfile.savedTeachers, teacher.id) : false;

    const handleRemoveFavorite = async () => {
        if (!currentUser || !userProfile) {
            navigate('/signup');
            return;
        }

        try {
            const userDocRef = doc(db, 'users', currentUser.uid);
            const updatedSavedTeachers = userProfile.savedTeachers.filter(savedTeacher => savedTeacher.id !== teacher.id);
            await updateDoc(userDocRef, {
                savedTeachers: updatedSavedTeachers
            });
    
            // Removing student from teacher's student list
            const teacherDocRef = doc(db, 'users', teacher.id);
            const updatedStudents = teacher.students.filter(student => student.id !== currentUser.uid);
            await updateDoc(teacherDocRef, {
                students: updatedStudents
            });
    
            // Update local saved teachers list
            updateLocalSavedTeachers(updatedSavedTeachers);
        } catch (error) {
            console.error("Error removing favorite", error);
        }
    };

    const renderTeachingMode = (mode) => {
        switch(mode) {
        case 'Online':
            return 'Online';
        case 'In Person':
            return 'In Person';
        case 'Both/Either':
            return 'In Person | Online';
        default:
            return '';
        }
    };

    return (
        <Card sx={{ width: "400px", borderRadius: 3, border: 1, borderColor: deepPurple[200], boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', height: "400px", py: 1, px: 1}}>
            <CardHeader 
                sx={{p: 1}}
                avatar={
                    <Avatar sx={{ bgcolor: red[500], border: 2, borderColor: deepPurple[200], width: 60, height: 60 }} src={teacher.pictureURL} />
                }
                action={
                    isTeacherSaved ? (
                        <IconButton aria-label="settings" onClick={handleRemoveFavorite}>
                            <FavoriteIcon sx={{ color: '#6C5DD3' }} />
                        </IconButton>
                    ) : (
                        <IconButton aria-label="settings" onClick={handleAddFavorite}>
                            <FavoriteBorderIcon />
                        </IconButton>
                    )
                }
                title= {
                    <Typography sx={{fontWeight: '600', fontFamily: "poppins", fontSize: 18}}>
                        {teacher.fullName}
                    </Typography>}
            
            subheader = {
                <List disablePadding>
                    <ListItem disablePadding>
                        <ListItemText>
                            <Typography variant="body2" color={deepPurple[200]} sx={{fontWeight: '500', fontFamily: "poppins", fontSize: 14}}>
                            {renderTeachingMode(teacher.onboardingData.teachingMode)}
                            </Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemText>
                            <Typography variant="body2" color="text.secondary" sx={{fontWeight: '500', fontFamily: "poppins", fontSize: 14}}>
                            {teacher.onboardingData.teachingYears} years experience
                            </Typography>
                        </ListItemText>
                    </ListItem>
                </List>
                }
            />
        
        <CardContent 
            sx={{p:1}}
        >
            <Box sx={{fontWeight: '600', fontFamily: "poppins", fontSize: 16}}>Who am I?</Box>
            <Typography variant="body2" sx={{fontWeight: '400', fontFamily: "poppins", fontSize: 16}}>
                {teacher.onboardingData.teacherDescription}
            </Typography>
            <Box sx={{fontWeight: '600', fontFamily: "poppins", fontSize: 16}}>I teach:</Box>
            <Typography variant="body2" sx={{fontWeight: '400', fontFamily: "poppins", fontSize: 16}}>
                {teacher.onboardingData.teachingDescription}
            </Typography>
            <Box sx={{fontWeight: '600', fontFamily: "poppins", fontSize: 16}}>Words to Describe Me</Box>
            <Typography variant="body2" sx={{fontWeight: '400', fontFamily: "poppins", fontSize: 16}}>
                {teacher.onboardingData.teacherTraits.join(", ")}
            </Typography>
        </CardContent>
        <CardActions disableSpacing sx={{ justifyContent: 'center' }}>
            <Button
            sx={{ px: '20px', py: '10px', borderRadius: '15px', backgroundColor: '#6C5DD3' }} 
            variant="contained"
            onClick={handleViewProfile}
            >
            <Box sx={{ fontWeight: 'bold' }}>View Profile</Box>
            </Button>
        </CardActions>    
        </Card>
    );
};

export default ExploreTeacherCard;
