import React, { useState } from 'react';
import { Button, Box, Typography, Grid, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SignInImage from '../../../logos_images/SignInImage.jpg'
import { useNavigate } from 'react-router-dom'; 

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
  },
  palette: {
    primary: {
      main: '#6C5DD3',
    },
  },
});

function OnboardingStudent1() {

  const navigate = useNavigate();
  const [value, setValue] = useState('findTeacher');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleContinue = () => {
    switch(value){
      case 'findTeacher':
        navigate("/onboardingStudent2");
        break;
      case 'haveTeacher':
        navigate("/enterTeacherCode");
        break;
      default:
        break;
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container>
        <Grid item xs={12} md={6} sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          backgroundImage: `url(${SignInImage})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', p: 3, height: '100vh' }}>
            <Typography variant="h4" sx={{ textAlign: 'center', width: '100%', mb: 3 }}>Which of the below options best describes your needs?</Typography>
            <RadioGroup
              aria-label="student needs"
              value={value}
              name="radio-buttons-group"
              onChange={handleChange}
            >
              <FormControlLabel value="findTeacher" control={<Radio />} label="I'm looking for a music teacher" />
              <FormControlLabel value="haveTeacher" control={<Radio />} label="I already have a teacher" />
            </RadioGroup>
            <Button 
              sx={{ px: '20px', py: '10px', borderRadius: '15px', mt: 2 }} 
              variant="contained"  
              onClick={handleContinue}
            >
              Continue
            </Button>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default OnboardingStudent1;
