import React from 'react';
import logo from '../logos_images/logo.png';
import { Typography } from '@mui/material';

const rootStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '2rem',
};

const logoStyle = {
  width: '100px', // Or any other size you want
  height: 'auto', // Maintain aspect ratio
};

const textStyle = {
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 'bold',
  fontSize: '20px',
  textAlign: 'center',
  marginTop: '1rem',
  padding: '0 1rem', // Give some padding on the sides for small screens
};

const MobileView = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    // Cleanup function to remove the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  // Assuming 768px as a breakpoint for mobile devices
  return width <= 768 ? (
    <div style={rootStyle}>
      <img src={logo} alt="Logo" style={logoStyle} />
      <Typography style={textStyle}>
        Welcome to ACHORD! Our web App is currently optimized for web and will be for mobile soon. To use ACHORD,
        please visit our site on a laptop or desktop.
      </Typography>
    </div>
  ) : (
    children
  );
};

export default MobileView;
