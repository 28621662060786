import React, { useState, useContext, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, TextField, Box, Grid, Alert, IconButton, Typography } from '@mui/material';
import { TimePicker, DatePicker } from '@mui/x-date-pickers';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { SchedulingContext } from '../../contexts/SchedulingContext';

const createTime = (timeString) => {
    if (timeString) {
        const [hours, minutes] = timeString.split(':');
        const date = new Date();
        date.setHours(parseInt(hours));
        date.setMinutes(parseInt(minutes));
        return date;
    } else {
        return null;
    }

}

const hasOverlap = (availability) => {
    for (let i = 0; i < availability.length; i++) {
        for (let j = i + 1; j < availability.length; j++) {
            if (!availability[i] || !availability[j]) {
                console.error(`availability[${i}] or availability[${j}] is undefined`);
                continue;
            }
            if (typeof availability[i] !== 'object' || typeof availability[j] !== 'object') {
                console.error(`availability[${i}] or availability[${j}] is not an object`);
                continue;
            }
            if (!('from' in availability[i]) || !('to' in availability[i]) ||
                !('from' in availability[j]) || !('to' in availability[j])) {
                console.error(`'from' or 'to' property is missing in availability[${i}] or availability[${j}]`);
                continue;
            }
            if (availability[i].from < availability[j].to && availability[i].to > availability[j].from) {
                return true;
            }
        }
    }
    return false;
};


const formatTime = (date) => {
    let hours = date.getHours().toString();
    let minutes = date.getMinutes().toString();

    if (hours.length < 2) {
        hours = '0' + hours;
    }

    if (minutes.length < 2) {
        minutes = '0' + minutes;
    }

    return `${hours}:${minutes}`;
};

const DayRow = ({ day, availability, setAvailability }) => {
    const timeSlots = availability.regulars[day] || [];

    const addTimeSlot = () => {
        setAvailability(prev => ({
            ...prev,
            regulars: {
                ...prev.regulars,
                [day]: [...timeSlots, { from: null, to: null }]
            }
        }));
    };

    const deleteTimeSlot = (index) => {
        setAvailability(prev => ({
            ...prev,
            regulars: {
                ...prev.regulars,
                [day]: timeSlots.filter((_, i) => i !== index)
            }
        }));
    };

    const validateTime = (index, type, newValue) => {
        setAvailability(prev => {
            let newSlots = [...timeSlots];
            newSlots[index][type] = newValue;

            if (newSlots[index].from && newSlots[index].to) {
                newSlots[index].error = newSlots[index].from >= newSlots[index].to;
            }

            return {
                ...prev,
                regulars: {
                    ...prev.regulars,
                    [day]: newSlots
                }
            };
        });
    };

    return (
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-start" my={2} sx={{borderBottom: '1px solid grey', py: 1}}>
            <Typography variant="h6" component="div" sx={{ fontWeight: '600', fontSize: 20, fontFamily: 'Poppins' }}>
                {day}
            </Typography>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
                {timeSlots.map((slot, index) => (
                    <Box key={index} display="flex" alignItems="center" mb={1}>
                        <TimePicker
                            value={createTime(slot.from)}
                            onChange={(newValue) => validateTime(index, 'from', formatTime(newValue))}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <Box mx={1}>-</Box>
                        <TimePicker
                            value={createTime(slot.to)}
                            onChange={(newValue) => validateTime(index, 'to', formatTime(newValue))}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        {slot.error && (
                            <Alert severity="error">End time must be later than start time.</Alert>
                        )}
                        <IconButton onClick={() => deleteTimeSlot(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                ))}
            </Box>
            <IconButton onClick={addTimeSlot}>
                <AddIcon />
            </IconButton>
        </Box>
    );
    
};



const IrregularitiesRow = ({ loadedDates, setAvailability }) => {
    const [selectedDates, setSelectedDates] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        setSelectedDates(loadedDates);
    }, []);

    // useEffect(() => {

    //     if (availability.irregulars) {
    //         const transformedIrregulars = availability.irregulars.map((irregular) => {
    //             if (irregular.from && toDate) {

    //                 const fromDate = irregular.from;
    //                 const toDate = irregular.to;

    //                 if (isNaN(fromDate.getTime()) || isNaN(toDate.getTime())) {
    //                     console.error(`Invalid date encountered: from(${irregular.from}), to(${irregular.to})`);
    //                     return null;
    //                 }

    //                 return {
    //                     from: fromDate,
    //                     to: toDate
    //                 };
    //             }
    //         }).filter(Boolean); // remove null entries due to invalid dates
    //     }
    // }, [availability]);

    const addDate = () => {
        setSelectedDates([...selectedDates, { date: null, slots: [] }]);
    };

    const handleDateChange = (date, index) => {
        let newDates = [...selectedDates];
        newDates[index].date = date;
        setSelectedDates(newDates);
    };

    // const addTimeSlot = (dateIndex) => {
    //     let newDates = [...selectedDates];
    //     newDates[dateIndex].slots.push({ from: null, to: null, error: false });
    //     setSelectedDates(newDates);
    // };

    const addTimeSlot = (dateIndex) => {
        // Check if there's already a slot with `null` values
        const hasNullSlot = selectedDates[dateIndex].slots.some(slot => slot.from === null || slot.to === null);

        if (hasNullSlot) {
            // Set error message if `null` slot exists
            setErrorMessage('Please fill out the current time slot before adding a new one.');
        } else {
            // Add new time slot if no `null` slots exist
            let newDates = [...selectedDates];
            newDates[dateIndex].slots.push({ from: null, to: null});
            setSelectedDates(newDates);

            // Clear error message if any
            setErrorMessage('');
        }
    };

    const deleteTimeSlot = (dateIndex, slotIndex) => {
        let newDates = [...selectedDates];
        newDates[dateIndex].slots = newDates[dateIndex].slots.filter((_, i) => i !== slotIndex);
        setSelectedDates(newDates);
    };

    const validateTime = (dateIndex, slotIndex, type, newValue) => {
        let newDates = [...selectedDates];
        let slot = newDates[dateIndex].slots[slotIndex];
        


        slot[type] = formatTime(newValue);
        if (slot.from && slot.to) {
            if (slot.from >= slot.to) {
                slot.error = true;
            } else {
                slot.error = false;
            }
        }
        setSelectedDates(newDates);
    };

    useEffect(() => {

        // const transformedDates = selectedDates.map(dateObj => {

        //     return dateObj.slots.map(slot => {
        //         if (slot.from !== null && slot.to !== null) {
        //             // Assuming slot.from and slot.to are already date objects
        //             if (isNaN(slot.from.getTime()) || isNaN(slot.to.getTime())) {
        //                 console.error(`Invalid dates: from(${slot.from}), to(${slot.to})`);
        //                 console.error(`Corresponding values: date(${dateObj.date}), fromTime(${slot.from}), toTime(${slot.to})`);
        //                 return null; // Here, if dates are not valid, return null
        //             }
        //             return {
        //                 from: slot.from,
        //                 to: slot.to
        //             };
        //         } else {
        //             return {
        //                 from: null,
        //                 to: null
        //             }
        //         }

        //     })
        // }).flat();

        setAvailability(prev => ({
            ...prev,
            irregulars: selectedDates
        }));

        console.log(selectedDates);

    }, [selectedDates]);



    return (
        <Grid container spacing={2} alignItems="center">
            {errorMessage && (
                <Grid item xs={12}>
                    <Alert severity="error">{errorMessage}</Alert>
                </Grid>
            )}
            <Box width="100%" display="flex" justifyContent="space-between" alignItems="center" sx={{px: 2, py: 2}}>
                <Typography variant="h6" component="div" sx={{ fontWeight: '300', fontSize: 16, fontFamily: 'Poppins'}}>
                    Add dates where your schedule is irregular
                </Typography>
                <Button
                    sx={{ px: '20px', py: '10px', borderRadius: '15px', backgroundColor: '#6C5DD3' }} 
                    variant="contained"
                    endIcon={<AddIcon />}
                    onClick={addDate}
                >
                    <Box sx={{ fontWeight: 'bold' }}>Add Date</Box>
                </Button>        
            </Box>
            {selectedDates.map((selectedDate, dateIndex) => (
                <Grid item xs={12} key={dateIndex}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <DatePicker
                            label="Select Date"
                            value={selectedDate.date}
                            onChange={(date) => handleDateChange(date, dateIndex)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <Box display="flex" justifyContent="flex-end" alignItems="center" ml={1}>
                            <AddIcon onClick={() => addTimeSlot(dateIndex)} />
                        </Box>
                    </Box>
                    {selectedDate.slots.map((slot, slotIndex) => (
                        <Box key={slotIndex} display="flex" alignItems="center">
                            <TimePicker
                                value={createTime(slot.from)}
                                onChange={(newValue) => validateTime(dateIndex, slotIndex, 'from', newValue)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <Box mx={1}>-</Box>
                            <TimePicker
                                value={createTime(slot.to)}
                                onChange={(newValue) => validateTime(dateIndex, slotIndex, 'to', newValue)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            {slot.error && (
                                <Alert severity="error">End time must be later than start time.</Alert>
                            )}
                            <DeleteIcon onClick={() => deleteTimeSlot(dateIndex, slotIndex)} />
                        </Box>
                    ))}
                </Grid>
            ))}
        </Grid>
    );    
};

// // This function transforms a backend irregular availability object into a form the frontend can use.
// const transformBackendIrregulars = (backendIrregulars) => {
//     return backendIrregulars.map(backendIrregular => ({
//         date: backendIrregular.from,
//         slots: [{
//             from: backendIrregular.from,
//             to: backendIrregular.to
//         }]

//     }));
// };


// // This function transforms a frontend irregular availability object into a form the backend can use.
// const transformFrontendIrregulars = (frontendIrregulars) => {
//     return frontendIrregulars.map(frontendIrregular => ({
//         from: frontendIrregular.from,
//         to: frontendIrregular.to
//     }));
// };

const AvailabilitiesDialog = ({ open, onClose }) => {
    const { loadAvailability, addAvailability } = useContext(SchedulingContext);
    const orderedDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']; //this is used to order the day rows when they load in.

    // These lines should be moved here
    //const [selectedDates, setSelectedDates] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const [availability, setAvailability] = useState({
        regulars: {
            Monday: [],
            Tuesday: [],
            Wednesday: [],
            Thursday: [],
            Friday: [],
            Saturday: [],
            Sunday: [],
        },
        irregulars: []
    });

    const fetchAvailability = async () => {
        const userAvailability = await loadAvailability();
        if (userAvailability) {
            setAvailability(userAvailability);
        }
    };


    useEffect(() => {
        fetchAvailability();
    }, [open, onClose]);

    const handleSaveChanges = async () => {
        console.log(availability);
    
        // Check if there's any slot with `null` values
        const hasNullSlot = Object.values(availability.regulars).some(day => day.some(slot => slot.from === null || slot.to === null)) 
                              || availability.irregulars.some(irregular => irregular.slots.some(slot => slot.from === null || slot.to === null));
    
        if (hasNullSlot) {
            // Set error message if `null` slot exists
            setErrorMessage('Please fill out all time slots before saving.');
        } else {
            // Clear error message if any
            setErrorMessage('');
    
            // Check overlap for each regular day separately
            for (let day in availability.regulars) {
                if (hasOverlap(availability.regulars[day])) {
                    alert(`Overlapping timeslots are not allowed on ${day}`);
                    return;
                }
            }
            
            // Check overlap for each irregular day separately
            for (let irregular of availability.irregulars) {
                if (hasOverlap(irregular.slots)) {
                    alert("Overlapping timeslots are not allowed in irregular availability");
                    return;
                }
            }
    
            let updatedAvailability = {
                regulars: availability.regulars,
                irregulars: availability.irregulars
            };
            await addAvailability(updatedAvailability);
            fetchAvailability();
            onClose(false);
        }
    };
    

    return (
        <Grid container spacing={2} alignItems="center">
                <Dialog 
                open={open} 
                onClose={() => onClose(false)}
                maxWidth="md" // 'md' is about 60% of the screen width
                fullWidth={true} // this will make dialog take the full width defined by 'maxWidth'
                >
                <DialogTitle>
                <Box sx={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid grey', py: 1 }}>
                    <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', fontSize: 24 }}>
                        My Availabilities
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={onClose}
                        sx={{ marginLeft: 'auto' }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>                
            <DialogContent>
                    {orderedDays.map(day => (
                        <DayRow
                            key={day}
                            day={day}
                            availability={availability}
                            setAvailability={setAvailability}
                        />
                    ))}
                    <Box mt={4} mb={2}>
                        <Typography variant="h6" component="div" sx={{ fontWeight: '600', fontSize: 20, fontFamily: 'Poppins' }}>
                            Set Irregular Availabilities
                        </Typography>                    
                    </Box>
                    <IrregularitiesRow
                        loadedDates={availability.irregulars}
                        setAvailability={setAvailability}
                    />
                    <Box display="flex" justifyContent="flex-end" mt={2}>
                        {errorMessage && (
                            <Grid item xs={12}>
                                <Alert severity="error">{errorMessage}</Alert>
                            </Grid>
                        )}
                        <Button
                            sx={{ px: '20px', py: '10px', borderRadius: '15px', backgroundColor: '#36C752' }} 
                            variant="contained"
                            endIcon={<SaveIcon />}
                            onClick={handleSaveChanges}
                        >
                            <Box sx={{ fontWeight: 'bold' }}>Save Changes</Box>
                        </Button>                    
                    </Box>
                </DialogContent>
            </Dialog>
        </Grid>
    );
};

export default AvailabilitiesDialog;