import React from 'react';
import { Container, Typography, Grid } from '@mui/material';
import MrsSompongImage from '../../logos_images/MrsSompong.jpeg';

const About = () => {
  return (
    <Container sx={{py: 7}}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Typography variant="h4" gutterBottom style={{fontFamily: 'Poppins', fontSize: 30, fontWeight: 'bold'}}>
            The Story Behind ACHORD
          </Typography>
          <Typography variant="body1" paragraph style={{fontFamily: 'Poppins', fontSize: 20}} sx={{mt: 4}}>
            We are devoted to connection, learning, and facilitating music. I'm Max, a co-founder, and this is the story behind ACHORD.
          </Typography>
          <Typography variant="body1" paragraph style={{fontFamily: 'Poppins', fontSize: 20}}>
            I've loved music for as long as I can remember, and central to the development of that passion was my relationship with my music teacher, Mrs. Sompong. Mrs. Sompong was the perfect music teacher for me. We met when I was in kindergarten, and I took piano lessons for over 10 years with her, and continued to visit her until her passing a few years ago.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <img src={MrsSompongImage} alt='Max and Mrs. Sompong' style={{width: '100%', height: 'auto'}}/>
        </Grid>
        <Grid item xs={12} sm={12}>
        <Typography variant="body1" paragraph style={{fontFamily: 'Poppins', fontSize: 20}}>
            Mrs. Sompong was one of the most important people in my life because she taught me so much more than how to play the piano, she taught me how to be truly passionate about something and gave me one of the greatest sources of joy in my life.
          </Typography>
          <Typography variant="body1" paragraph style={{fontFamily: 'Poppins', fontSize: 20}}>
            Fast-forward to 2 years ago, I met Robert, my now roommate and best-friend at Washington University in St. Louis. Robert also played the piano and loved music, however, he had a much different experience with his music teachers. Robert went through 5 teachers before giving up on music, and he rediscovered the passion years later. As our relationship and connection as friends grew deeper, we eventually came up with a realization, a realization that grew into an idea.
          </Typography>
          <Typography variant="body1" paragraph style={{fontFamily: 'Poppins', fontSize: 20}}>
            Music should be such a source of joy in one's life, but if one doesn't have a teacher who is a right fit for them, learning to play an instrument can become a chore and all passion can be lost. It was then we came up with the idea for ACHORD, a platform where music students could find a teacher who was a great fit for them, and form a genuine connection, like the one I had with Mrs. Sompong.
         </Typography>
         <Typography variant="body1" paragraph style={{fontFamily: 'Poppins', fontSize: 20}}>
            After working on our idea in two business classes and one computer science class at WashU, we learned a lot, won some prize money, and decided to get to work over the summer of 2023, to try and make ACHORD a reality. That journey has led us to the current state of ACHORD, the web app which this about page is currently displayed on.
         </Typography>
         <Typography variant="body1" paragraph style={{fontFamily: 'Poppins', fontSize: 20}}>
            Through our journey, we talked to dozens of students and teachers, and we found that not only are the methods of finding teachers outdated and unlikely to lead to a genuine connection, teachers also faced another problem that got in the way of forming connections. Music teachers love to teach and connect with their students, however, for private music teachers, they also have to act as small business people.
         </Typography>
         <Typography variant="body1" paragraph style={{fontFamily: 'Poppins', fontSize: 20}}>
            The struggles of managing scheduling/booking, payments, communicating with students, advertising themselves, business analytics and more are stressful, time-consuming, and get in the way of the stuff that makes teaching music fun and fulfilling. Through ACHORD, we hope to slowly and surely build a platform that can be a music teacher and students dream.
         </Typography>
         <Typography variant="body1" paragraph style={{fontFamily: 'Poppins', fontSize: 20}}>
            That's our story. That's why we are devoted to connection, learning, and striking ACHORD between music students and teachers.
         </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default About;
