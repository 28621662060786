import React from 'react';
import { Box, Typography } from '@mui/material';

const ProfileBackground = (dataProfile) => {

  let studentBackground = [];
  let teacherBackground = [];
  dataProfile = dataProfile.dataProfile

  if (dataProfile?.userType === 'student' && dataProfile?.onboardingData) {
    studentBackground = [
        { label: dataProfile.onboardingData.learnGoal ? 'In one sentence, what would you like to learn?' : '', value: dataProfile.onboardingData.learnGoal || '' },
        { label: dataProfile.onboardingData.experienceYears ? 'How many years have you been learning music or playing an instrument?' : '', value: dataProfile.onboardingData.experienceYears || '' },
        { label: dataProfile.onboardingData.musicExperience ? 'In your own words, describe your experience with learning music.' : '', value: dataProfile.onboardingData.musicExperience || '' },
        { label: dataProfile.onboardingData.musicChallenge ? 'What is your biggest challenge when it comes to learning music?' : '', value: dataProfile.onboardingData.musicChallenge || '' },
        { label: dataProfile.onboardingData.favoriteMusic ? 'What is your favorite song or type of music?' : '', value: dataProfile.onboardingData.favoriteMusic || '' }
    ];
  } else if (dataProfile?.userType === 'teacher' && dataProfile?.onboardingData) {
    teacherBackground = [
        { label: dataProfile.onboardingData.teachingDescription ? 'Describe what you teach in one sentence?' : '', value: dataProfile.onboardingData.teachingDescription || '' },
        { label: dataProfile.onboardingData.teachingYears ? 'How many years have you been teaching' : '', value: dataProfile.onboardingData.teachingYears || '' },
        { label: dataProfile.onboardingData.teachingExperience ? 'Describe your experience with teaching in your own words' : '', value: dataProfile.onboardingData.teachingExperience || '' },
        { label: dataProfile.onboardingData.handleStrugglingStudent ? 'How do you handle a student who is struggling to understand a concept or master a skill?' : '', value: dataProfile.onboardingData.handleStrugglingStudent || '' },
        { label: dataProfile.onboardingData.engagingLessons ? 'How do you keep your lessons engaging and fun?' : '', value: dataProfile.onboardingData.engagingLessons || '' }
    ];
  }

  const backgroundInfo = dataProfile.userType === 'student' ? studentBackground : teacherBackground;

  return (
    <Box
      sx={{
        border: '2px solid #C4C4FF',
        borderRadius: '12px',
        padding: '20px',
        margin: '10px 0',
        width: '100%',
      }}
    >
      <Typography 
        sx={{              
          fontFamily: 'Poppins',
          fontWeight: 'bold',
          fontSize: '24px',
          mb: 2
        }}
      >
        Musical Background And Interests
      </Typography>
      {backgroundInfo.map((info, index) => (
        info.label && info.value && (
          <Box key={index} sx={{ marginBottom: '10px' }}>
            <Typography 
              variant="h6"
              sx={{
                fontFamily: 'Poppins',
                fontWeight: 'semibold',
                fontSize: '22px',
              }}
            >
              {info.label}
            </Typography>
            <Typography 
              variant="body1"
              sx={{
                fontFamily: 'Poppins',
                fontSize: '20px',
                mb: 2
              }}
            >
              {info.value || ''}
            </Typography>
          </Box>
        )
      ))}
    </Box>
  );
};

export default ProfileBackground;
