import React, { useState, useContext } from 'react';
import { Avatar, IconButton, Snackbar, Alert, Button, Box, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthContext';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { storage, ref, uploadBytesResumable, getDownloadURL } from "../../../services/firebase";
import { readAndCompressImage } from 'browser-image-resizer';

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
  },
  palette: {
    primary: {
      main: '#6C5DD3',
    },
  },
});

const imageConfig = {
  quality: 0.7,
  maxWidth: 800,
  maxHeight: 800,
  autoRotate: true,
  debug: true,
};

function AddProfilePic() {
  const { userProfile, updateUserProfile, currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(userProfile ? userProfile.pictureURL : null);
  const [uploading, setUploading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    try {
      const resizedFile = await readAndCompressImage(file, imageConfig);
      resizedFile.name = file.name;
      setImage(resizedFile);
      setImagePreviewUrl(URL.createObjectURL(resizedFile));
    } catch (err) {
      console.log(err);
    }
  };

  const handleContinueOrSkip = async () => {
    if (imagePreviewUrl) {
      // Upload the image if one has been selected
      await handleUploadImage();
    }

    if (userProfile.userType === 'student') {
      navigate('/onboardingStudent1');
    } else {
      navigate('/onboardingTeacher1');
    }
  };

  const handleUploadImage = async () => {
    if (image) {
      setUploading(true);
      const uid = currentUser.uid;
      const storageRef = ref(storage, `profile-pictures/${uid}_${image.name}`);
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on('state_changed',
        (snapshot) => {
          // optional: add progress function here
        },
        (error) => {
          console.log(error);
          setError(true);
          setUploading(false);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            updateUserProfile({ ...userProfile, pictureURL: downloadURL });
            setUploading(false);
            setSuccess(true);
          });
        }
      );
    }
  };

  const handleCloseSnackbar = () => {
    setSuccess(false);
    setError(false);
  }

  return (
    <ThemeProvider theme={theme}>
      <Box 
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
        sx={{ marginBottom: '100px'}} 
      >
        <Box 
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{mt: 4, maxWidth: "60vw"}}
        >
          <Typography variant="h4" gutterBottom>Update Profile Picture</Typography>
  
          <Avatar src={imagePreviewUrl} sx={{ width: theme.spacing(20), height: theme.spacing(20), margin: theme.spacing(1) }} />
          <IconButton component="label" color="primary">
            <input
              accept="image/*"
              type="file"
              hidden
              onChange={handleImageChange}
            />
            <AddAPhotoIcon />
          </IconButton>

          <Button 
            onClick={handleContinueOrSkip} 
            variant="contained" 
            color="primary" 
            sx={{ mt: 2 }}
          >
            {imagePreviewUrl ? "Continue" : "Skip"}
          </Button>

          <Snackbar open={success} autoHideDuration={6000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity="success">
              Image uploaded successfully!
            </Alert>
          </Snackbar>
          <Snackbar open={error} autoHideDuration={6000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity="error">
              There was an error uploading your image.
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default AddProfilePic;
