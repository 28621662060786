import React from "react";
import { Box, Typography, Avatar, Tooltip, useTheme } from "@mui/material";
import truncate from 'lodash/truncate';

const ConversationCard = ({
  name,
  profilePic,
  lastMessage,
  timestamp,
  unreadMessages,
  onSelect,
  conversation,
}) => {
  const date = new Date(timestamp);
  const theme = useTheme();

  const optionsTime = { hour: "numeric", minute: "numeric" };
  const optionsDate = { month: "long", day: "numeric" };

  const formattedTime = date.toLocaleTimeString([], optionsTime);
  const formattedDate = date.toLocaleDateString([], optionsDate);

  const formattedTimestamp = `${formattedTime} ${formattedDate}`;

  return (
    <Box
      onClick={() => onSelect(conversation)}
      sx={{
        width: "90%",
        display: "flex",
        alignItems: "center",
        gap: 1,
        p: theme.spacing(1.5, 2),
        bgcolor: "#FFFFFF",
        borderRadius: 5,
        border: "1px solid #C4C4FF",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Avatar
        src={profilePic}
        sx={{
          width: 60,
          height: 60,
          borderRadius: 100,
          borderColor: "#C4C4FF",
          borderWidth: 2,
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: 153,
          p: theme.spacing(0.625, 0),
        }}
      >
        <Typography variant="body2" sx={{ fontWeight: "bold", fontFamily: "Poppins" }}>{name}</Typography>
        <Tooltip title={lastMessage}>
          <Typography variant="body2" sx={{ fontFamily: "Poppins", maxHeight: '3em', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', webkitLineClamp: '2', webkitBoxOrient: 'vertical' }}>{truncate(lastMessage, { 'length': 50, 'separator': /,? +/ })}</Typography>
        </Tooltip>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-end", // align right
          flex: 1,
          p: theme.spacing(0.625, 0),
        }}
      >
        <Typography variant="body2" noWrap sx={{ fontFamily: "Poppins", mb: theme.spacing(1) }}>{formattedTimestamp}</Typography>
        {unreadMessages > 0 && (
          <Box
            sx={{
              bgcolor: "#6C5DD3",
              borderRadius: "50%",
              color: "#FFFFFF",
              fontFamily: "Poppins",
              fontWeight: "bold",
              fontSize: 10,
              p: theme.spacing(0.5),
              width: theme.spacing(3),
              height: theme.spacing(3),
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
           {unreadMessages}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ConversationCard;