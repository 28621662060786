import React, { createContext, useState, useContext, useEffect } from 'react';
import { collection, getDocs, query, where } from "firebase/firestore";
import { getFirestore, doc, updateDoc } from "../services/firebase";
import { AuthContext } from './AuthContext';
import { ConstructionOutlined } from '@mui/icons-material';

export const ExploreContext = createContext();

export const ExploreProvider = ({ children }) => {
  const [onboardingData, setOnboardingData] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [currentTeacherViewing, setCurrentTeacherViewing] = useState(null); 
  const { currentUser } = useContext(AuthContext);
  const db = getFirestore();


  useEffect(() => {
    fetchTeachers();
  }, []);

  const fetchTeachers = async () => {
    try {
      const usersCollection = collection(db, 'users');
      const teachersQuery = query(usersCollection, 
        where('userType', '==', 'teacher'),
        where('onboardingData', '!=', null),
        where('unlisted', '==', false)
      );
      const teacherSnapshot = await getDocs(teachersQuery);
      
      let teacherData = [];
      teacherSnapshot.forEach(doc => {
          teacherData.push({id: doc.id, ...doc.data()});
      });
      console.log(teacherData)
      setTeachers(teacherData);
    } catch (error) {
      console.log("Error fetching teachers:", error);
    }
  };
  

  const updateOnboardingData = (data) => {
    return new Promise((resolve) => {
      setOnboardingData(prevData => {
        const updatedData = { ...prevData, ...data };
        resolve(updatedData);
        return updatedData;
      });
    });
  };
  
  const saveOnboardingData = async (data) => {
    if (!currentUser) {
      throw new Error('No user is currently logged in');
    }
  
    try {
      // Reference to the user's document
      const userDocRef = doc(db, 'users', currentUser.uid);
  
      // Update the user's explore data in Firestore
      await updateDoc(userDocRef, { onboardingData: data });
  
      // Clear the explore data in state
      setOnboardingData({});
    } catch (error) {
      throw new Error('Error saving explore data', error);
    }
  };
  

  return (
    <ExploreContext.Provider
      value={{
        teachers,
        onboardingData,
        currentTeacherViewing,
        updateOnboardingData,
        saveOnboardingData,
        fetchTeachers,
        setCurrentTeacherViewing
      }}
    >
      {children}
    </ExploreContext.Provider>
  );
};

export default ExploreProvider;


