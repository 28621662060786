import React from 'react';
import { InputBase, IconButton } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';


function SearchBox() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/explore');
  };

  return (
    <div
      style={{
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
        borderRadius: 20,
        border: '1px solid #E3E3E3',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Add the box shadow here
      }}
      onClick={handleClick}
    >
      <InputBase
        style={{
          marginLeft: 10,
          flex: 1,
          color: '#000000',  // Here is the text color change
        }}
        placeholder="Start Your Search"
        inputProps={{ 'aria-label': 'start your search' }}
        disabled
      />
      <IconButton type="submit" style={{ padding: 0, backgroundColor: '#6C5DD3', borderRadius: '50%', height: '32px', width: '32px' }} aria-label="search">
        <SearchIcon style={{ color: '#ffffff' }} />
      </IconButton>
    </div>
  );
}

export default SearchBox;
