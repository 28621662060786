import React from 'react';
import { Box, Typography } from '@mui/material';

const ProfilePersonality = (dataProfile) => {

  let studentPersonality = [];
  let teacherPersonality = [];
  dataProfile = dataProfile.dataProfile

  if (dataProfile?.userType === 'student' && dataProfile?.onboardingData) {
    studentPersonality = [
        { label: dataProfile.onboardingData.shortDescription ? 'Describe yourself in one sentence' : '', value: dataProfile.onboardingData.shortDescription || '' },
        { label: dataProfile.onboardingData.traits?.join(', ') ? 'Traits that describe your ideal music teacher' : '', value: dataProfile.onboardingData.traits?.join(', ') || '' },
        { label: dataProfile.onboardingData.detailedDescription ? 'Describe your personality in more detail' : '', value: dataProfile.onboardingData.detailedDescription || '' },
        { label: dataProfile.onboardingData.goals ? 'What are your short-term and long-term goals in music?' : '', value: dataProfile.onboardingData.goals || '' }
    ];
  } else if (dataProfile?.userType === 'teacher' && dataProfile?.onboardingData) {
    teacherPersonality = [
        { label: dataProfile.onboardingData.teacherDescription ? 'Describe yourself in one sentence' : '', value: dataProfile.onboardingData.teacherDescription || '' },
        { label: dataProfile.onboardingData.teacherTraits?.join(', ') ? 'Traits that best describe you' : '', value: dataProfile.onboardingData.teacherTraits?.join(', ') || '' },
        { label: dataProfile.onboardingData.personalityDescription ? 'Describe your personality in more detail' : '', value: dataProfile.onboardingData.personalityDescription || '' },
        { label: dataProfile.onboardingData.favoriteMusic ? 'What is your personal favorite song or type of music?' : '', value: dataProfile.onboardingData.favoriteMusic || '' },
        { label: dataProfile.onboardingData.goalsInMusic ? 'What are your short-term and long-term goals in music?' : '', value: dataProfile.onboardingData.goalsInMusic || '' }
    ];
  }

  const personalityInfo = dataProfile?.userType === 'student' ? studentPersonality : teacherPersonality;

  return (
    <Box
      sx={{
        border: '2px solid #C4C4FF',
        borderRadius: '12px',
        padding: '20px',
        margin: '10px 0',
        width: '100%',
      }}
    >
      <Typography 
        sx={{              
          fontFamily: 'Poppins',
          fontWeight: 'bold',
          fontSize: '24px',
          mb: 2
        }}
      >
        Personality Overview
      </Typography>
      {personalityInfo.map((info, index) => (
        info.label && info.value && (
          <Box key={index} sx={{ marginBottom: '10px' }}>
            <Typography 
              variant="h6"
              sx={{
                fontFamily: 'Poppins',
                fontWeight: 'semibold',
                fontSize: '22px',
              }}
            >
              {info.label}
            </Typography>
            <Typography 
              variant="body1"
              sx={{
                fontFamily: 'Poppins',
                fontSize: '20px',
                mb: 2
              }}
            >
              {info.value || ''}
            </Typography>
          </Box>
        )
      ))}
    </Box>
  );
};

export default ProfilePersonality;
