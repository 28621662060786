// SchedulingContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import { getFirestore, doc, getDoc, arrayUnion, updateDoc, onSnapshot } from '../services/firebase';
import { AuthContext } from './AuthContext';
import { parseISO } from 'date-fns';
import { deleteDoc, getDocs, query, where, arrayRemove, collection, addDoc } from 'firebase/firestore';
import moment from 'moment';
import { Timestamp } from 'firebase/firestore';

export const SchedulingContext = createContext();

export const SchedulingProvider = ({ children }) => {
  const [appointments, setAppointments] = useState([]);
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const db = getFirestore();

  const { currentUser, userProfile } = useContext(AuthContext); // Access user ID from AuthContext

  const fetchData = async () => {
    setLoading(true);
    setError(null);
  
    try {
        // Get a reference to the appointments collection
        const appointmentsCollection = collection(db, "appointments");
    
        // Determine the appropriate field and query based on the user type
        const userField = userProfile.userType === 'teacher' ? 'teacherID' : 'studentID';
        const userQuery = query(appointmentsCollection, where(userField, '==', currentUser.uid));

        const handleSnapshot = (snapshot) => {
            let userAppointments = [];

            snapshot.docs.forEach((doc) => {
                const appointmentData = doc.data();
                const date = moment(appointmentData.date.toDate()).startOf('day');
                const time = moment(appointmentData.from, 'HH:mm');
                const combinedDateTime = date.add(time.hours(), 'hours').add(time.minutes(), 'minutes');
      
                appointmentData.time = Timestamp.fromDate(combinedDateTime.toDate());
                appointmentData.id = doc.id;
                userAppointments.push(appointmentData);
            });

            // Instead of appending to the previous appointments, replace them
            setAppointments(userAppointments);
        };
    
        // Listen for changes in the query
        const unsubscribeUser = onSnapshot(userQuery, handleSnapshot);
    
        // Get a reference to the user's document
        const userDocRef = doc(db, 'users', currentUser.uid);
    
        const docSnapshot = await getDoc(userDocRef);
    
        if (docSnapshot.exists()) {
            // Extract the packages array from the user document
            let packages = docSnapshot.data().scheduling.packages;
            setPackages(packages); // Set packages state
        } else {
            console.log("No such user!");
        }
    
        setLoading(false);
    
        // Return cleanup function to stop listening when component is unmounted
        return () => {
            unsubscribeUser();
        };
    } catch (err) {
        setError(err);
        setLoading(false);
    }
};

  
  useEffect(() => {
    if (currentUser) { // Only fetch data if there is a current user
      fetchData();
    }
  }, [currentUser]); // Rerun effect when currentUser changes
  
  
  

  useEffect(() => {
    if (currentUser) { // Only fetch data if there is a current user
      fetchData();
    }
  }, [currentUser]); // Rerun effect when currentUser changes

  useEffect(() => {
    const userDocRef = doc(db, "users", currentUser.uid);
    const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
      const userData = docSnapshot.data();
      setPackages(userData.scheduling.packages);
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, [currentUser, db]);

  const addPackage = async (newPackage) => {
    const userDocRef = doc(db, "users", currentUser.uid);
    const userDocSnapshot = await getDoc(userDocRef);
    const userData = userDocSnapshot.data();

    // Ensure the "online" field is present
    newPackage.online = newPackage.online || false;

    const updatedPackages = [...userData.scheduling.packages, newPackage];

    await updateDoc(userDocRef, {
      'scheduling.packages': updatedPackages
    });
  };

  const updatePackage = async (updatedPackage) => {
    const userDocRef = doc(db, "users", currentUser.uid);
    const userDocSnapshot = await getDoc(userDocRef);
    const userData = userDocSnapshot.data();

    // Ensure the "online" field is present
    updatedPackage.online = updatedPackage.online || false;

    const updatedPackages = userData.scheduling.packages.map(pkg =>
      pkg.id === updatedPackage.id ? updatedPackage : pkg
    );

    await updateDoc(userDocRef, {
      'scheduling.packages': updatedPackages
    });
  };


  const addAvailability = async (newAvailability) => {
    try {
      const userDocRef = doc(db, "users", currentUser.uid);
      const userDocSnapshot = await getDoc(userDocRef);
      const userData = userDocSnapshot.data();
      const currentAvailabilities = userData.scheduling.availabilities || { regulars: {}, irregulars: [] };

      // Filter irregulars array to exclude dates with no defined slots
      newAvailability.irregulars = newAvailability.irregulars.filter(irregular => irregular.slots && irregular.slots.length > 0);

      const updatedAvailabilities = newAvailability;

      // Add new availability to the availabilities field in the scheduling map
      await updateDoc(userDocRef, {
        'scheduling.availabilities': updatedAvailabilities
      });
    } catch (error) {
      console.error("Error adding availability: ", error);
    }
  };

  const deletePackage = async (packageId) => {
    const userDocRef = doc(db, "users", currentUser.uid);
    const userDocSnapshot = await getDoc(userDocRef);
    const userData = userDocSnapshot.data();

    const updatedPackages = userData.scheduling.packages.filter(pkg =>
      pkg.id !== packageId
    );

    await updateDoc(userDocRef, {
      'scheduling.packages': updatedPackages
    });
  };

  const loadAvailability = async () => {
    try {

      const userDocRef = doc(db, "users", currentUser.uid);
      const userDocSnapshot = await getDoc(userDocRef);
      const userData = userDocSnapshot.data();
      let availabilities = userData?.scheduling?.availabilities;
      if (!availabilities) {
        availabilities = {
          regulars: {
            Monday: [],
            Tuesday: [],
            Wednesday: [],
            Thursday: [],
            Friday: [],
            Saturday: [],
            Sunday: [],
          },
          irregulars: []
        };
      }

      if (availabilities.irregulars) {

        availabilities.irregulars = availabilities.irregulars.map(irregular => {
          return {
            date: irregular.date.toDate(),
            slots: irregular.slots.map(slot => ({
              from: slot.from,
              to: slot.to
            }))
          };
        });
      }
      return availabilities;
    } catch (error) {
      console.error("Error loading availability: ", error);
    }
  };

  const addAppointment = async (appointmentData) => {
    try {
      // Get a reference to the "appointments" collection
      const appointmentsRef = collection(db, "appointments");

      // Add the appointment data to the collection
      const appointmentDocRef = await addDoc(appointmentsRef, appointmentData);

      console.log('Appointment added with ID:', appointmentDocRef.id);

      return appointmentDocRef.id; // Return the ID of the newly added appointment document
    } catch (error) {
      console.error('Error adding appointment:', error);
      throw new Error('Failed to add appointment');
    }
  };

  const cancelAppointment = async (appointmentId) => {
    // Get a reference to the document to delete
    const appointmentRef = doc(db, 'appointments', appointmentId);

    // Delete the document
    await deleteDoc(appointmentRef);

    console.log('Appointment cancelled:', appointmentId);
  }

  const cancelPackage = async (packageId) => {
    let q = null;
    // Get all appointments associated with the packageId
    if (userProfile.userType === "teacher") {
      q = query(
        collection(db, "appointments"),
        where("packageID", "==", packageId),
        where("teacherID", "==", currentUser.uid)
      );
    } else {
      q = query(
        collection(db, "appointments"),
        where("packageID", "==", packageId),
        where("studentID", "==", currentUser.uid)
      );
    }

    const snapshot = await getDocs(q);
    const deletes = snapshot.docs.map((docSnapshot) => {
      const appointmentRef = doc(db, 'appointments', docSnapshot.id);
      return deleteDoc(appointmentRef);
    });
    await Promise.all(deletes);
  }

  // // Function to accept a package
  // const acceptPackage = async (packageId) => {
  //   // Get all appointments associated with the packageId
  //   const q = query(collection(db, 'appointments'), where('packageId', '==', packageId));
  //   const snapshot = await getDocs(q);

  //   // Create a batch to perform all updates in a single operation
  //   const batch = db.batch();

  //   snapshot.docs.forEach(doc => {
  //     batch.update(doc.ref, { accepted: true });
  //   });

  //   // Commit the batch
  //   await batch.commit();
  // };

  const acceptPackage = async (packageId) => {
    const q = query(
      collection(db, "appointments"),
      where("packageID", "==", packageId),
      where("teacherID", "==", currentUser.uid)
    );

    const snapshot = await getDocs(q);
    const updates = snapshot.docs.map((docSnapshot) => {
      const appointmentRef = doc(db, 'appointments', docSnapshot.id);
      return updateDoc(appointmentRef, {
        accepted: true
      });
    });
    await Promise.all(updates);
  }

  return (
    <SchedulingContext.Provider
      value={{
        appointments,
        packages,
        loading,
        error,
        addAppointment,
        cancelAppointment,
        addPackage,
        updatePackage,
        deletePackage,
        addAvailability,
        loadAvailability,
        fetchData,
        cancelPackage,
        acceptPackage
      }}
    >
      {children}
    </SchedulingContext.Provider>
  );
};

export default SchedulingProvider;
