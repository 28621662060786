import React, { useContext, useEffect, useState } from 'react';
import { Grid, Box, Typography, Button, InputBase, IconButton } from '@mui/material';
import { ExploreContext } from '../../contexts/ExploreContext';
import { AuthContext } from '../../contexts/AuthContext'; 
import ExploreTeacherCard from '../profile_components/ExploreTeacherCard';
import SavedTeacherCard from '../profile_components/SavedTeacherCard'; 
import { Search as SearchIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import FilterDialog from '../profile_components/FilterDialog';  

function Explore () {
    const { teachers, fetchTeachers } = useContext(ExploreContext);
    const { usersSavedTeachers, currentUser } = useContext(AuthContext); 
    const [searchQuery, setSearchQuery] = useState("");
    const [filterDialogOpen, setFilterDialogOpen] = useState(false);
    const [filteredTeachers, setFilteredTeachers] = useState(teachers);

  useEffect(() => {
    fetchTeachers();
    console.log(teachers)
  }, []);

  useEffect(() => {
    setFilteredTeachers(teachers);
  }, [teachers]);

  const handleOpenFilters = () => {
    setFilterDialogOpen(true);
  };

  const handleCloseFilters = () => {
    setFilterDialogOpen(false);
  };

  const handleFilterApply = (selectedTeachingMode, selectedInstruments, selectedGenres) => {
    let newFilteredTeachers = [...teachers]; 

    if (selectedTeachingMode) {
      newFilteredTeachers = newFilteredTeachers.filter(teacher => teacher.onboardingData.teachingMode === selectedTeachingMode);
    }

    if (selectedInstruments && selectedInstruments.length > 0) {
      newFilteredTeachers = newFilteredTeachers.filter(teacher => selectedInstruments.some(instrument => teacher.onboardingData.teachingInstruments && teacher.onboardingData.teachingInstruments.includes(instrument)));
    }

    if (selectedGenres && selectedGenres.length > 0) {
      newFilteredTeachers = newFilteredTeachers.filter(teacher => selectedGenres.some(genre => teacher.onboardingData.teachingGenres && teacher.onboardingData.teachingGenres.includes(genre)));
    }

    setFilteredTeachers(newFilteredTeachers); 
};


const handleStrikeACHORD = () => {
};




  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: 5,  
          py: 2,  
          mt: 3 
        }}
      >
    <Button
        sx={{ 
            px: '20px', 
            py: '5px', 
            borderRadius: '30px', 
            backgroundColor: '#ffffff', 
            border: '1px solid lightgrey', 
            color: '#6C5DD3',
            textTransform: 'none', 
            fontWeight: '600', 
            fontFamily: 'Poppins', 
            fontSize: 16,
            '&:hover': {
                backgroundColor: '#C4C4FF',
            },
        }} 
        variant="contained"
        endIcon={<FilterAltIcon />}
        onClick={handleOpenFilters}  
    >
    <Box>Filters</Box>
    </Button>
    <FilterDialog
        open={filterDialogOpen}
        handleClose={handleCloseFilters}
        handleFilterApply={handleFilterApply}
        teachingModes={['Online', 'In Person', 'Both/Either']}
        instruments={["Piano", "Guitar", "Violin", "Voice", "Cello", "Flute", "Drums", "Saxophone", "Clarinet", "Trumpet", "Harp", "Viola", "Trombone", "Ukulele", "Bass Guitar", "Double Bass", "Accordion", "Banjo", "Mandolin", "Harmonica", "Oboe", "Synthesizer", "Other" ]} 
        genres={  ["Pop", "Rock","Country","Jazz","Blues","Classical","Hip Hop","Reggae","Electronic","Folk","Soul","R&B", "Bluegrass", "Gospel", "Punk", "Metal", "Indie", "Latin", "World Music", "Alternative", "Other"]} 
      />
    <ExploreSearchBox setSearchQuery={setSearchQuery} /> 
    <Button
        sx={{ 
            px: '20px', 
            py: '5px', 
            borderRadius: '30px', 
            backgroundColor: '#ffffff', 
            border: '1px solid lightgrey', 
            color: '#6C5DD3', 
            textTransform: 'none', 
            fontWeight: 'bold', 
            fontFamily: 'Poppins', 
            fontSize: 16,
            '&:hover': {
                backgroundColor: '#C4C4FF',
            },
        }} 
        variant="contained"
        endIcon={<MusicNoteIcon />}
        onClick={handleStrikeACHORD}  
    >
    <Box>Strike ACHORD</Box>
    </Button>

    </Box>

      <Grid container spacing={0} sx={{ px: '80px', py: 6 }} >
        <Grid item xs={9}>
          <Box sx={{ height: '500px', overflowY: 'scroll' }}>
            <Grid container spacing={3}>
              {filteredTeachers.filter(teacher => teacher.fullName.toLowerCase().includes(searchQuery.toLowerCase())).map((teacher, index) => (
                <Grid key={index} item xs={6}>
                  <ExploreTeacherCard teacher={teacher} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
          <Grid item xs={3} sx={{gap: 3}}>
                  <Box sx={{ borderBottom: 1, borderColor: 'black', pb: 1}}>
                  <Typography 
                      variant="h5" 
                      sx={{ 
                      fontFamily: 'Poppins', 
                      fontWeight: '600', 
                      fontSize: 20,
                      }}
                  >
                      Saved Teachers
                  </Typography>
                  </Box>
                  {currentUser &&(
                    <Box sx={{ height: '475px', overflowY: 'scroll', width: '350px' }}>
                    {usersSavedTeachers.map((teacher, index) => (
                      <Box key={index} sx={{ mt: 3 }}>
                        <SavedTeacherCard teacher={teacher} />
                      </Box>
                    ))}
                  </Box>
                  )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Explore;


function ExploreSearchBox({ setSearchQuery }) {
    const navigate = useNavigate();
  
    const handleClick = () => {
      navigate('/explore');
    };

    const handleChange = (event) => {
        setSearchQuery(event.target.value);
      };
  
    return (
      <div
        style={{
          padding: '10px 15px', 
          display: 'flex',
          alignItems: 'center',
          width: 400,
          borderRadius: 50,
          border: '1px solid #E3E3E3',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', 
        }}
        onClick={handleClick}
      >
      <InputBase
        style={{
          marginLeft: 10,
          flex: 1,
          color: '#000000', 
        }}
        placeholder="Start Your Search"
        inputProps={{ 'aria-label': 'start your search' }}
        onChange={handleChange}
      />
        <IconButton type="submit" style={{ padding: 0, backgroundColor: '#6C5DD3', borderRadius: '50%', height: '32px', width: '32px' }} aria-label="search">
          <SearchIcon style={{ color: '#ffffff' }} />
        </IconButton>
      </div>
    );
  }