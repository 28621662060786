import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Avatar, Typography, Box, Button, Grid } from '@mui/material';
import { red, deepPurple } from '@mui/material/colors';
import { ExploreContext } from '../../contexts/ExploreContext';


const SavedTeacherCard = ({ teacher }) => {
    const navigate = useNavigate();
    const { setCurrentTeacherViewing } = useContext(ExploreContext);
  
    const handleViewProfile = () => {
        const url = `${window.location.origin}/teacher-profile/${teacher.id}`;
        window.open(url, "_blank");
    };    

    return (
        <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: "306px", borderRadius: 3, border: 1, borderColor: deepPurple[200], boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', maxHeight: "100px", py: 1.5, px: 2.5 }}>
            <Grid container>
                <Grid item xs={3}>
                    <Avatar sx={{ bgcolor: red[500], border: 2, borderColor: deepPurple[200], width: 60, height: 60 }} src={teacher.pictureURL} />
                </Grid>
                <Grid item xs={9} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                    <Typography variant='subtitle1' sx={{ fontWeight: '600', textAlign: 'right', fontFamily: "poppins", fontSize: 18 }}>
                        {teacher.fullName}
                    </Typography>
                    <Button
                        sx={{ alignSelf: 'flex-end', px: '20px', py: '10px', borderRadius: '40px', backgroundColor: '#6C5DD3' }} 
                        variant="contained"
                        onClick={handleViewProfile}
                    >
                        <Box sx={{ fontWeight: 'bold' }}>View Profile</Box>
                    </Button>
                </Grid>
            </Grid>
        </Card>
    );
};

export default SavedTeacherCard;
