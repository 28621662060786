import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { Box, Button, Container, Typography, Avatar, Divider, TextField, IconButton, Snackbar, Alert } from "@mui/material";
import { styled } from "@mui/system";
import SaveIcon from '@mui/icons-material/Save';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import theme from "../theme";
import { storage, ref, uploadBytesResumable, getDownloadURL } from "../../services/firebase";
import { readAndCompressImage } from 'browser-image-resizer';

const imageConfig = {
  quality: 0.7, // Reduce this to reduce final file size (0.7 is a suggested value)
  maxWidth: 800, // New max size
  maxHeight: 800, // New max size
  autoRotate: true,
  debug: true,
};

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(20),
  height: theme.spacing(20),
  margin: theme.spacing(1),
}));

const EditProfile = () => {
  const { userProfile, updateUserProfile, currentUser, updateEmailFrontEnd } = useContext(AuthContext);
  const navigate = useNavigate();

  const [name, setName] = useState(userProfile ? userProfile.fullName : '');
  const [email, setEmail] = useState(userProfile ? userProfile.email : '');
  const [password, setPassword] = useState('');
  const [image, setImage] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(userProfile ? userProfile.pictureURL : null);
  const [uploading, setUploading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (userProfile) {
      setName(userProfile.fullName);
      setImagePreviewUrl(userProfile.pictureURL);
    }
  }, [userProfile]);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    try {
      const resizedFile = await readAndCompressImage(file, imageConfig);
      resizedFile.name = file.name; // Set the name of the file
      setImage(resizedFile);
      setImagePreviewUrl(URL.createObjectURL(resizedFile));
    } catch (err) {
      console.log(err);
    }
  };


  const handleUploadImage = async () => {
    if (image) {
      setUploading(true);
      const uid = currentUser.uid; // get user's UID
      const storageRef = ref(storage, `profile-pictures/${uid}_${image.name}`);
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on('state_changed',
        (snapshot) => {
          // optional: add progress function here
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log(error);
          setError(true);
          setUploading(false);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            updateUserProfile({ ...userProfile, pictureURL: downloadURL });
            setUploading(false);
            setSuccess(true);
          });
        }
      );
    }
  };

  const handleOnboardingNavigation = () => {
    if (userProfile.userType === 'student') {
      navigate("/OnboardingStudent3");
    } else {
      navigate("/OnboardingTeacher2");
    }
  };


  const handleSaveChanges = async () => {

    await handleUploadImage();

    if (userProfile) {
      await updateUserProfile({ ...userProfile, fullName: name, email: email });
      const didUpdateEmail = await updateEmailFrontEnd(email, password);
      if (didUpdateEmail == 0) {
        alert('Incorrect Password! Please try again.');
        return;
      } else {
        alert("Success!");
      }
    }
    navigate("/myProfile")
  }

  const handleCloseSnackbar = () => {
    setSuccess(false);
    setError(false);
  }

  if (!userProfile) {
    return <div>Loading...</div>;
  }

  return (
    <Container maxWidth={false} sx={{ minHeight: '90vh' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid black', py: 2, px: 2 }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', fontFamily: 'Poppins' }}>Edit My Profile</Typography>
        <Button
          sx={{ px: '20px', py: '10px', borderRadius: '15px', backgroundColor: theme.palette.achord_purple.main }} variant="contained" endIcon={<SaveIcon />}
          onClick={handleSaveChanges}
        >
          <Box sx={{ fontWeight: 'bold' }}>Save Changes</Box>
        </Button>
      </Box>
      <Divider />
      <Box sx={{ display: 'flex', marginTop: 2 }}>
        <Box sx={{ width: '33%', marginRight: 2, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: 2, gap: 1 }}>
          <Box sx={{ position: 'relative' }}>
            <StyledAvatar src={imagePreviewUrl} />
            <IconButton
              sx={{ position: 'absolute', right: 0, bottom: 0, backgroundColor: 'rgba(255, 255, 255, 0.7)' }}
              component="label"
            >
              <input
                accept="image/*"
                type="file"
                hidden
                onChange={handleImageChange}
              />
              <AddAPhotoIcon />
            </IconButton>
          </Box>
          <Typography sx={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: "20px" }}>Full Name</Typography>
          <TextField
            variant="outlined"
            label="Name"
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <Typography sx={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: "20px" }}>Email</Typography>
          <TextField
            variant="outlined"
            label="Email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <Typography sx={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: "20px" }}>Re-enter your current password</Typography>
          <TextField
            variant="outlined"
            label="Password"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          {
            userProfile.onboardingData ? (
              <Button onClick={handleOnboardingNavigation} variant="contained" sx={{ backgroundColor: theme.palette.achord_purple.main, mt: 3, fontFamily: "Poppins", fontSize: "16px", fontWeight: "bold" }}>Change My Profile Answers</Button>

            ) : (
              <></>
            )
          }
        </Box>
        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        </Box>
      </Box>
      <Snackbar open={success} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success">
          Image uploaded successfully!
        </Alert>
      </Snackbar>
      <Snackbar open={error} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error">
          There was an error uploading your image.
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default EditProfile;