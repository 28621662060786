import { initializeApp } from 'firebase/app';
import { getAuth, signOut, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, deleteUser, updateEmail, EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
import { arrayRemove, collection, getDocs, increment, getFirestore, doc, setDoc, updateDoc, arrayUnion, getDoc, where, query, Timestamp, serverTimestamp, deleteDoc, onSnapshot, addDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions"; // Import for functions



const firebaseConfig = {
    apiKey: "AIzaSyA2SmLxpTQzDCa7A1CSFQXp6SNkhukCU14",
    authDomain: "achord-web.firebaseapp.com",
    projectId: "achord-web",
    storageBucket: "achord-web.appspot.com",
    messagingSenderId: "835125883267",
    appId: "1:835125883267:web:604a41857f44276bd186b6",
    measurementId: "G-5M2PHHW40D"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);
const functions = getFunctions(app);

export { auth, storage, functions, reauthenticateWithCredential, EmailAuthProvider, updateEmail, httpsCallable, ref, uploadBytesResumable, getDownloadURL, getDocs, arrayRemove, collection, deleteUser, deleteDoc, sendPasswordResetEmail , increment, Timestamp, serverTimestamp, query, where, getDoc, getAuth, signOut, createUserWithEmailAndPassword, signInWithEmailAndPassword, getFirestore, doc, setDoc, updateDoc, arrayUnion, onSnapshot, addDoc }; // Also add these three to the export

