import React, { useContext, useState } from "react";
import { Button, ThemeProvider, Typography, Box } from "@mui/material";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import EditIcon from '@mui/icons-material/Edit';
import { List, Divider, ListItem, ListItemButton, ListItemText, ListItemAvatar, Collapse } from "@mui/material";
import Stack from '@mui/material/Stack';
import { Avatar } from "@mui/material";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import theme from "../theme";
import { SchedulingContext } from '../../contexts/SchedulingContext';
import NewBookingModal from "./NewBookingModal";
import { AuthContext } from '../../contexts/AuthContext';
import PackageDialog from "./PackageDialog";
import AvailabilityDialog from "./AvailabilityDialog";

const BookingItem = ({ bookingListItem, isUpcoming }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { cancelAppointment, acceptPackage, cancelPackage } = useContext(SchedulingContext);
    const { userProfile } = useContext(AuthContext);

    const handleCancelPackage = (id) => {
        const confirmation = window.confirm(`Are you sure you want to cancel the package?`);
        if (confirmation) {
            // Here we're passing the packageId as an argument to the function
            cancelPackage(id);
        }
    };

    const handleAcceptPackage = (id) => {
        const confirmation = window.confirm(`Are you sure you want to accept the package?`);
        if (confirmation) {
            // Here we're passing the packageId as an argument to the function
            acceptPackage(id);
        }
    };

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    if (bookingListItem.isPackage) {
        console.log("booking list itme", bookingListItem.appointments);
        return (
            <Box>
                <ListItem
                    onClick={toggleExpanded}
                    sx={{ my: 1.5, py: 1.5, px: 2, borderRadius: '12px', border: 1, borderColor: '#C4C4FF', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
                    secondaryAction={
                        <Stack direction="column" alignItems="flex-end" spacing='10px'>
                            {userProfile.userType === 'teacher' &&
                                <Button
                                    variant="contained"
                                    sx={{ px: '20px', py: '10px', borderRadius: '15px', backgroundColor: '#12ab10' }}
                                    onClick={() => handleAcceptPackage(bookingListItem.appointments[0].packageID)}>
                                    <Box sx={{ fontWeight: 'bold', fontSize: '14px' }}>Accept Package</Box>
                                </Button>
                            }
                            <Button
                                variant="contained"
                                sx={{ px: '20px', py: '10px', borderRadius: '15px', backgroundColor: '#C52A66' }}
                                onClick={() => handleCancelPackage(bookingListItem.appointments[0].packageID)}>
                                <Box sx={{ fontWeight: 'bold', fontSize: '14px' }}>Cancel Package Request</Box>
                            </Button>
                        </Stack>
                    }
                >
                    <ListItemAvatar>
                        <Avatar sx={{ border: 2, borderColor: '#C4C4FF' }} src={userProfile.userType === 'student' ? bookingListItem.appointments[0].teacher.image : bookingListItem.appointments[0].student.image}>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Box sx={{ fontWeight: 'bold' }}>{userProfile.userType === 'student' ? bookingListItem.appointments[0].teacher.name : bookingListItem.appointments[0].student.name}</Box>
                        }
                        secondary={
                            <List disablePadding dense>
                                <ListItem disablePadding>
                                    <ListItemText sx={{ m: 0 }}>
                                        <Typography variant="body2" color="text.secondary">
                                            Package: {bookingListItem.appointments[0].packageName}
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemText sx={{ m: 0 }}>
                                        <Typography variant="body2" color="text.secondary">
                                            Instrument: {bookingListItem.appointments[0].instrument}
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemText sx={{ m: 0 }}>
                                        <Typography variant="body2" color="text.secondary">
                                            Location: {bookingListItem.appointments[0].location}
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                            </List>
                        }
                    />
                </ListItem>
                {isExpanded && bookingListItem.appointments
                    .sort((a, b) => a.date.seconds - b.date.seconds)
                    .map(appointment => {
                        const timeString = appointment.from; // Assuming appointment.from is something like "09:30"
                        const [hours, minutes] = timeString.split(':').map(Number);

                        const date = new Date(appointment.date.seconds * 1000); // Using the appointment date
                        date.setHours(hours);
                        date.setMinutes(minutes);

                        return (

                            < ListItem key={appointment.id} >
                                <ListItemAvatar>
                                    <Avatar src={userProfile.userType === 'student' ? appointment.teacher.image : appointment.student.image}>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={userProfile.userType === 'student' ? appointment.teacher.name : appointment.student.name}
                                    secondary={`Time: ${`${date.toLocaleTimeString([], { month: 'long', day: 'numeric' })}, ${date.toLocaleDateString([], { month: 'long', day: 'numeric' })}`}`}
                                />
                            </ListItem>
                        )


                    })}
            </Box >
        )
    } else {
        const singleAppointment = bookingListItem.appointment;
        //converting the timestamp from firebase to a Date object
        const date = new Date(singleAppointment.date.seconds * 1000);
        // Format the date to '3:00 PM, June 25th'
        const optionsTime = { hour: 'numeric', minute: 'numeric' };
        const optionsDate = { month: 'long', day: 'numeric' };
        const timeString = date.toLocaleTimeString([], optionsTime);
        const dateString = date.toLocaleDateString([], optionsDate);
        const fullDateString = `${timeString}, ${dateString}`;


        const handleCancelLesson = () => {
            const confirmation = window.confirm(`Are you sure you want to cancel the appointment scheduled for ${fullDateString}?`);
            if (confirmation) {
                cancelAppointment(singleAppointment.id);
            }
        };
        return (
            <ListItem
                sx={{ my: 1.5, py: 1.5, px: 2, borderRadius: '12px', border: 1, borderColor: '#C4C4FF', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
                secondaryAction={
                    isUpcoming ?
                        <Stack direction="column" alignItems="flex-end" spacing='10px'>

                            <Button
                                variant="contained"
                                sx={{ px: '20px', py: '10px', borderRadius: '15px', backgroundColor: '#C52A66' }}
                                onClick={handleCancelLesson}>
                                <Box sx={{ fontWeight: 'bold', fontSize: '14px' }}>Cancel Lesson</Box>
                            </Button>
                        </Stack>
                        :
                        null
                }
            >
                <ListItemAvatar>
                    <Avatar sx={{ border: 2, borderColor: '#C4C4FF' }} src={userProfile.userType === 'student' ? singleAppointment.teacher.image : singleAppointment.student.image}>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <Box sx={{ fontWeight: 'bold' }}>{userProfile.userType === 'student' ? singleAppointment.teacher.name : singleAppointment.student.name}</Box>
                    }
                    secondary={
                        <List disablePadding dense>
                            <ListItem disablePadding>
                                <ListItemText sx={{ m: 0 }}>
                                    <Typography variant="body2" color="text.secondary">
                                        {fullDateString}
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemText sx={{ m: 0 }}>
                                    <Typography variant="body2" color="text.secondary">
                                        Instrument: {singleAppointment.instrument}
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemText sx={{ m: 0 }}>
                                    <Typography variant="body2" color="text.secondary">
                                        Location: {singleAppointment.location}
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                        </List>
                    }
                />
            </ListItem>
        )
    }
};


function Booking() {

    const { userProfile } = useContext(AuthContext);
    const { appointments } = useContext(SchedulingContext);
    const [open, setOpen] = useState(false);
    const [openAvails, setOpenAvails] = useState(false);
    const [newBookingOpen, setNewBookingOpen] = useState(false);
    const [requestOpen, setRequestOpen] = useState(true);
    const [upcomingOpen, setupcomingOpen] = useState(true);
    const [completedOpen, setcompletedOpen] = useState(false);

    const handleEditPackages = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleEditAvailabilities = () => {
        setOpenAvails(true);
    };

    const handleCloseAvails = () => {
        setOpenAvails(false);
    }

    // Current time in seconds since Unix epoch
    const now = Math.floor(Date.now() / 1000);

    const upcomingAppointments = appointments.filter(appointment => appointment.time.seconds > now && appointment.accepted).sort((a, b) => a.time.seconds - b.time.seconds);

    const completedAppointments = appointments.filter(appointment => appointment.time.seconds <= now && appointment.accepted);

    // New requestedAppointments array that stores appointments where accepted is false
    let requestedAppointments = appointments.filter(appointment => appointment.time.seconds > now && !appointment.accepted);

    let packages = {};

    requestedAppointments.forEach(appointment => {
        // Delete old unaccepted appointments
        if (appointment.time.seconds < now) {
            console.log('Deleting an old unaccepted appointment with ID: ' + appointment.id);
            // Logic to delete the appointment from your data source
        } else if (appointment.packageID) {
            if (!packages[appointment.packageID]) {
                packages[appointment.packageID] = {
                    isPackage: true,
                    appointments: []
                };
            }
            // Add the appointment to the appropriate package
            packages[appointment.packageID].appointments.push(appointment);
        }
        // Handle appointments not part of any package
        else {
            packages[appointment.id] = {
                isPackage: false,
                appointment: appointment
            };
        }
    });

    // Transform the packages object back into an array
    requestedAppointments = Object.values(packages);

    const handleNewBookingOpen = () => {
        console.log("newbooking");
        // new booking modal
        setNewBookingOpen(true)
    };

    const handleNewBookingClose = () => {
        setNewBookingOpen(false);
    };

    const handleRequestClick = () => {
        setRequestOpen(!requestOpen);
    };

    const handleUpcomingClick = () => {
        setupcomingOpen(!upcomingOpen);
    };

    const handleCompletedClick = () => {
        setcompletedOpen(!completedOpen);
    };

    return (
        <div>

            <NewBookingModal newBookingOpen={newBookingOpen} handleNewBookingClose={handleNewBookingClose} />


            <PackageDialog open={open} onClose={handleClose} />
            <AvailabilityDialog open={openAvails} onClose={handleCloseAvails} />

            <ThemeProvider theme={theme}>
                {/* ..                 <Button
//                     sx={{ px: '20px', py: '10px', borderRadius: '15px' }} variant="contained" color="achord_purple" endIcon={<ControlPointIcon />}
//                     onClick={handleNewBookingOpen}
//                 >
//                     <Box sx={{ fontWeight: 'bold' }}>New Booking</Box>
//                 </Button> */}

                {userProfile?.userType === "student" ? (
                    <Button
                        sx={{ px: '20px', py: '10px', borderRadius: '15px' }} variant="contained" color="achord_purple" endIcon={<ControlPointIcon />}
                        onClick={handleNewBookingOpen}
                    >
                        <Box sx={{ fontWeight: 'bold' }}>New Booking</Box>
                    </Button>
                ) : (
                    <Button
                        sx={{ px: '20px', py: '10px', borderRadius: '15px' }}
                        variant="contained"
                        color="achord_purple"
                        endIcon={<EditIcon />}
                        onClick={handleEditPackages}
                    >
                        <Box sx={{ fontWeight: 'bold' }}>Edit Packages</Box>
                    </Button>
                )}
                {userProfile?.userType === "teacher" && (
                    <Button
                        sx={{ px: '20px', py: '10px', borderRadius: '15px', float: 'right' }}
                        variant="contained"
                        color="achord_purple"
                        endIcon={<EditIcon />}
                        onClick={handleEditAvailabilities}
                    >
                        <Box sx={{ fontWeight: 'bold' }}>Edit Availabilities</Box>
                    </Button>
                )}
            </ThemeProvider>

            <List
                sx={{ maxHeight: '800px', width: '100%', bgcolor: 'background.paper', overflow: 'auto' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <ListItemButton onClick={handleRequestClick}>
                    <ListItemText primary={
                        <Box sx={{ fontWeight: 'bold', fontSize: '20px' }}>Requested Bookings</Box>
                    } />
                    {requestOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Divider />
                <Collapse in={requestOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {requestedAppointments.map((item, index) => (
                            <BookingItem key={index} bookingListItem={item} isUpcoming={true} />
                        ))}
                    </List>
                </Collapse>

                <ListItemButton onClick={handleUpcomingClick}>
                    <ListItemText primary={
                        <Box sx={{ fontWeight: 'bold', fontSize: '20px' }}>Upcoming Booking</Box>
                    } />
                    {upcomingOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Divider />
                <Collapse in={upcomingOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {upcomingAppointments.map((item, index) => (
                            <BookingItem key={index} bookingListItem={{ appointment: item }} isUpcoming={true} />
                        ))}
                    </List>
                </Collapse>

                <ListItemButton onClick={handleCompletedClick}>
                    <ListItemText primary={
                        <Box sx={{ fontWeight: 'bold', fontSize: '20px' }}>Completed Booking</Box>
                    } />
                    {completedOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Divider />
                <Collapse in={completedOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {completedAppointments.map((item, index) => (
                            <BookingItem key={index} bookingListItem={{ appointment: item }} isUpcoming={false} />
                        ))}
                    </List>
                </Collapse>
            </List>
        </div>
    );
}

export default Booking;