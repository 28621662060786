import React, { useState, useContext } from 'react';
import { TextField, Button, FormControlLabel, Radio, RadioGroup, Checkbox, Box, Typography, Grid, Link } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AuthContext } from '../../../contexts/AuthContext';
import SignInImage from '../../../logos_images/SignInImage.jpg';
import { useNavigate } from 'react-router-dom'; 

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
  },
  palette: {
    primary: {
      main: '#6C5DD3',
    },
  },
});

function Signup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userType, setUserType] = useState('student');
  const [error, setError] = useState('');
  const [agreeToPrivacyPolicy, setAgreeToPrivacyPolicy] = useState(false);
  const [isParentManaged, setIsParentManaged] = useState(false);

  const { signupUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    if (!agreeToPrivacyPolicy) {
      alert("You must agree to the privacy policy to proceed!");
      return;
    }
  
    try {
      const signupSuccess = await signupUser(email, password, fullName, userType, isParentManaged);
      if (signupSuccess) {
          navigate('/addProfilePic');
      }
    } catch (error) {
      setError('Error signing up user');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container>
        <Grid item xs={12} md={6} sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          backgroundImage: `url(${SignInImage})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}>
          {/* Empty Grid item to hold image */}
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', p: 3, height: '100vh' }}>
            <Typography variant="h4" sx={{ textAlign: 'center', width: '100%', mb: 3 }}>Sign Up</Typography>
            <form onSubmit={handleSubmit} sx={{ width: '100%' }}>
              <TextField
                label="Full Name"
                variant="outlined"
                onChange={e => setFullName(e.target.value)}
                margin="normal"
                fullWidth
              />
              <TextField
                label="Email"
                variant="outlined"
                onChange={e => setEmail(e.target.value)}
                margin="normal"
                fullWidth
              />
              <TextField
                label="Password"
                type="password"
                variant="outlined"
                onChange={e => setPassword(e.target.value)}
                margin="normal"
                fullWidth
              />
              <TextField
                label="Confirm Password"
                type="password"
                variant="outlined"
                onChange={e => setConfirmPassword(e.target.value)}
                margin="normal"
                fullWidth
              />
              <Typography>
                Who are you?
              </Typography>
              <RadioGroup
                aria-label="user type"
                name="userType"
                value={userType}
                onChange={(e) => setUserType(e.target.value)}
                sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 1 }}
              >
                <FormControlLabel value="student" control={<Radio />} label="Student" />
                <FormControlLabel value="teacher" control={<Radio />} label="Teacher" />
              </RadioGroup>
              <FormControlLabel
                control={<Checkbox checked={isParentManaged} onChange={(e) => setIsParentManaged(e.target.checked)} />}
                label={(
                  <Typography>
                    I am signing up as a parent.
                  </Typography>
                )}
              />
              <Box></Box>
              <FormControlLabel
                control={<Checkbox checked={agreeToPrivacyPolicy} onChange={(e) => setAgreeToPrivacyPolicy(e.target.checked)} />}
                label={(
                  <Typography>
                    I agree to the&nbsp;
                    <Link href="/privacy-policy" target="_blank" rel="noopener">
                      Privacy Policy
                    </Link>
                  </Typography>
                )}
              />
              <Box sx={{ marginBottom: '1rem', mt: 2, mb: 2 }}>
                <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{error}</Typography>
              </Box>
              <Button variant="contained" type="submit" sx={{ mt: 1, width: '100%' }}>Create Account</Button>
            </form>
            <Link
              component="button"
              variant="body2"
              underline="always"
              onClick={() => navigate("/login")}
              sx={{ mt: 2 }}
            >
              Already have an account? Login Here.
            </Link>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default Signup;
