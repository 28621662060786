import React, { useState, useContext, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Stack } from '@mui/material';
import { SchedulingContext } from '../../contexts/SchedulingContext';
import { v4 as uuidv4 } from 'uuid';
//import PackageCard from './PackageCard';
import { ThemeProvider, Typography, Box, IconButton  } from '@mui/material';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import { List, ListItem, ListItemText } from "@mui/material";
import theme from "../theme";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function PackageDialog({ open, onClose }) {
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [newPackage, setNewPackage] = useState(null);
    const { packages, updatePackage, addPackage, deletePackage } = useContext(SchedulingContext);

    useEffect(() => {
        if (selectedPackage) {
            const updatedPackage = packages.find(pkg => pkg.id === selectedPackage.id);
            setSelectedPackage(updatedPackage);
        }
    }, [packages]);

    const handleAddPackage = async () => {
        try {
            console.log(newPackage);
            // if(!newPackage.online) {
            //     setNewPackage({ ...newPackage, online: false });
            // }
            // Ensure the new package has a unique id
            const newPackageWithId = { ...newPackage, id: uuidv4() };
            await addPackage(newPackageWithId);
            setNewPackage(null);
        } catch (err) {
            console.error("Failed to add package: ", err);
        }
    };

    const handleUpdatePackage = async () => {
        try {
            await updatePackage(selectedPackage);
            setSelectedPackage(null);
        } catch (err) {
            console.error("Failed to update package: ", err);
        }
    };

    const handleEdit = (pkg) => {
        setSelectedPackage(pkg);
    };

    const handleNewPackage = () => {
        setNewPackage(true);
    };

    const handleDeletePackage = async (pkgId) => {
        try {
            await deletePackage(pkgId);
        } catch (err) {
            console.error("Failed to delete package: ", err);
        }
    };

    // Commented out hard-coded package data. We are now using live data from the context.
    // const packages = [ ... ];
    const packageDialogTitle = newPackage ? 'Create New Package' : 'My Packages';

    return (
        <Dialog open={open} onClose={onClose} PaperProps={{
            sx: {
                width: '50%',
                maxWidth: '100%',
                paddingBottom: 3
            }
        }}>
            <DialogTitle>
                <Box sx={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid grey', py: 1 }}>
                    <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', fontSize: 24 }}>
                        {packageDialogTitle}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={onClose}
                        sx={{ marginLeft: 'auto' }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                {newPackage ? (
                    // Display form for adding a new package
                    <form>
                        <Stack spacing={2} direction="column" sx={{ fontFamily: 'Poppins', fontSize: 14, fontWeight: 500, marginTop: 2}}>
                            <TextField
                                label="Package Name"
                                onChange={(e) => setNewPackage({ ...newPackage, name: e.target.value })}
                            />
                            <TextField
                                label="Instrument"
                                onChange={(e) => setNewPackage({ ...newPackage, instrument: e.target.value })}
                            />
                            <TextField
                                label="Location/Zoom Link"
                                onChange={(e) => setNewPackage({ ...newPackage, location: e.target.value })}
                            />
                            <TextField
                                label="Duration (in minutes)"
                                type="number"
                                onChange={(e) => setNewPackage({ ...newPackage, duration: e.target.value })}
                            />
                            <TextField
                                label="Number of Lessons"
                                type="number"
                                onChange={(e) => setNewPackage({ ...newPackage, numberLessons: e.target.value })}
                            />
                            <label style={{display: 'flex', alignItems: 'center', gap: '10px', fontSize: '16px', marginBottom: 6}}>
                                Online:
                                <input
                                    type="checkbox"
                                    onChange={(e) => setNewPackage({ ...newPackage, online: e.target.checked })}
                                />
                            </label>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
                                <Button
                                    sx={{ px: '20px', py: '10px', borderRadius: '15px', backgroundColor: theme.palette.achord_purple.main }} 
                                    variant="contained"
                                    startIcon={<ArrowBackIcon />}
                                    onClick={() => { setNewPackage(null); }}
                                >
                                    <Box sx={{ fontWeight: 'bold' }}>Go Back</Box>
                                </Button>

                                <Button
                                    sx={{ px: '20px', py: '10px', borderRadius: '15px', backgroundColor: '#36C752' }} 
                                    variant="contained"
                                    endIcon={<ControlPointIcon />}
                                    onClick={handleAddPackage}  
                                >
                                    <Box sx={{ fontWeight: 'bold' }}>Create</Box>
                                </Button>
                            </Box>
                        </Stack>

                    </form>
                ) : selectedPackage ? (
                    // Display form for editing the selected package
                    <form>
                        <Stack spacing={2} direction="column" sx={{ fontFamily: 'Poppins', fontSize: 14, fontWeight: 500, marginTop: 2}}>
                            <TextField
                                label="Package Name"
                                value={selectedPackage.name}
                                onChange={(e) => setSelectedPackage({ ...selectedPackage, name: e.target.value })}
                            />
                            <TextField
                                label="Instrument"
                                value={selectedPackage.instrument}
                                onChange={(e) => setSelectedPackage({ ...selectedPackage, instrument: e.target.value })}
                            />
                            <TextField
                                label="Location/Zoom Link"
                                value={selectedPackage.location}
                                onChange={(e) => setSelectedPackage({ ...selectedPackage, location: e.target.value })}
                            />
                            <TextField
                                label="Duration (in minutes)"
                                type="number"
                                value={selectedPackage.duration}
                                onChange={(e) => setSelectedPackage({ ...selectedPackage, duration: e.target.value })}
                            />
                            <TextField
                                label="Number of Lessons"
                                type="number"
                                value={selectedPackage.numberLessons}
                                onChange={(e) => setSelectedPackage({ ...selectedPackage, numberLessons: e.target.value })}
                            />
                            <label style={{display: 'flex', alignItems: 'center', gap: '10px', fontSize: '16px', marginBottom: 6}}>
                                Online:
                                <input
                                    type="checkbox"
                                    checked={selectedPackage.online}
                                    onChange={(e) => setSelectedPackage({ ...selectedPackage, online: e.target.checked })}
                                />
                            </label>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button
                                    sx={{ px: '20px', py: '10px', borderRadius: '15px', backgroundColor: theme.palette.achord_purple.main }} 
                                    variant="contained"
                                    startIcon={<ArrowBackIcon />}
                                    onClick={() => { setSelectedPackage(null); }}
                                >
                                    <Box sx={{ fontWeight: 'bold' }}>Go Back</Box>
                                </Button>
            
                                <Button
                                    sx={{ px: '20px', py: '10px', borderRadius: '15px', backgroundColor: '#36C752' }} 
                                    variant="contained"
                                    endIcon={<SaveIcon />}
                                    onClick={handleUpdatePackage}
                                >
                                    <Box sx={{ fontWeight: 'bold' }}>Save Changes</Box>
                                </Button>
                            </Box>
                        </Stack>
                    </form>
                ) : (
                    // Display list of packages
                    packages.map((pkg) => (
                        // <PackageCard Package={pkg} handleEdit={handleEdit} />
                        <div key={pkg.id}>
                            <ThemeProvider theme={theme}>
                                <ListItem
                                    sx={{ my: 1.5, py: 1.5, px: 2, borderRadius: '12px', border: 1, borderColor: '#C4C4FF', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
                                    secondaryAction={
                                        <Stack spacing={1}>
                                            <Button
                                                sx={{ px: '20px', py: '10px', borderRadius: '15px' }}
                                                variant="contained"
                                                color="achord_purple"
                                                endIcon={<EditIcon />}
                                                onClick={() => handleEdit(pkg)}
                                            >
                                                <Box sx={{ fontWeight: 'bold' }}>Edit</Box>
                                            </Button>
                                            <Button
                                                sx={{ px: '20px', py: '10px', borderRadius: '15px' }}
                                                variant="contained"
                                                color="achord_purple"
                                                endIcon={<DeleteIcon />}
                                                onClick={() => handleDeletePackage(pkg.id)}
                                            >
                                                <Box sx={{ fontWeight: 'bold' }}>Delete</Box>
                                            </Button>
                                        </Stack>


                                    }
                                >
                                    <ListItemText
                                        primary={
                                            <Box sx={{ fontWeight: 'bold' }}>{pkg.name}</Box>
                                        }
                                        secondary={
                                            <List disablePadding dense>
                                                <ListItem disablePadding>
                                                    <ListItemText sx={{ m: 0 }}>
                                                        <Typography variant="body2" color="text.secondary">
                                                            {"Instrument: " + pkg.instrument}
                                                        </Typography>
                                                    </ListItemText>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemText sx={{ m: 0 }}>
                                                        <Typography variant="body2" color="text.secondary">
                                                            {"Number of Lessons: " + pkg.numberLessons}
                                                        </Typography>
                                                    </ListItemText>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemText sx={{ m: 0 }}>
                                                        <Typography variant="body2" color="text.secondary">
                                                            {"Duration: " + pkg.duration}
                                                        </Typography>
                                                    </ListItemText>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemText sx={{ m: 0 }}>
                                                        <Typography variant="body2" color="text.secondary">
                                                            {"Location/Zoom Link: " + pkg.location}
                                                        </Typography>
                                                    </ListItemText>
                                                </ListItem>
                                            </List>
                                        }
                                    />
                                </ListItem>
                            </ThemeProvider>
                        </div>
                    ))
                )
                }
            </DialogContent >
            <DialogActions sx={{justifyContent: 'center'}}>
                {!selectedPackage && !newPackage &&                     <Button
                    sx={{ px: '20px', py: '10px', borderRadius: '15px', backgroundColor: '#36C752'}} variant="contained" endIcon={<ControlPointIcon />}
                    onClick={handleNewPackage}
                >
                    <Box sx={{ fontWeight: 'bold' }}>Create New Package</Box>
                </Button>}
            </DialogActions>
        </Dialog >
    );
}

export default PackageDialog;
