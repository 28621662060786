import React from 'react';
import { Button } from '@mui/material';
import ControlPointIcon from '@mui/icons-material/ControlPoint';

function AnalyticsAddButton({ selectedTab, addRow }) {

    const displayTabName = selectedTab === 'Expenses' ? 'Expense' : selectedTab === 'Students' ? 'Student' : selectedTab;

    const handleClick = () => {
    addRow(); // Add a new row
    };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        endIcon={<ControlPointIcon />}
        onClick={handleClick}
        sx={{
          backgroundColor: '#6C5DD3',
          borderRadius: "15px",
          px: "20px",
          py: "10px",
          fontSize: 14,
          fontWeight: 'bold',
          fontFamily: 'poppins',
          color: 'white',
        }}
      >
        Add {displayTabName}
      </Button>
    </div>
  );
}

export default AnalyticsAddButton;
