import React, { useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import ConversationList from '../messaging_components/ConversationList';
import CurrentConversationInterface from '../messaging_components/CurrentConversationInterface';

function Messaging() {
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [conversationsChangeTrigger, setConversationsChangeTrigger] = useState(0);

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'flex-start',  
      padding: '3rem',
      fontFamily: 'Poppins, sans-serif',
    }}>
      <Box sx={{
        width: '40%',
        height: 'calc(100vh - 100px)',
        overflowY: 'scroll',
        marginRight: '7%'
      }}>
        <ConversationList setSelectedConversation={setSelectedConversation} trigger={conversationsChangeTrigger} />
      </Box>
      <Box sx={{
        width: '53%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        {selectedConversation ?
          <CurrentConversationInterface 
            conversation={selectedConversation} 
            onConversationDelete={() => {
              setSelectedConversation(null);
              setConversationsChangeTrigger(conversationsChangeTrigger + 1);
            }} 
            onConversationChange={() => {
              setConversationsChangeTrigger(conversationsChangeTrigger + 1);
            }} 
          />          
          :
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'black', fontFamily: 'Poppins' }}>
          Create or select a conversation to start messaging!
          </Typography>        
          }
      </Box>
    </Box>
  );
}

export default Messaging;
