import React, { useState, useContext, useEffect } from 'react';
import { TextField, Button, Box, Typography, Select, MenuItem, FormControl, Slider, Grid } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'; 
import { ExploreContext } from '../../../contexts/ExploreContext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
  },
  palette: {
    primary: {
      main: '#6C5DD3',
    },
  },
});

const learningModes = ['Online', 'In Person', 'Both/Either'];
const lessonTypes = ['Group', 'Solo'];

function OnboardingStudent5() {
  const [onboardingData, setOnboardingData] = useState({
    learningMode: "",
    lessonType: "",
    learningStyle: { structured: 5, theory: 5, performance: 5},
    practiceHours: 0,
    teachingMethods: "",
    guidancePreference: "",
    typicalLesson: "",
    specialConsiderations: "",
  });

  const navigate = useNavigate();
  const { updateOnboardingData, saveOnboardingData } = useContext(ExploreContext); 
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsValid(
      onboardingData.learningMode !== "" &&
      onboardingData.learningStyle.structured !== -1 &&
      onboardingData.learningStyle.theory !== -1 &&
      onboardingData.learningStyle.performance !== -1
    );
  }, [onboardingData]); 

  const handleChange = (event) => {
    event.preventDefault();
    setOnboardingData({
      ...onboardingData,
      [event.target.name]: event.target.value,
    });
  };
  
  const handleSliderChange = (event, newValue, name) => {
    event.preventDefault();
    setOnboardingData({
      ...onboardingData,
      learningStyle: {
        ...onboardingData.learningStyle,
        [name]: newValue,
      },
    });
  };
  


  const handleSubmit = async (event) => {
    event.preventDefault();
    const updatedData = await updateOnboardingData(onboardingData);
    await saveOnboardingData(updatedData);
    navigate('/explore'); 
  };
  

  const handleBack = () => {
    navigate('/onboardingStudent4');
  };

  return (
    <ThemeProvider theme={theme}>
      <Box 
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
        className="login-container"
        sx={{ marginBottom: '100px' }} 
      >
        <Box 
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          className="form-container"
          sx={{mt: 4}}
        >
          <Typography variant="h4" className="login-heading" gutterBottom>Learning Preferences & Conditions</Typography>
  
          <form onSubmit={handleSubmit} className="login-form" style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 10}}>
            
            <Typography variant="body1" fontWeight="semiBold" fontSize={20} id="learning-mode-label">Would you prefer learning online or in person? (Required)</Typography>
            <FormControl required fullWidth margin="normal">
              <Select
                labelId="learning-mode-label"
                id="learningMode"
                name="learningMode"
                value={onboardingData.learningMode}
                onChange={handleChange}
              >
                {learningModes.map((mode) => (
                  <MenuItem value={mode} key={mode}>{mode}</MenuItem>
                ))}
              </Select>
            </FormControl>
  
            <Typography variant="body1" fontWeight="semiBold" fontSize={20} id="lesson-type-label">Do you prefer group lessons or solo lessons? (Optional)</Typography>
            <FormControl fullWidth margin="normal">
              <Select
                labelId="lesson-type-label"
                id="lessonType"
                name="lessonType"
                value={onboardingData.lessonType}
                onChange={handleChange}
              >
                {lessonTypes.map((type) => (
                  <MenuItem value={type} key={type}>{type}</MenuItem>
                ))}
              </Select>
            </FormControl>
  
            <Typography variant="body1" fontWeight="semiBold" fontSize={20} id="lesson-type-label" sx={{mb: 2, mt: 4}}>Adjust the Sliders Based on the style of your lessons (Required)</Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Grid item><Typography variant="body1" fontWeight="semiBold" fontSize={20}>Structured</Typography></Grid>
                  <Grid item><Typography variant="body1" fontWeight="semiBold" fontSize={20}>Flexible</Typography></Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Slider
                  required
                  value={onboardingData.learningStyle.structured}
                  onChange={(e, value) => handleSliderChange(e, value, 'structured')}
                  aria-labelledby="continuous-slider"
                  valueLabelDisplay="auto"
                  min={0}
                  max={10}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Grid item><Typography variant="body1" fontWeight="semiBold" fontSize={20}>Theory</Typography></Grid>
                  <Grid item><Typography variant="body1" fontWeight="semiBold" fontSize={20}>Practical</Typography></Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Slider
                  required
                  value={onboardingData.learningStyle.theory}
                  onChange={(e, value) => handleSliderChange(e, value, 'theory')}
                  aria-labelledby="continuous-slider"
                  valueLabelDisplay="auto"
                  min={0}
                  max={10}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Grid item><Typography variant="body1" fontWeight="semiBold" fontSize={20}>Competitive</Typography></Grid>
                  <Grid item><Typography variant="body1" fontWeight="semiBold" fontSize={20}>Fun</Typography></Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Slider
                  required
                  value={onboardingData.learningStyle.performance}
                  onChange={(e, value) => handleSliderChange(e, value, 'performance')}
                  aria-labelledby="continuous-slider"
                  valueLabelDisplay="auto"
                  min={0}
                  max={10}
                />
              </Grid>
            </Grid>

  
  
            <Typography variant="body1" fontWeight="semiBold" fontSize={20} id="practice-hours-label">How many hours per week would you like to practice? (Optional)</Typography>
            <TextField
              aria-labelledby="practice-hours-label"
              variant="outlined"
              className="login-input"
              margin="normal"
              name="practiceHours"
              value={onboardingData.practiceHours}
              onChange={handleChange}
              type="number"
              fullWidth
              multiline
              rows={3}
            />
  
            <Typography variant="body1" fontWeight="semiBold" fontSize={20} id="teaching-methods-label">What kind of teaching methods do you find most effective? (Optional)</Typography>
            <TextField
              aria-labelledby="teaching-methods-label"
              variant="outlined"
              className="login-input"
              margin="normal"
              name="teachingMethods"
              value={onboardingData.teachingMethods}
              onChange={handleChange}
              fullWidth
              multiline
              rows={3}
            />
  
            <Typography variant="body1" fontWeight="semiBold" fontSize={20} id="guidance-preference-label">How do you like to be corrected or guided during lessons? (Optional)</Typography>
            <TextField
              aria-labelledby="guidance-preference-label"
              variant="outlined"
              className="login-input"
              margin="normal"
              name="guidancePreference"
              value={onboardingData.guidancePreference}
              onChange={handleChange}
              fullWidth
              multiline
              rows={3}
            />
  
            <Typography variant="body1" fontWeight="semiBold" fontSize={20} id="typical-lesson-label">Could you describe a typical lesson you would enjoy? (Optional)</Typography>
            <TextField
              aria-labelledby="typical-lesson-label"
              variant="outlined"
              className="login-input"
              margin="normal"
              name="typicalLesson"
              value={onboardingData.typicalLesson}
              onChange={handleChange}
              fullWidth
              multiline
              rows={3}
            />
  
            <Typography variant="body1" fontWeight="semiBold" fontSize={20} id="special-considerations-label">Do you have any special considerations the teacher should be aware of? (Optional)</Typography>
            <TextField
              aria-labelledby="special-considerations-label"
              variant="outlined"
              className="login-input"
              margin="normal"
              name="specialConsiderations"
              value={onboardingData.specialConsiderations}
              onChange={handleChange}
              fullWidth
              multiline
              rows={3}
            />
  
            <Box display="flex" justifyContent="space-between" marginY={2} sx={{width: '100%'}}>
              <Button
                sx={{ px: '20px', py: '10px', borderRadius: '15px', backgroundColor: '#6C5DD3' }} 
                variant="contained"
                endIcon={<ArrowBackIcon />}
                onClick={handleBack}
              >
                <Box sx={{ fontWeight: 'bold' }}>Back</Box>
              </Button>
              <Button
                type="submit"
                sx={{ px: '20px', py: '10px', borderRadius: '15px', backgroundColor: '#6C5DD3' }} 
                variant="contained"
                disabled={!isValid}
              >
                <Box sx={{ fontWeight: 'bold' }}>Finish</Box>
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </ThemeProvider>
  );  
}

export default OnboardingStudent5;
