import React, { useState, useEffect } from 'react';
import AnalyticsAddButton from '../analytics_components/AnalyticsAddButton';
import AnalyticsDataTable from '../analytics_components/AnalyticsDataTable';
import AnalyticsTabSelection from '../analytics_components/AnalyticsTabSelection';
import AnalyticsTimeFrameSelection from '../analytics_components/AnalyticsTimeFrameSelection';
import { Box, Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { AnalyticsContext } from '../../contexts/AnalyticsContext';


function Analytics() {
  const [selectedTab, setSelectedTab] = useState('Students'); // Default tab
  const [data, setData] = useState(getDummyData(selectedTab));
  const [editingRows, setEditingRows] = useState([]);

  useEffect(() => {
    setData(getDummyData(selectedTab));
  }, [selectedTab]);

  const addRow = () => {
    const newRow = getEmptyRow(selectedTab);
    setData([...data, newRow]);
    setEditingRows([...editingRows, true]);
  };

  const handleSaveChanges = () => {
    // Code to save changes globally (e.g., update backend database)
  };

  return (
    <Box sx={{ px: '80px', py: 10 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <AnalyticsTabSelection selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        {selectedTab !== 'Students' && <AnalyticsTimeFrameSelection />}
        <AnalyticsAddButton selectedTab={selectedTab} addRow={addRow} />
      </Box>
      <AnalyticsDataTable
        selectedTab={selectedTab}
        data={data}
        setData={setData}
        editingRows={editingRows}
        setEditingRows={setEditingRows}
      />
      <Box sx={{ justifyContent: 'flex-end', width: '100%', display: 'flex', mt: 5 }}>
        <Button
          variant="contained"
          color="primary"
          endIcon={<SaveIcon />}
          onClick={handleSaveChanges}
          sx={{
            backgroundColor: '#36C752',
            borderRadius: '15px',
            px: '20px',
            py: '10px',
            fontSize: 14,
            fontWeight: 'bold',
            fontFamily: 'poppins',
            color: 'white',
          }}
        >
          Save Changes
        </Button>
      </Box>
    </Box>
  );
}


// Function to create a new dummy row based on the selected tab
const getEmptyRow = (selectedTab) => {
    switch (selectedTab) {
      case 'Students':
        return { name: '', booked: 0, rescheduled: 0, cancelled: 0, firstLesson: '' };
      case 'Income':
        return { type: '', source: '', amount: 0, date: '', paymentMethod: '' };
      case 'Expenses':
        return { type: '', vendor: '', amount: 0, date: '', paymentMethod: '' };
      default:
        return {};
    }
  };

  // Dummy data based on the selected tab
const getDummyData = (selectedTab) => {
    switch (selectedTab) {
      case 'Students':
        return [
          { name: 'John Doe', booked: 10, rescheduled: 2, cancelled: 1, firstLesson: '2021-08-01' },
          { name: 'Jane Smith', booked: 20, rescheduled: 1, cancelled: 0, firstLesson: '2021-07-15' },
          { name: 'Robert Johnson', booked: 16, rescheduled: 3, cancelled: 2, firstLesson: '2021-06-20' },
          { name: 'Emily Davis', booked: 6, rescheduled: 4, cancelled: 0, firstLesson: '2021-05-10' },
          // Add more as needed
        ];
      case 'Income':
        return [
          { type: 'Lesson', source: 'John Doe', amount: 100, date: '2021-08-01', paymentMethod: 'Credit Card' },
          { type: 'Lesson', source: 'Jane Smith', amount: 80, date: '2021-07-15', paymentMethod: 'Cash' },
          { type: 'Lesson', source: 'Robert Johnson', amount: 50, date: '2021-06-20', paymentMethod: 'PayPal' },
          { type: 'Lesson', source: 'Emily Davis', amount: 120, date: '2021-05-10', paymentMethod: 'Credit Card' },
          // Add more as needed
        ];
      case 'Expenses':
        return [
          { type: 'Rent', vendor: 'Landlord', amount: 1000, date: '2021-08-01', paymentMethod: 'Bank Transfer' },
          { type: 'Utilities', vendor: 'Utility Company', amount: 200, date: '2021-08-01', paymentMethod: 'Credit Card' },
          { type: 'Supplies', vendor: 'Music Store', amount: 150, date: '2021-07-15', paymentMethod: 'Cash' },
          { type: 'Marketing', vendor: 'Ad Agency', amount: 300, date: '2021-06-20', paymentMethod: 'Bank Transfer' },
          // Add more as needed
        ];
      default:
        return [];
    }
  };

  export default Analytics;
