import { useEffect, useRef } from 'react';

function useStripePricingTable(stripePublishableKey, pricingTableId) {
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      ref.current.innerHTML = `<stripe-pricing-table pricing-table-id="${pricingTableId}" publishable-key="${stripePublishableKey}"></stripe-pricing-table>`;
    }
  }, [stripePublishableKey, pricingTableId]);

  return ref;
}

export default useStripePricingTable;
