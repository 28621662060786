import React, { useState, useContext, useEffect } from 'react';
import { TextField, Button, Box, Typography, Select, MenuItem, FormControl, Slider, Grid } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'; 
import { ExploreContext } from '../../../contexts/ExploreContext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
  },
  palette: {
    primary: {
      main: '#6C5DD3',
    },
  },
});

const learningModes = ['In Person', 'Online', 'Both/Either'];
const lessonTypes = ['Group', 'Solo'];

function OnboardingTeacher4() {
  const [onboardingData, setOnboardingData] = useState({
    teachingMode: "",
    lessonType: "",
    teachingStyle: { structured: 5, theory: 5, performance: 5 },
    practiceHours: 0,
    practiceHandling: "",
    typicalStudent: "",
    specialConsiderationsExperience: "",
  });

  const navigate = useNavigate();
  const { updateOnboardingData, saveOnboardingData } = useContext(ExploreContext);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsValid(
      onboardingData.teachingMode !== "" &&
      onboardingData.teachingStyle.structured !== -1 &&
      onboardingData.teachingStyle.theory !== -1 &&
      onboardingData.teachingStyle.performance !== -1
    );
  }, [onboardingData]); 

  const handleChange = (event) => {
    event.preventDefault();
    setOnboardingData({
      ...onboardingData,
      [event.target.name]: event.target.value,
    });
  };
  
  const handleSliderChange = (event, newValue, name) => {
    event.preventDefault();
    setOnboardingData({
      ...onboardingData,
      teachingStyle: {
        ...onboardingData.teachingStyle,
        [name]: newValue,
      },
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const updatedData = await updateOnboardingData(onboardingData);
    await saveOnboardingData(updatedData);
    navigate('/scheduling'); 
  };
  
  const handleBack = () => {
    navigate('/onboardingTeacher3');
  };
  
  return (
    <ThemeProvider theme={theme}>
      <Box 
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
        className="login-container"
        sx={{ marginBottom: '100px' }} 
      >
        <Box 
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          className="form-container"
          sx={{mt: 4}}
        >
          <Typography variant="h4" className="login-heading" gutterBottom sx={{mb: 4}}>Teaching Style & Conditions</Typography>

          <form onSubmit={handleSubmit} className="login-form" style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 10}}>
            <Typography variant="body1" fontWeight="semiBold" fontSize={20} id="teaching-mode-label">Online vs. In Person (Required)</Typography>
            <FormControl fullWidth margin="normal">
              <Select
                labelId="teaching-mode-label"
                id="teachingMode"
                name="teachingMode"
                value={onboardingData.teachingMode}
                onChange={handleChange}
              >
                {learningModes.map((mode) => (
                  <MenuItem value={mode} key={mode}>{mode}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <Typography variant="body1" fontWeight="semiBold" fontSize={20} id="lesson-type-label">Group vs. Solo (Optional)</Typography>
            <FormControl fullWidth margin="normal">
              <Select
                labelId="lesson-type-label"
                id="lessonType"
                name="lessonType"
                value={onboardingData.lessonType}
                onChange={handleChange}
              >
                {lessonTypes.map((type) => (
                  <MenuItem value={type} key={type}>{type}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <Typography variant="body1" fontWeight="semiBold" fontSize={20} id="lesson-type-label" sx={{mb: 2, mt: 4}}>Adjust the Sliders Based on the style of your lessons (Required)</Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Grid item><Typography variant="body1" fontWeight="semiBold" fontSize={20}>Structured</Typography></Grid>
                  <Grid item><Typography variant="body1" fontWeight="semiBold" fontSize={20}>Flexible</Typography></Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Slider
                  required
                  value={onboardingData.teachingStyle.structured}
                  onChange={(e, value) => handleSliderChange(e, value, 'structured')}
                  aria-labelledby="continuous-slider"
                  valueLabelDisplay="auto"
                  min={0}
                  max={10}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Grid item><Typography variant="body1" fontWeight="semiBold" fontSize={20}>Theory</Typography></Grid>
                  <Grid item><Typography variant="body1" fontWeight="semiBold" fontSize={20}>Practical</Typography></Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Slider
                  required
                  value={onboardingData.teachingStyle.theory}
                  onChange={(e, value) => handleSliderChange(e, value, 'theory')}
                  aria-labelledby="continuous-slider"
                  valueLabelDisplay="auto"
                  min={0}
                  max={10}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Grid item><Typography variant="body1" fontWeight="semiBold" fontSize={20}>Performance</Typography></Grid>
                  <Grid item><Typography variant="body1" fontWeight="semiBold" fontSize={20}>Fun</Typography></Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Slider
                  required
                  value={onboardingData.teachingStyle.performance}
                  onChange={(e, value) => handleSliderChange(e, value, 'performance')}
                  aria-labelledby="continuous-slider"
                  valueLabelDisplay="auto"
                  min={0}
                  max={10}
                />
              </Grid>
            </Grid>



            <Typography variant="body1" fontWeight="semiBold" fontSize={20} id="practice-hours-label">How many hours do you require students practice per week? (Optional)</Typography>
            <TextField
              aria-labelledby="practice-hours-label"
              variant="outlined"
              className="login-input"
              margin="normal"
              name="practiceHours"
              value={onboardingData.practiceHours}
              onChange={handleChange}
              fullWidth
              multiline
              rows={3}
            />

            <Typography variant="body1" fontWeight="semiBold" fontSize={20} id="practice-handling-label">How do you handle a student who is not practicing as required? (Optional)</Typography>
            <TextField
              aria-labelledby="practice-handling-label"
              variant="outlined"
              className="login-input"
              margin="normal"
              name="practiceHandling"
              value={onboardingData.practiceHandling}
              onChange={handleChange}
              fullWidth
              multiline
              rows={3}
            />

            <Typography variant="body1" fontWeight="semiBold" fontSize={20} id="typical-student-label">What is your typical student like? (Optional)</Typography>
            <TextField
              aria-labelledby="typical-student-label"
              variant="outlined"
              className="login-input"
              margin="normal"
              name="typicalStudent"
              value={onboardingData.typicalStudent}
              onChange={handleChange}
              fullWidth
              multiline
              rows={3}
            />

            <Typography variant="body1" fontWeight="semiBold" fontSize={20} id="special-considerations-experience-label">What is your experience teaching students with special considerations? (Optional)</Typography>
            <TextField
              aria-labelledby="special-considerations-experience-label"
              variant="outlined"
              className="login-input"
              margin="normal"
              name="specialConsiderationsExperience"
              value={onboardingData.specialConsiderationsExperience}
              onChange={handleChange}
              fullWidth
              multiline
              rows={3}
            />

            <Box display="flex" justifyContent="space-between" marginY={2} sx={{width: '100%'}}>
              <Button
                sx={{ px: '20px', py: '10px', borderRadius: '15px', backgroundColor: '#6C5DD3' }} 
                variant="contained"
                endIcon={<ArrowBackIcon />}
                onClick={handleBack}
              >
                <Box sx={{ fontWeight: 'bold' }}>Back</Box>
              </Button>
              <Button
                type="submit"
                sx={{ px: '20px', py: '10px', borderRadius: '15px', backgroundColor: '#6C5DD3' }} 
                variant="contained"
                disabled={!isValid}
              >
                <Box sx={{ fontWeight: 'bold' }}>Finish</Box>
              </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </ThemeProvider>
      );
  }
  
  export default OnboardingTeacher4;
  
