import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './components/NavBar';
import Signup from './components/pages/Onboarding/Signup';
import MyProfile from './components/pages/MyProfile';
import Login from './components/pages/Onboarding/Login';
import Scheduling from './components/pages/Scheduling';
import Messaging from './components/pages/Messaging';
import AuthProvider from "./contexts/AuthContext"
import ExploreProvider from "./contexts/ExploreContext"
import EnterTeacherCode from "./components/pages/EnterTeacherCode"
import EditProfile from "./components/pages/EditProfile"
import MobileView from './components/MobileView';
import Footer from './components/Footer';
import About from './components/pages/About'
import PrivacyPolicy from './components/pages/PrivacyPolicy'
import DisplayTeacherCode from './components/pages/DisplayTeacherCode';
import OnboardingStudent1 from './components/pages/Onboarding/OnboardingStudent1';
import OnboardingStudent2 from './components/pages/Onboarding/OnboardingStudent2';
import OnboardingStudent3 from './components/pages/Onboarding/OnboardingStudent3';
import OnboardingStudent4 from './components/pages/Onboarding/OnboardingStudent4';
import OnboardingStudent5 from './components/pages/Onboarding/OnboardingStudent5';
import OnboardingTeacher1 from './components/pages/Onboarding/OnboardingTeacher1';
import OnboardingTeacher2 from './components/pages/Onboarding/OnboardingTeacher2';
import OnboardingTeacher3 from './components/pages/Onboarding/OnboardingTeacher3';
import OnboardingTeacher4 from './components/pages/Onboarding/OnboardingTeacher4';
import Explore from './components/pages/Explore';
import Analytics from './components/pages/Analytics';
import ViewTeacherProfile from './components/pages/ViewTeacherProfile';
import ViewProfile from './components/pages/ViewProfile';
import Landing from './components/pages/Landing';
import TempLanding from './components/pages/TempLanding'
import Subscription from './components/pages/Subscription';
import Contact from './components/pages/Contact';
import AddProfilePic from './components/pages/Onboarding/AddProfilePic';



function App() {
  return (
    <AuthProvider>
      <ExploreProvider>
        <MobileView>
          <Router>
            <NavBar />
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/about" element={<About />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/addProfilePic" element={<AddProfilePic />} />
              <Route path="/onboardingTeacher1" element={<OnboardingTeacher1 />} />
              <Route path="/onboardingTeacher2" element={<OnboardingTeacher2 />} />
              <Route path="/onboardingTeacher3" element={<OnboardingTeacher3 />} />
              <Route path="/onboardingTeacher4" element={<OnboardingTeacher4 />} />
              <Route path="/onboardingStudent1" element={<OnboardingStudent1 />} />
              <Route path="/onboardingStudent2" element={<OnboardingStudent2 />} />
              <Route path="/onboardingStudent3" element={<OnboardingStudent3 />} />
              <Route path="/onboardingStudent4" element={<OnboardingStudent4 />} />
              <Route path="/onboardingStudent5" element={<OnboardingStudent5 />} />
              <Route path="/subscription" element={<Subscription />} />
              <Route path="/myProfile" element={<MyProfile />} />
              <Route path="/editProfile" element={<EditProfile />} />
              <Route path="/teacher-profile/:teacherId" element={<ViewTeacherProfile />} />
              <Route path="/viewProfile" element={<ViewProfile />} />
              <Route path="/scheduling" element={<Scheduling />} />
              <Route path="/messaging" element={<Messaging />} />
              <Route path="/explore" element={<Explore />} />
              <Route path="/analytics" element={<Analytics />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/enterTeacherCode" element={<EnterTeacherCode />} />
              <Route path="/displayTeacherCode" element={<DisplayTeacherCode />} />

            </Routes>
            <Footer />
          </Router>
        </MobileView>
      </ExploreProvider>
    </AuthProvider>
  );
}

export default App;
