import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

function AnalyticsDataTable({ selectedTab, data: initialData }) {
  const [data, setData] = useState(initialData);
  const [editingRowIndex, setEditingRowIndex] = useState(-1);

  const formatValue = (value, isCurrency = false) => {
    if (value === 0 || value === null || value === "") return '-';
    return isCurrency ? `$${value}` : value;
  };  

  const getColumnTitles = () => {
    switch (selectedTab) {
      case 'Students':
        return ['Student Name', 'Lessons Booked', 'Lessons Rescheduled', 'Lessons Cancelled', 'First Lesson Date'];
      case 'Income':
        return ['Type', 'Source', 'Amount', 'Date', 'Payment Method'];
      case 'Expenses':
        return ['Type', 'Vendor', 'Amount', 'Date', 'Payment Method'];
      default:
        return [];
    }
  };

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  const getTotalRow = () => {
    const totalRow = {};

    Object.keys(data[0]).forEach(key => {
      if (typeof data[0][key] === 'number') {
        totalRow[key] = 0;
      }
    });

    data.forEach(record => {
      Object.keys(record).forEach(key => {
        if (typeof record[key] === 'number') {
          totalRow[key] += record[key];
        }
      });
    });

    return totalRow;
  };

  const handleEditClick = (index) => {
    setEditingRowIndex(index === editingRowIndex ? -1 : index);
  };

  const handleSave = (index) => {
    const updatedRow = {};
    const rowElement = document.getElementById(`row-${index}`);
    const inputElements = rowElement.querySelectorAll('input');

    Object.keys(data[index]).forEach((key, cellIndex) => {
      updatedRow[key] = inputElements[cellIndex].value;
    });

    const newData = [...data];
    newData[index] = updatedRow;
    setData(newData);
    handleEditClick(index); // Turn off edit mode
  };

  const totalRow = getTotalRow();

  return (
    <Table>
      <TableHead>
        <TableRow style={{ borderBottom: '2px solid black' }}>
          {getColumnTitles().map((title) => (
            <TableCell key={title} style={{ fontWeight: '600', fontSize: 20, fontFamily: 'poppins', paddingLeft: 0 }} align="left">
              {title}
            </TableCell>
          ))}
          <TableCell></TableCell> {/* For edit button */}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row, index) => (
          <TableRow key={index} id={`row-${index}`} style={{ borderBottom: '1.5px solid black' }}>
            {index === editingRowIndex ? (
              Object.keys(row).map((key, cellIndex) => (
                <TableCell key={cellIndex} style={{ fontWeight: '600', fontSize: 20, fontFamily: 'poppins', paddingLeft: 0 }} align="left">
                  <input type="text" defaultValue={row[key]} />
                </TableCell>
              ))
            ) : (
                Object.values(row).map((cell, cellIndex) => (
                    <TableCell key={cellIndex} style={{ fontWeight: '600', fontSize: 20, fontFamily: 'poppins', paddingLeft: 0 }} align="left">
                      {formatValue(cell, cellIndex === 2 && (selectedTab === 'Income' || selectedTab === 'Expenses'))}
                    </TableCell>
                  ))                  
            )}
            <TableCell align="right">
              <IconButton
                onClick={() => {
                  index === editingRowIndex ? handleSave(index) : handleEditClick(index);
                }}
              >
                {index === editingRowIndex ? <SaveIcon /> : <EditIcon />}
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
        <TableRow style={{ borderBottom: 'none' }}>
          <TableCell style={{ fontWeight: '600', fontSize: 20, fontFamily: 'poppins', paddingLeft: 0 }} align="left" colSpan={1}>
            TOTAL
          </TableCell>
          {getColumnTitles().slice(1).map((_, cellIndex) => (
            <TableCell key={cellIndex} style={{ fontWeight: '600', fontSize: 20, fontFamily: 'poppins', paddingLeft: 0 }} align="left">
                {formatValue(totalRow[Object.keys(data[0])[cellIndex + 1]], (selectedTab === 'Income' || selectedTab === 'Expenses') && cellIndex === 1)}
            </TableCell>
            ))}
          <TableCell></TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

export default AnalyticsDataTable;
