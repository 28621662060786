import React, {useContext} from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { SchedulingContext } from "../../contexts/SchedulingContext";
import "../../Global.css"
import { AuthContext } from '../../contexts/AuthContext';

const localizer = momentLocalizer(moment)

const SchedulingCalendar = () => {
    const { appointments } = useContext(SchedulingContext);
    const { userProfile } = useContext(AuthContext);

    const events = appointments.map(appointment => {
        // calculate the end time by adding the duration in minutes to the start time
        const endTime = new Date((appointment.time.seconds * 1000) + (appointment.duration * 60 * 1000)); // convert duration to milliseconds

        return {
            title: userProfile.userType === 'student' ? appointment.teacher.name : appointment.student.name, // or some other attribute of the appointment
            start: new Date(appointment.time.seconds * 1000),
            end: endTime, // use calculated end time
            allDay: false
        };
    });

    return (
        <div style={{height: '800px'}}>
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
            />
        </div>
    )
}

export default SchedulingCalendar;


/*
const calendarTheme = createTheme({
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                width: '500px'
            },
        },
       
    },
});

function SchedulingCalendar() {
    const date = new Date();
    const [value, setValue] = React.useState(dayjs(date))
    return (
        <ThemeProvider theme={calendarTheme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar 
            value={value}
            onChange={(newValue) => setValue(newValue)}

            renderInput={(params) => {<TextField {...params}  /> }}
            //className="schedulingCalendar"
            sx={{width: '100%', height: '100%', minHeight: '800px', maxHeight: '800px'}}
            slots= {{ day : customPickerDay
            }}
            slotProps={{
                day: {
                    selectedDay: value,
                    task: userTasks
                  }
            }}
            />

             
        </LocalizationProvider>
        </ThemeProvider>
    )
    
}
*/