import React from 'react';
import { Typography, IconButton, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function AnalyticsTabSelection({ selectedTab, setSelectedTab }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    handleClose();
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Typography fontWeight="bold" sx={{fontFamily: "poppins", fontSize: 24}}>
        {selectedTab}
      </Typography>
      <IconButton onClick={handleClick}>
        <KeyboardArrowDownIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleTabChange('Students')}>Students</MenuItem>
        <MenuItem onClick={() => handleTabChange('Income')}>Income</MenuItem>
        <MenuItem onClick={() => handleTabChange('Expenses')}>Expenses</MenuItem>
      </Menu>
    </div>
  );
}

export default AnalyticsTabSelection;
