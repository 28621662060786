import React from 'react';
import { Container, Typography, Link } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container sx={{py: 10}}>
      <Typography variant="h4" gutterBottom>
        Privacy Notice
      </Typography>
      <Typography variant="body1" paragraph>
        ACHORD collects data from its users in order to improve services and ensure the security of the app. We also collect user-provided data in order to create functional user profiles. Please see our complete Privacy Policy for more information.
      </Typography>

      <Typography variant="h5" gutterBottom>
        PRIVACY POLICY
      </Typography>

      <Typography variant="h6" gutterBottom>
        1. What data does ACHORD collect?
      </Typography>
      <Typography variant="body1" paragraph>
        ACHORD collects various types of information from our users...
        User-Provided Information: When you create an account with us, we collect your name and email address. You can also choose to provide additional information in your profile, such as links to your social media profiles and answers to personal questions. If you use our scheduling and booking features, we collect the details you provide about your schedules and bookings. Furthermore, if you use our messaging service, we store the content of your messages.
        Automatically-Collected Information: ACHORD is built on Firebase, which automatically collects certain information about your device and your use of the app. This can include your IP address, device information (such as device ID, model, and operating system), and data about your use of the app.
      </Typography>

      <Typography variant="h6" gutterBottom>
        2. Why do we collect this data?
      </Typography>
      <Typography variant="body1" paragraph>
        We collect this data for several purposes.
        User-Provided Information: We use this information to create and manage your account, to form matches between students and teachers, to enable the functionalities of the ACHORD (like scheduling and messaging), and to personalize your experience.
        Automatically-Collected Information: We use this data to understand how our users interact with the app, to identify any technical issues, to improve our services, and to ensure the security of our app.
      </Typography>

      <Typography variant="h6" gutterBottom>
        3. How do we collect this data?
      </Typography>
      <Typography variant="body1" paragraph>
        User-Provided Information: We collect this information directly from you as you put it in the app.
        Automatically-Collected Information: This information is automatically collected by Firebase as you use ACHORD.
      </Typography>

      <Typography variant="h6" gutterBottom>
        4. Who has access to this data?
      </Typography>
      <Typography variant="body1" paragraph>
        Access to user data within our company is limited to company personnel who require the information to perform their job functions, such as customer support and technical operations. Firebase, as our backend service provider, also has access to this data. Please refer to <Link href="https://firebase.google.com/support/privacy" target="_blank" rel="noopener">Firebase's privacy policy</Link> for more details about their data handling practices.
        We may also disclose your information to law enforcement or other authorities if we are required to do so by law, or if we believe in good faith that such disclosure is necessary to comply with legal process, to protect our rights or property, or to protect the safety of our users or the public.
      </Typography>

      <Typography variant="h6" gutterBottom>
        5. How is your data protected?
      </Typography>
      <Typography variant="body1" paragraph>
        We take data security seriously. ACHORD is built on Firebase, which uses robust security measures to protect your data. These include the use of secure server infrastructure, encryption of data in transit, and optional Security Rules to help safeguard your data.
        Please note that no method of data transmission or storage can be 100% secure, so while we strive to protect your data, we cannot guarantee absolute security.
      </Typography>
    </Container>
  );
};

export default PrivacyPolicy;
