import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Typography, Avatar, Grid } from "@mui/material";
import { styled } from "@mui/system";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ProfileBackground from '../profile_components/ProfileBackground';
import ProfilePersonality from '../profile_components/ProfilePersonality';
import ProfilePreferences from '../profile_components/ProfilePreferences';
import { AuthContext } from '../../contexts/AuthContext';




const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(20),
  height: theme.spacing(20),
  margin: theme.spacing(1),
}));

const ViewProfile = () => { 
    const navigate = useNavigate();
    const { currentProfileViewingId, usersSavedTeachers, userProfile, usersStudents} = useContext(AuthContext);
    const [currentProfileViewing, setCurrentProfileViewing] = useState(null);

    useEffect(() => {
        if (!currentProfileViewingId) {
          return;
        }
    
        const userType = userProfile.userType;
    
        if (userType === 'student') {
          // Check if the teacher is saved in the user's saved teachers list
          const savedTeacher = usersSavedTeachers.find(teacher => teacher.id === currentProfileViewingId);
    
          if (!savedTeacher) {
            // If the teacher is not saved, navigate to /messaging
            navigate('/messaging');
          } else {
            // If the teacher is saved, set the currentProfileViewing to the saved teacher
            setCurrentProfileViewing(savedTeacher);
          }
        } else if (userType === 'teacher') {
          // Check if the student exists in the user's students list
          const student = usersStudents.find(student => student.id === currentProfileViewingId);
    
          if (!student) {
            // If the student does not exist, navigate to /messaging
            navigate('/messaging');
          } else {
            // If the student exists, set the currentProfileViewing to the student
            setCurrentProfileViewing(student);
          }
        }
      }, [currentProfileViewingId, usersSavedTeachers, usersStudents, userProfile, navigate]);


  if (!currentProfileViewing) {
    return <div>Loading...</div>; 
  }

  const renderLearningOrTeachingMode = () => {
    const mode = currentProfileViewing.onboardingData?.teachingMode;
    if(mode === 'Both/Either') {
      return 'Online | In Person';
    }
    return mode || '';
  };

  const renderYearsOfTeaching = () => {
    return currentProfileViewing.onboardingData?.teachingYears || '';
  };

  const renderInstruments = () => {
    const instruments = currentProfileViewing.onboardingData?.teachingInstruments;
    return instruments ? instruments.map(instrument => (
      <Typography key={instrument} variant="body1" sx={{ fontFamily: 'Poppins', fontWeight: 'regular', fontSize: 20 }}>{instrument}</Typography>
    )) : null;
  };

  const renderGenres = () => {
    const genres = currentProfileViewing.onboardingData?.teachingGenres;
    return genres ? genres.map(genre => (
      <Typography key={genre} variant="body1" sx={{ fontFamily: 'Poppins', fontWeight: 'regular', fontSize: 20 }}>{genre}</Typography>
    )) : null;
  };



  return (
    <Container maxWidth={false}>
      <Box sx={{minHeight: '90vh', py: 5, px: "80px"}}>
        <Grid container spacing={10}>
          <Grid item xs={12} sm={12} md={3}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1,  }}>
              <StyledAvatar src={currentProfileViewing.pictureURL || ''}></StyledAvatar>
              <Typography variant="h6" sx={{ fontFamily: 'Poppins', fontWeight: 'semibold', fontSize: 30 }}>{currentProfileViewing.fullName || ''}</Typography>
              <Typography variant="body1" sx={{ fontFamily: 'Poppins', fontWeight: 'medium', fontSize: 24, color: '#7F7FD4' }}>{renderLearningOrTeachingMode()}</Typography>
              <Typography variant="body1" sx={{ fontFamily: 'Poppins', fontWeight: 'medium', fontSize: 22, color: 'black', display: 'flex', alignItems: 'center' }}><AccessTimeIcon sx={{ marginRight: 1 }}/>{renderYearsOfTeaching()} years of experience</Typography>
              <Typography variant="h6" sx={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 24, color: 'black', mt: 2 }}>Instrument(s)</Typography>
              {renderInstruments()}
              <Typography variant="h6" sx={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 24, color: 'black', mt: 2 }}>Genre(s)</Typography>
              {renderGenres()}
              <div>
                <Typography variant="h6" sx={{ fontFamily: 'Poppins', fontWeight: 'semibold', fontSize: 20, mt: 2 }}>Teacher Code:</Typography>
                <Typography variant="body1">{currentProfileViewing.teacherCode || ''}</Typography>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
              <ProfileBackground dataProfile={currentProfileViewing} />
              <ProfilePersonality dataProfile={currentProfileViewing}/>
              <ProfilePreferences dataProfile={currentProfileViewing}/>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ViewProfile;
