import React, { useState } from 'react';
import { Container, Typography, Grid, TextField, Button } from '@mui/material';
import { getFirestore, collection, addDoc } from '../../services/firebase';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  const db = getFirestore();


  const handleSubmit = (event) => {
    event.preventDefault();
  
    const bugsCollectionRef = collection(db, 'bugReporting');
  
    addDoc(bugsCollectionRef, {
      name: name,
      email: email,
      subject: subject,
      description: description,
      timestamp: new Date()
    })
    .then(() => {
      alert('Your bug report has been submitted successfully. Thank you!');
      setName('');
      setEmail('');
      setSubject('');
      setDescription('');
    })
    .catch((error) => {
      alert(error.message);
    });
  };

  return (
    <Container sx={{py: 7}}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h4" gutterBottom style={{fontFamily: 'Poppins', fontSize: 30, fontWeight: 'bold'}}>
            Contact Us
          </Typography>
          <Typography variant="body1" paragraph style={{fontFamily: 'Poppins', fontSize: 20}} sx={{mt: 4}}>
            We are dedicated to ensuring your experience with ACHORD is the best it can be. If you encounter any issues, please let us know by filling out the form below.
          </Typography>
          <Typography variant="body1" paragraph style={{fontFamily: 'Poppins', fontSize: 20}}>
            Your feedback and reports are vital in helping us improve our platform and continue our mission of facilitating music learning and connection. We greatly appreciate your help and support.
          </Typography>
          <a href="mailto:max@achord.me" sx={{fontFamily: 'Poppins', fontSize: 20}}>Want to email the developers directly? Email: max@achord.me</a>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom style={{fontFamily: 'Poppins', fontSize: 20, fontWeight: 'bold'}}>
            Report a Bug
          </Typography>
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <TextField 
              fullWidth 
              margin="normal" 
              label="Your Name" 
              variant="outlined" 
              value={name} 
              onChange={(e) => setName(e.target.value)} 
            />
            <TextField 
              fullWidth 
              margin="normal" 
              label="Your Email" 
              variant="outlined" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
            />
            <TextField 
              fullWidth 
              margin="normal" 
              label="Subject" 
              variant="outlined" 
              value={subject} 
              onChange={(e) => setSubject(e.target.value)} 
            />
            <TextField 
              fullWidth 
              margin="normal" 
              label="Description" 
              variant="outlined" 
              multiline 
              rows={4} 
              value={description} 
              onChange={(e) => setDescription(e.target.value)} 
            />
            <Button variant="contained" color="primary" style={{ marginTop: 15, backgroundColor: "#6C5DD3" }} type="submit">
              Submit
            </Button>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Contact;
