import React, { useState, useContext } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'; 
import { getDocs, query, collection, where, updateDoc, getFirestore, doc, arrayUnion} from 'firebase/firestore';
import { AuthContext } from '../../contexts/AuthContext';



const theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
  },
  palette: {
    primary: {
      main: '#6C5DD3',
    },
  },
});

function EnterTeacherCode() {
  const [teacherCode, setTeacherCode] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { currentUser, userProfile } = useContext(AuthContext); // move useContext here
  const db = getFirestore();

  const handleSubmit = async (event) => {
    event.preventDefault();
      
    // Query for a teacher with the entered code
    const teacherQuerySnap = await getDocs(query(collection(db, 'users'), where('teacherCode', '==', teacherCode)));

    if (!teacherQuerySnap.empty) {
      const teacherDoc = teacherQuerySnap.docs[0];
      const userDocRef = doc(db, 'users', currentUser.uid);
                
      // Update the current user's document to include the teacher's ID and fullName in the 'savedTeachers' array
      await updateDoc(userDocRef, {
        savedTeachers: arrayUnion({id: teacherDoc.id})
      });
              
      // Also update the teacher's document to include the current user's ID and fullName in the 'students' array
      const teacherDocRef = doc(db, 'users', teacherDoc.id);
      await updateDoc(teacherDocRef, {
        students: arrayUnion({id: currentUser.uid})
      });
      
      // Then navigate to /scheduling
      navigate('/scheduling');
    } else {
      setError("No teacher found with the entered code");
    }
  };
  

  return (
    <ThemeProvider theme={theme}>
      <Box 
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
        className="login-container"
        sx={{ marginBottom: '100px' }} // Adjust this value as per your footer's height
      >
        <Box 
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          className="form-container"
        >
          <Box           
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{width: '70%'}}
          >
            <Typography variant="h4" className="login-heading" gutterBottom>Please Enter your 6-digit teacher code</Typography>
            <Typography gutterBottom>This can be found in your teachers profile. Add this code upon login, or later through your profile to interact with your teacher.</Typography>
          </Box>

          <form onSubmit={handleSubmit} className="login-form" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <TextField
              label="Teacher Code"
              variant="outlined"
              className="login-input"
              margin="normal"
              value={teacherCode}
              onChange={(e) => setTeacherCode(e.target.value)}
            />
            <Box marginX={2}>
              <Button variant="contained" type="submit">Add Teacher</Button>
            </Box>
          </form>
          {error && <Typography color="error">{error}</Typography>}
          <Box position="absolute" bottom={20} right={20}>
            <Button variant="contained" className="skip-button" onClick={() => navigate('/scheduling')}>Skip</Button>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default EnterTeacherCode;
